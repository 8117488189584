import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SalesFilterState {
  salesFilter: {
    activeFilters?: any;
    dateRange?: any;
    shopOrderFilters?: any;
    orderTabStatus?: any;
  };
}

const initialState: SalesFilterState = {
  salesFilter: {},
};

const homeSalesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    saveFilter: (state, action: PayloadAction<SalesFilterState>) => {
      console.log({payload: action.payload})
      return {...state, ...action.payload}; 
    },
  },
});

// Export actions
export const { saveFilter } = homeSalesSlice.actions;

// Export reducer
export default homeSalesSlice.reducer;
