import * as Operations from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const ImageFragmentDoc = gql`
  fragment Image on Image {
    id
    originalSrc
    thumbnailSrc
    croppedSrc: transformedSrc(width: 512, height: 512)
    height
    width
    focusRegion {
      x
      y
      height
      width
    }
  }
`;
export const OptionValueFragmentDoc = gql`
  fragment OptionValue on OptionValue {
    id
    label
    order
    optionId
    option {
      id
      name
      required
      productId
      order
      ... on CustomizationOption {
        enableEmojis
        enableLetters
        enableNumbers
        maxLength
        minLength
        uppercaseOnly
      }
    }
    ... on ColorOptionValue {
      color
    }
  }
`;
export const PricesFragmentDoc = gql`
  fragment Prices on Prices {
    price
    basePrice
    retailPrice
    salePrice
    currency
  }
`;
export const QuantityFragmentDoc = gql`
  fragment Quantity on Quantity {
    total
    available
    reserved
  }
`;
export const MoneyFragmentDoc = gql`
  fragment Money on Money {
    amount
    currency
  }
`;
export const WeightFragmentDoc = gql`
  fragment Weight on Weight {
    unit
    value
  }
`;
export const DimensionsFragmentDoc = gql`
  fragment Dimensions on Dimensions {
    height
    length
    width
    unit
  }
`;
export const InventoryItemFragmentDoc = gql`
  fragment InventoryItem on InventoryItem {
    id
    barcode
    sku
    maxReservable
    variantId
    productId
    cost {
      ...Money
    }
    weight {
      ...Weight
    }
    dimensions {
      ...Dimensions
    }
    quantity {
      ...Quantity
    }
    createdAt
    updatedAt
  }
  ${MoneyFragmentDoc}
  ${WeightFragmentDoc}
  ${DimensionsFragmentDoc}
  ${QuantityFragmentDoc}
`;
export const TaxCategoryFragmentDoc = gql`
  fragment TaxCategory on TaxCategory {
    id
    name
    description
  }
`;
export const ShippingProfileFragmentDoc = gql`
  fragment ShippingProfile on ShippingProfile {
    id
    shopId
    name
  }
`;
export const VariantFragmentDoc = gql`
  fragment Variant on Variant {
    id
    productId
    defaultImageId
    createdAt
    updatedAt
    defaultImageId
    archived
    defaultImage {
      ...Image
    }
    optionValues {
      nodes {
        ...OptionValue
      }
    }
    prices {
      ...Prices
    }
    quantity {
      ...Quantity
    }
    inventoryItem {
      ...InventoryItem
    }
    taxCategoryId
    taxCategory {
      ...TaxCategory
    }
    shippingProfileId
    shippingProfile {
      ...ShippingProfile
    }
  }
  ${ImageFragmentDoc}
  ${OptionValueFragmentDoc}
  ${PricesFragmentDoc}
  ${QuantityFragmentDoc}
  ${InventoryItemFragmentDoc}
  ${TaxCategoryFragmentDoc}
  ${ShippingProfileFragmentDoc}
`;
export const CategoryFragmentDoc = gql`
  fragment Category on Category {
    id
    name
    description
    slug
    path {
      id
      name
      slug
    }
  }
`;
export const ProductPricesFragmentDoc = gql`
  fragment ProductPrices on ProductPrices {
    maxPrice
    minPrice
    maxRetailPrice
    minRetailPrice
    currency
  }
`;
export const ShowProductVariantsFragmentDoc = gql`
  fragment ShowProductVariants on Variant {
    id
    defaultImage {
      originalSrc
    }
    prices {
      price
    }
    optionValues {
      edges {
        node {
          label
          id
        }
      }
    }
    quantity {
      available
    }
  }
`;
export const ProductFragmentDoc = gql`
  fragment Product on Product {
    id
    name
    slug
    description
    categoryId
    shop {
      id
      slug
    }
    category {
      ...Category
    }
    quantity {
      ...Quantity
    }
    productPrices {
      ...ProductPrices
    }
    defaultImage {
      ...Image
    }
    variantsWithDetails: variants {
      nodes {
        ...ShowProductVariants
      }
    }
    defaultVariantId
    shopId
    slug
    status
    createdAt
    updatedAt
  }
  ${CategoryFragmentDoc}
  ${QuantityFragmentDoc}
  ${ProductPricesFragmentDoc}
  ${ImageFragmentDoc}
  ${ShowProductVariantsFragmentDoc}
`;
export const VariantImageFragmentDoc = gql`
  fragment VariantImage on VariantImage {
    id
    default
    order
    variantId
    imageId
    image {
      ...Image
    }
  }
  ${ImageFragmentDoc}
`;
export const StockLocationFragmentDoc = gql`
  fragment StockLocation on StockLocation {
    storageLocationType1
    storageLocationType2
    storageLocation2
    storageLocation1
    zone
  }
`;
export const LocationInventoryFragmentDoc = gql`
  fragment LocationInventory on LocationInventory {
    id
    inventoryItemId
    shopLocationId
    quantity {
      ...Quantity
    }
    stockLocation {
      ...StockLocation
    }
  }
  ${QuantityFragmentDoc}
  ${StockLocationFragmentDoc}
`;
export const VariantDetailsFragmentDoc = gql`
  fragment VariantDetails on Variant {
    ...Variant
    product {
      ...Product
    }
    variantImages(first: 4) {
      nodes {
        ...VariantImage
      }
    }
    inventoryItem {
      ...InventoryItem
      locationInventories {
        nodes {
          ...LocationInventory
        }
      }
    }
  }
  ${VariantFragmentDoc}
  ${ProductFragmentDoc}
  ${VariantImageFragmentDoc}
  ${InventoryItemFragmentDoc}
  ${LocationInventoryFragmentDoc}
`;
export const CartItemCustomizationFragmentDoc = gql`
  fragment CartItemCustomization on CartItemCustomization {
    customizationOptionId
    customizationOption {
      id
      name
      required
    }
    cartItemId
    index
    value
  }
`;
export const CartItemFragmentDoc = gql`
  fragment CartItem on CartItem {
    id
    cartId
    quantity
    variantId
    expiresAt
    variant {
      ...VariantDetails
    }
    customizations {
      nodes {
        ...CartItemCustomization
      }
    }
  }
  ${VariantDetailsFragmentDoc}
  ${CartItemCustomizationFragmentDoc}
`;
export const CategoryChildrenFragmentDoc = gql`
  fragment CategoryChildren on Category {
    ...Category
    children {
      ...Category
      children {
        ...Category
        children {
          ...Category
          children {
            ...Category
          }
        }
      }
    }
  }
  ${CategoryFragmentDoc}
`;
export const CustomerFragmentDoc = gql`
  fragment Customer on Customer {
    id
    email
    name
    phone
    createdAt
  }
`;
export const AddressFragmentDoc = gql`
  fragment Address on Address {
    name
    companyName
    phone
    line1
    line2
    line3
    cityLocality
    stateProvince
    postalCode
    countryCode
    formatted
  }
`;
export const CustomerAddressFragmentDoc = gql`
  fragment CustomerAddress on CustomerAddress {
    id
    customerId
    createdAt
    updatedAt
    default
    address {
      ...Address
    }
  }
  ${AddressFragmentDoc}
`;
export const CustomerPaymentMethodFragmentDoc = gql`
  fragment CustomerPaymentMethod on CustomerPaymentMethod {
    id
    customerId
    createdAt
    setupSecret
    status
    ... on CustomerCard {
      name
      brand
      country
      funding
      expMonth
      expYear
      last4
    }
  }
`;
export const ShopFragmentDoc = gql`
  fragment Shop on Shop {
    id
    name
    legalBusinessName
    slug
    contactEmail
    phone
    description
    shopUsers {
      nodes {
        role
        user {
          name
          photoUrl
        }
      }
    }
    defaultCommission
    shopFbPages
    archived
    shopSetupLastCompleted
    defaultShippingProfileId
    defaultShopLocationId
    isActive
    defaultCommission
    isSellerGuideLinesAccepted
    shopType
    isSameAsSeller
    isCarierTnCAccepted
    address {
      ...Address
    }
    shopOrders {
      nodes {
        orderLineItems {
          nodes {
            quantity
          }
        }
      }
    }
    shopCategories {
      nodes {
        category {
          ...Category
        }
      }
    }
    shopShoppingCategories {
      nodes {
        shoppingCategory {
          id
          name
        }
        shoppingCategoryId
      }
    }
    shopLocations {
      nodes {
        isBillingAddress
        isShippingAddress
        isContactAddress
        address {
          ...Address
        }
      }
    }
    shopCustomizations {
      id
      shopCoverPhoto {
        id
        originalSrc
      }
      shopProfileImage {
        id
        originalSrc
      }
      createdAt
      updatedAt
      shopDescription
    }
    shows {
      nodes {
        id
        name
      }
    }
  }
  ${AddressFragmentDoc}
  ${CategoryFragmentDoc}
`;
export const CheckoutLineItemCustomizationFragmentDoc = gql`
  fragment CheckoutLineItemCustomization on CheckoutLineItemCustomization {
    checkoutLineItemId
    customizationOptionId
    index
    value
  }
`;
export const CheckoutLineItemFragmentDoc = gql`
  fragment CheckoutLineItem on CheckoutLineItem {
    id
    cartItemId
    checkoutId
    variantId
    variant {
      ...VariantDetails
    }
    totalQuantity
    unitPrice {
      ...Money
    }
    customizations {
      nodes {
        customizationOption {
          id
          name
          required
        }
        ...CheckoutLineItemCustomization
      }
    }
  }
  ${VariantDetailsFragmentDoc}
  ${MoneyFragmentDoc}
  ${CheckoutLineItemCustomizationFragmentDoc}
`;
export const ShippingCarrierPackageFragmentDoc = gql`
  fragment ShippingCarrierPackage on ShippingCarrierPackage {
    id
    name
    description
  }
`;
export const ShippingCarrierServiceFragmentDoc = gql`
  fragment ShippingCarrierService on ShippingCarrierService {
    id
    name
    domestic
    description
    international
    logoUrl
  }
`;
export const ShippingRateFragmentDoc = gql`
  fragment ShippingRate on ShippingRate {
    id
    shopId
    ... on CarrierShippingRate {
      defaultShippingCarrierPackageId
      defaultShippingCarrierPackage {
        ...ShippingCarrierPackage
      }
      shippingCarrierServiceId
      shippingCarrierService {
        ...ShippingCarrierService
      }
      handlingFee {
        ...Money
      }
      handlingFeePercent
    }
    ... on FixedShippingRate {
      price {
        ...Money
      }
    }
  }
  ${ShippingCarrierPackageFragmentDoc}
  ${ShippingCarrierServiceFragmentDoc}
  ${MoneyFragmentDoc}
`;
export const ShippingMethodFragmentDoc = gql`
  fragment ShippingMethod on ShippingMethod {
    id
    name
    description
    shippingRateId
    shippingZoneId
    shippingRate {
      ...ShippingRate
    }
  }
  ${ShippingRateFragmentDoc}
`;
export const CheckoutShippingRateFragmentDoc = gql`
  fragment CheckoutShippingRate on CheckoutShippingRate {
    id
    checkoutShipmentId
    deliveryDays
    estimatedDeliveryDate
    price {
      ...Money
    }
    shippingMethodId
    shippingMethod {
      ...ShippingMethod
    }
  }
  ${MoneyFragmentDoc}
  ${ShippingMethodFragmentDoc}
`;
export const LocationFragmentDoc = gql`
  fragment Location on ShopLocation {
    id
    name
    shopId
    default
    archived
    address {
      ...Address
    }
  }
  ${AddressFragmentDoc}
`;
export const CheckoutShipmentLineItemFragmentDoc = gql`
  fragment CheckoutShipmentLineItem on CheckoutShipmentLineItem {
    id
    checkoutLineItemId
    quantity
    checkoutLineItem {
      ...CheckoutLineItem
    }
    taxableAmount {
      ...Money
    }
    taxCollectable {
      ...Money
    }
  }
  ${CheckoutLineItemFragmentDoc}
  ${MoneyFragmentDoc}
`;
export const CheckoutShipmentFragmentDoc = gql`
  fragment CheckoutShipment on CheckoutShipment {
    id
    checkoutId
    sourceShopLocationId
    selectedCheckoutShippingRateId
    shop {
      ...Shop
    }
    total {
      ...Money
    }
    subtotal {
      ...Money
    }
    shippingTotal {
      ...Money
    }
    taxCollectable {
      ...Money
    }
    shippingTax {
      ...Money
    }
    selectedCheckoutShippingRate {
      ...CheckoutShippingRate
    }
    sourceShopLocation {
      ...Location
    }
    checkoutShipmentLineItems(first: 100) {
      edges {
        node {
          ...CheckoutShipmentLineItem
        }
        cursor
      }
    }
    checkoutShippingRates {
      edges {
        node {
          ...CheckoutShippingRate
        }
        cursor
      }
    }
  }
  ${ShopFragmentDoc}
  ${MoneyFragmentDoc}
  ${CheckoutShippingRateFragmentDoc}
  ${LocationFragmentDoc}
  ${CheckoutShipmentLineItemFragmentDoc}
`;
export const CheckoutFragmentDoc = gql`
  fragment Checkout on Checkout {
    id
    customerId
    shippingCustomerAddressId
    carts {
      nodes {
        id
        shopId
        shop {
          ...Shop
        }
      }
    }
    shippingCustomerAddress {
      ...CustomerAddress
      customer {
        ...Customer
      }
    }
    billingCustomerAddressId
    billingCustomerAddress {
      ...CustomerAddress
    }
    customerPaymentMethodId
    customerPaymentMethod {
      ...CustomerPaymentMethod
    }
    subtotal {
      ...Money
    }
    shippingTotal {
      ...Money
    }
    salesTaxTotal {
      ...Money
    }
    total {
      ...Money
    }
    checkoutLineItems {
      edges {
        node {
          ...CheckoutLineItem
        }
        cursor
      }
    }
    checkoutShipments {
      edges {
        node {
          ...CheckoutShipment
        }
        cursor
      }
    }
    createdAt
    updatedAt
  }
  ${ShopFragmentDoc}
  ${CustomerAddressFragmentDoc}
  ${CustomerFragmentDoc}
  ${CustomerPaymentMethodFragmentDoc}
  ${MoneyFragmentDoc}
  ${CheckoutLineItemFragmentDoc}
  ${CheckoutShipmentFragmentDoc}
`;
export const CustomerDetailsFragmentDoc = gql`
  fragment CustomerDetails on Customer {
    ...Customer
    customerAddresses {
      nodes {
        ...CustomerAddress
      }
    }
    customerPaymentMethods(filter: { status: { eq: VERIFIED } }) {
      nodes {
        ...CustomerPaymentMethod
      }
    }
    checkouts {
      nodes {
        ...Checkout
      }
    }
  }
  ${CustomerFragmentDoc}
  ${CustomerAddressFragmentDoc}
  ${CustomerPaymentMethodFragmentDoc}
  ${CheckoutFragmentDoc}
`;
export const FbBusinessAccountFragmentDoc = gql`
  fragment FbBusinessAccount on FbBusinessAccount {
    id
    connectedAt
    scopes
    shopId
    systemUserId
  }
`;
export const FbCommentsSessionFragmentDoc = gql`
  fragment FbCommentsSession on FbCommentsSession {
    id
    pageId
    shopId
    showId
    liveId
    status
    lastUpdatedAt
    businessAccountId
    error
    message
    live {
      id
      description
    }
  }
`;
export const FbLiveFragmentDoc = gql`
  fragment FbLive on FbLive {
    id
    description
    startedAt
    status
    createdAt
    pageId
  }
`;
export const FbPageLivesFragmentDoc = gql`
  fragment FbPageLives on FbPage {
    id
    name
    category
    lives(status: [LIVE]) {
      nodes {
        ...FbLive
      }
    }
  }
  ${FbLiveFragmentDoc}
`;
export const ShowCardFragmentDoc = gql`
  fragment ShowCard on ShowCard {
    id
    showId
    createdAt
    updatedAt
    ... on VariantShowCard {
      variantId
      number
      variant {
        ...VariantDetails
      }
    }
  }
  ${VariantDetailsFragmentDoc}
`;
export const ShopPublicFragmentDoc = gql`
  fragment ShopPublic on Shop {
    id
    name
    legalBusinessName
    slug
    contactEmail
    phone
    description
    defaultCommission
    shopFbPages
    archived
    shopSetupLastCompleted
    defaultShippingProfileId
    defaultShopLocationId
    isActive
    defaultCommission
    isSellerGuideLinesAccepted
    shopType
    isSameAsSeller
    isCarierTnCAccepted
    address {
      ...Address
    }
    shopCategories {
      nodes {
        category {
          ...Category
        }
      }
    }
    shopShoppingCategories {
      nodes {
        shoppingCategory {
          id
          name
        }
        shoppingCategoryId
      }
    }
    shopLocations {
      nodes {
        isBillingAddress
        isShippingAddress
        isContactAddress
        address {
          ...Address
        }
      }
    }
    shopCustomizations {
      id
      shopCoverPhoto {
        id
        originalSrc
      }
      shopProfileImage {
        id
        originalSrc
      }
      createdAt
      updatedAt
      shopDescription
    }
  }
  ${AddressFragmentDoc}
  ${CategoryFragmentDoc}
`;
export const OptionFragmentDoc = gql`
  fragment Option on Option {
    id
    name
    required
    productId
    order
    ... on CustomizationOption {
      enableEmojis
      enableLetters
      enableNumbers
      maxLength
      minLength
      uppercaseOnly
      cartItemCustomizations {
        nodes {
          cartItemId
        }
      }
      checkoutLineItemCustomizations {
        nodes {
          checkoutLineItemId
        }
      }
      orderLineItemCustomizations {
        nodes {
          orderLineItemId
        }
      }
    }
    optionValues {
      nodes {
        ...OptionValue
      }
    }
  }
  ${OptionValueFragmentDoc}
`;
export const ProductImageFragmentDoc = gql`
  fragment ProductImage on ProductImage {
    id
    default
    order
    productId
    imageId
    image {
      ...Image
    }
  }
  ${ImageFragmentDoc}
`;
export const ProductDetailsFragmentDoc = gql`
  fragment ProductDetails on Product {
    ...Product
    shop {
      ...ShopPublic
    }
    options {
      nodes {
        ...Option
      }
    }
    defaultVariant {
      ...VariantDetails
    }
    variants(first: 100) {
      nodes {
        ...VariantDetails
      }
    }
    productImages(first: 4) {
      nodes {
        ...ProductImage
      }
    }
  }
  ${ProductFragmentDoc}
  ${ShopPublicFragmentDoc}
  ${OptionFragmentDoc}
  ${VariantDetailsFragmentDoc}
  ${ProductImageFragmentDoc}
`;
export const FeaturedShowCardFragmentDoc = gql`
  fragment FeaturedShowCard on ShowCard {
    ...ShowCard
    ... on VariantShowCard {
      number
      variant {
        ...VariantDetails
        product {
          ...ProductDetails
        }
      }
    }
  }
  ${ShowCardFragmentDoc}
  ${VariantDetailsFragmentDoc}
  ${ProductDetailsFragmentDoc}
`;
export const ShowCardPublicFragmentDoc = gql`
  fragment ShowCardPublic on ShowCard {
    id
    showId
    createdAt
    updatedAt
    ... on VariantShowCard {
      variantId
      number
      variant {
        ...VariantDetails
      }
    }
  }
  ${VariantDetailsFragmentDoc}
`;
export const FeaturedShowCardPublicFragmentDoc = gql`
  fragment FeaturedShowCardPublic on ShowCard {
    ...ShowCardPublic
  }
  ${ShowCardPublicFragmentDoc}
`;
export const IdentityFragmentDoc = gql`
  fragment Identity on Identity {
    provider
    userId
    social
    accessToken
    refreshToken
  }
`;
export const ImageUploadFragmentDoc = gql`
  fragment ImageUpload on ImageUpload {
    imageId
    filename
    uploadUrl
  }
`;
export const PaymentFragmentDoc = gql`
  fragment Payment on Payment {
    id
    status
    errorCode
    cardDeclineCode
    amount {
      ...Money
    }
    salesTaxAmount {
      ...Money
    }
    shippingAmount {
      ...Money
    }
    applicationFee {
      ...Money
    }
    paymentProviderFee {
      ...Money
    }
    createdAt
    ... on StripePayment {
      stripePaymentIntentId
      clientSecret
    }
  }
  ${MoneyFragmentDoc}
`;
export const OrderFragmentDoc = gql`
  fragment Order on Order {
    id
    orderNumber
    customerId
    currency
    paid
    closed
    closedAt
    email
    phone
    refundedTotal {
      ...Money
    }
    salesTaxTotal {
      ...Money
    }
    currentSalesTaxTotal {
      ...Money
    }
    shippingTotal {
      ...Money
    }
    subtotal {
      ...Money
    }
    currentSubtotal {
      ...Money
    }
    total {
      ...Money
    }
    currentTotal {
      ...Money
    }
    payments {
      nodes {
        ...Payment
      }
    }
    createdAt
    updatedAt
  }
  ${MoneyFragmentDoc}
  ${PaymentFragmentDoc}
`;
export const ShowFragmentDoc = gql`
  fragment Show on Show {
    id
    name
    description
    shopId
    slug
    start
    end
    startedAt
    endedAt
    createdAt
    updatedAt
    commission
    inviteAccepted
    affiliateShopId
    showFbPage
    showImages {
      image {
        id
        originalSrc
        thumbnailSrc
        transformedSrc
      }
    }
    images {
      ...Image
    }
    affiliateEarnings {
      ...Money
    }
    totalSales {
      ...Money
    }
    processingFeesTotal {
      amount
      currency
    }
    jatangoFeesTotal {
      amount
      currency
    }
    salesTaxTotal {
      amount
      currency
    }
    shippingTotal {
      amount
      currency
    }
    shop {
      ...Shop
    }
    shopOrders {
      nodes {
        id
        orderId
        orderLineItems {
          nodes {
            quantity
          }
        }
      }
    }
    affiliateShop {
      ...Shop
    }
    shopOrders {
      nodes {
        id
        customer {
          name
        }
        currency
        status
        createdAt
        order {
          orderNumber
          total {
            amount
          }
        }
      }
    }
  }
  ${ImageFragmentDoc}
  ${MoneyFragmentDoc}
  ${ShopFragmentDoc}
`;
export const ShopOrderFragmentDoc = gql`
  fragment ShopOrder on ShopOrder {
    id
    orderId
    customerId
    email
    phone
    customerId
    currency
    status
    fulfilledAt
    canceledAt
    email
    phone
    showId
    order {
      id
      orderNumber
    }
    customer {
      ...Customer
    }
    shop {
      ...Shop
    }
    show {
      ...Show
    }
    refundedTotal {
      ...Money
    }
    salesTaxTotal {
      ...Money
    }
    currentSalesTaxTotal {
      ...Money
    }
    shippingTotal {
      ...Money
    }
    subtotal {
      ...Money
    }
    currentSubtotal {
      ...Money
    }
    total {
      ...Money
    }
    currentTotal {
      ...Money
    }
    createdAt
    updatedAt
    shopPayments {
      nodes {
        status
      }
    }
  }
  ${CustomerFragmentDoc}
  ${ShopFragmentDoc}
  ${ShowFragmentDoc}
  ${MoneyFragmentDoc}
`;
export const OrderLineItemCustomizationFragmentDoc = gql`
  fragment OrderLineItemCustomization on OrderLineItemCustomization {
    orderLineItemId
    customizationOptionId
    index
    value
  }
`;
export const OrderLineItemFragmentDoc = gql`
  fragment OrderLineItem on OrderLineItem {
    id
    shopOrderId
    currentQuantity
    quantity
    sku
    name
    unitPrice {
      ...Money
    }
    variant {
      ...VariantDetails
    }
    customizations {
      nodes {
        customizationOption {
          id
          name
          required
        }
        ...OrderLineItemCustomization
      }
    }
  }
  ${MoneyFragmentDoc}
  ${VariantDetailsFragmentDoc}
  ${OrderLineItemCustomizationFragmentDoc}
`;
export const OrderShipmentLineItemFragmentDoc = gql`
  fragment OrderShipmentLineItem on OrderShipmentLineItem {
    id
    orderLineItemId
    orderShipmentId
    fulfilledQuantity
    totalQuantity
    orderLineItem {
      ...OrderLineItem
    }
    orderShipment {
      id
      shopOrderId
    }
  }
  ${OrderLineItemFragmentDoc}
`;
export const OrderShipmentFragmentDoc = gql`
  fragment OrderShipment on OrderShipment {
    id
    shopOrderId
    deliveryDays
    estimatedDeliveryDate
    shippingPrice {
      ...Money
    }
    sourceShopLocationId
    sourceAddress {
      ...Address
    }
    destinationAddress {
      ...Address
    }
    shippingMethod {
      ...ShippingMethod
    }
    orderShipmentLineItems(first: 100) {
      nodes {
        ...OrderShipmentLineItem
      }
    }
  }
  ${MoneyFragmentDoc}
  ${AddressFragmentDoc}
  ${ShippingMethodFragmentDoc}
  ${OrderShipmentLineItemFragmentDoc}
`;
export const OrderDetailsFragmentDoc = gql`
  fragment OrderDetails on Order {
    ...Order
    shopOrders {
      nodes {
        ...ShopOrder
      }
    }
    orderShipments {
      nodes {
        ...OrderShipment
      }
    }
    orderShipments {
      nodes {
        ...OrderShipment
      }
    }
    orderLineItems {
      nodes {
        ...OrderLineItem
      }
    }
    payments {
      nodes {
        ...Payment
        ... on CashPayment {
          amountPaid {
            amount
          }
          changeDue {
            amount
          }
        }
      }
    }
  }
  ${OrderFragmentDoc}
  ${ShopOrderFragmentDoc}
  ${OrderShipmentFragmentDoc}
  ${OrderLineItemFragmentDoc}
  ${PaymentFragmentDoc}
`;
export const PageInfoFragmentDoc = gql`
  fragment PageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;
export const ExternalAccountFragmentDoc = gql`
  fragment ExternalAccount on ExternalAccount {
    id
    last4
    country
    currency
    defaultForCurrency
    paymentAccountId
    paymentProvider
    ... on BankAccountExternalAccount {
      bankName
      routingNumber
      status
    }
  }
`;
export const PaymentAccountFragmentDoc = gql`
  fragment PaymentAccount on PaymentAccount {
    id
    paymentProvider
    providerAccountId
    status
    payoutsEnabled
    shopId
    externalAccounts {
      nodes {
        ...ExternalAccount
      }
    }
  }
  ${ExternalAccountFragmentDoc}
`;
export const PaymentAccountLinkFragmentDoc = gql`
  fragment PaymentAccountLink on PaymentAccountLink {
    createdAt
    expiresAt
    type
    url
  }
`;
export const ProductDetailsPublicFragmentDoc = gql`
  fragment ProductDetailsPublic on Product {
    ...Product
    shop {
      ...ShopPublic
    }
    options {
      nodes {
        ...Option
      }
    }
    defaultVariant {
      ...VariantDetails
    }
    variants(first: 100) {
      nodes {
        ...VariantDetails
      }
    }
    productImages(first: 4) {
      nodes {
        ...ProductImage
      }
    }
  }
  ${ProductFragmentDoc}
  ${ShopPublicFragmentDoc}
  ${OptionFragmentDoc}
  ${VariantDetailsFragmentDoc}
  ${ProductImageFragmentDoc}
`;
export const ShopOrderDetailsFragmentDoc = gql`
  fragment ShopOrderDetails on ShopOrder {
    ...ShopOrder
    orderLineItems {
      nodes {
        ...OrderLineItem
      }
    }
    orderShipments {
      nodes {
        ...OrderShipment
      }
    }
    orderPackages(order: { createdAt: ASC }) {
      nodes {
        id
        status
        shippingLabelPriceMoney {
          amount
          currency
        }
        orderFulfilmentLineItems {
          id
          status
          orderLineItemId
          quantity
          createdAt
          updatedAt
          orderId
          shopOrderId
          orderShipmentLineItemId
          order {
            id
          }
          shopOrder {
            id
          }
          orderLineItem {
            ...OrderLineItem
          }
        }
        shopOrderId
        estimatedDeliveryDate
        orderId
        shippingProvider
        currency
        shippingLabelUrl
        deliveryDays
        packingSlipUrl
        shippingCarrierPackage {
          packageType
          id
          dimensions {
            length
            width
            height
            unit
          }
          name
        }
        shippingUserPackage {
          id
          shippingProviderObjectId
          dimensions {
            length
            width
            height
            unit
          }
          name
        }
        primaryWeight {
          value
          unit
        }
        secondaryWeight {
          value
          unit
        }
        totalWeight {
          value
          unit
        }
      }
    }
    orderFulfilmentlineItems {
      nodes {
        id
        status
        orderLineItemId
        quantity
        createdAt
        updatedAt
        orderId
        shopOrderId
        orderShipmentLineItemId
        order {
          id
        }
        shopOrder {
          id
        }
        orderLineItem {
          ...OrderLineItem
        }
      }
    }
  }
  ${ShopOrderFragmentDoc}
  ${OrderLineItemFragmentDoc}
  ${OrderShipmentFragmentDoc}
`;
export const ShowWithoutShopOrdersFragmentDoc = gql`
  fragment ShowWithoutShopOrders on Show {
    id
    name
    description
    shopId
    slug
    start
    end
    startedAt
    endedAt
    createdAt
    updatedAt
    commission
    inviteAccepted
    affiliateShopId
    showFbPage
    affiliateShop {
      ...ShopPublic
    }
    shop {
      ...ShopPublic
    }
    showImages {
      image {
        id
        originalSrc
        thumbnailSrc
        transformedSrc
      }
    }
    images {
      ...Image
    }
    affiliateEarnings {
      ...Money
    }
    totalSales {
      ...Money
    }
    processingFeesTotal {
      amount
      currency
    }
    jatangoFeesTotal {
      amount
      currency
    }
    salesTaxTotal {
      amount
      currency
    }
    shippingTotal {
      amount
      currency
    }
  }
  ${ShopPublicFragmentDoc}
  ${ImageFragmentDoc}
  ${MoneyFragmentDoc}
`;
export const StreamFragmentDoc = gql`
  fragment Stream on Stream {
    id
    sessionId
    chatRoomId
  }
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    email
    name
    phone
    photoUrl
    createdAt
    updatedAt
    userDemographics {
      id
      dateOfBirth
      gender
    }
    username
  }
`;
export const ErrorFragmentDoc = gql`
  fragment Error on Error {
    message
  }
`;
export const DuplicateVariantOptionErrorFragmentDoc = gql`
  fragment DuplicateVariantOptionError on DuplicateVariantOptionError {
    ...Error
    variantOptionId
  }
  ${ErrorFragmentDoc}
`;
export const ShopLocationNotFoundErrorFragmentDoc = gql`
  fragment ShopLocationNotFoundError on ShopLocationNotFoundError {
    ...Error
    shopLocationId
  }
  ${ErrorFragmentDoc}
`;
export const TaxCategoriesByCategoryDocument = gql`
  query TaxCategoriesByCategory($categoryId: ID!) {
    taxCategoriesByCategory(categoryId: $categoryId) {
      edges {
        node {
          ...TaxCategory
        }
        cursor
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${TaxCategoryFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useTaxCategoriesByCategoryQuery__
 *
 * To run a query within a React component, call `useTaxCategoriesByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxCategoriesByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxCategoriesByCategoryQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useTaxCategoriesByCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.TaxCategoriesByCategoryQuery,
    Operations.TaxCategoriesByCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.TaxCategoriesByCategoryQuery, Operations.TaxCategoriesByCategoryQueryVariables>(
    TaxCategoriesByCategoryDocument,
    options
  );
}
export function useTaxCategoriesByCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.TaxCategoriesByCategoryQuery,
    Operations.TaxCategoriesByCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.TaxCategoriesByCategoryQuery, Operations.TaxCategoriesByCategoryQueryVariables>(
    TaxCategoriesByCategoryDocument,
    options
  );
}
export type TaxCategoriesByCategoryQueryHookResult = ReturnType<typeof useTaxCategoriesByCategoryQuery>;
export type TaxCategoriesByCategoryLazyQueryHookResult = ReturnType<typeof useTaxCategoriesByCategoryLazyQuery>;
export type TaxCategoriesByCategoryQueryResult = Apollo.QueryResult<
  Operations.TaxCategoriesByCategoryQuery,
  Operations.TaxCategoriesByCategoryQueryVariables
>;
export const AcceptAffiliateInviteDocument = gql`
  mutation AcceptAffiliateInvite($input: AcceptAffiliateInviteInput!) {
    acceptAffiliateInvite(input: $input) {
      show {
        ...Show
      }
      errors {
        ...Error
      }
    }
  }
  ${ShowFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type AcceptAffiliateInviteMutationFn = Apollo.MutationFunction<
  Operations.AcceptAffiliateInviteMutation,
  Operations.AcceptAffiliateInviteMutationVariables
>;

/**
 * __useAcceptAffiliateInviteMutation__
 *
 * To run a mutation, you first call `useAcceptAffiliateInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAffiliateInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptAffiliateInviteMutation, { data, loading, error }] = useAcceptAffiliateInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptAffiliateInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.AcceptAffiliateInviteMutation,
    Operations.AcceptAffiliateInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.AcceptAffiliateInviteMutation,
    Operations.AcceptAffiliateInviteMutationVariables
  >(AcceptAffiliateInviteDocument, options);
}
export type AcceptAffiliateInviteMutationHookResult = ReturnType<typeof useAcceptAffiliateInviteMutation>;
export type AcceptAffiliateInviteMutationResult = Apollo.MutationResult<Operations.AcceptAffiliateInviteMutation>;
export type AcceptAffiliateInviteMutationOptions = Apollo.BaseMutationOptions<
  Operations.AcceptAffiliateInviteMutation,
  Operations.AcceptAffiliateInviteMutationVariables
>;
export const AddCartItemCustomizationDocument = gql`
  mutation AddCartItemCustomization($input: AddCartItemCustomizationInput!) {
    addCartItemCustomization(input: $input) {
      cartItemCustomization {
        ...CartItemCustomization
      }
    }
  }
  ${CartItemCustomizationFragmentDoc}
`;
export type AddCartItemCustomizationMutationFn = Apollo.MutationFunction<
  Operations.AddCartItemCustomizationMutation,
  Operations.AddCartItemCustomizationMutationVariables
>;

/**
 * __useAddCartItemCustomizationMutation__
 *
 * To run a mutation, you first call `useAddCartItemCustomizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCartItemCustomizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCartItemCustomizationMutation, { data, loading, error }] = useAddCartItemCustomizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCartItemCustomizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.AddCartItemCustomizationMutation,
    Operations.AddCartItemCustomizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.AddCartItemCustomizationMutation,
    Operations.AddCartItemCustomizationMutationVariables
  >(AddCartItemCustomizationDocument, options);
}
export type AddCartItemCustomizationMutationHookResult = ReturnType<typeof useAddCartItemCustomizationMutation>;
export type AddCartItemCustomizationMutationResult = Apollo.MutationResult<Operations.AddCartItemCustomizationMutation>;
export type AddCartItemCustomizationMutationOptions = Apollo.BaseMutationOptions<
  Operations.AddCartItemCustomizationMutation,
  Operations.AddCartItemCustomizationMutationVariables
>;
export const AddOptionValueDocument = gql`
  mutation AddOptionValue($input: AddOptionValueInput!) {
    addOptionValue(input: $input) {
      optionValue {
        ...OptionValue
      }
    }
  }
  ${OptionValueFragmentDoc}
`;
export type AddOptionValueMutationFn = Apollo.MutationFunction<
  Operations.AddOptionValueMutation,
  Operations.AddOptionValueMutationVariables
>;

/**
 * __useAddOptionValueMutation__
 *
 * To run a mutation, you first call `useAddOptionValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOptionValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOptionValueMutation, { data, loading, error }] = useAddOptionValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOptionValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.AddOptionValueMutation,
    Operations.AddOptionValueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.AddOptionValueMutation, Operations.AddOptionValueMutationVariables>(
    AddOptionValueDocument,
    options
  );
}
export type AddOptionValueMutationHookResult = ReturnType<typeof useAddOptionValueMutation>;
export type AddOptionValueMutationResult = Apollo.MutationResult<Operations.AddOptionValueMutation>;
export type AddOptionValueMutationOptions = Apollo.BaseMutationOptions<
  Operations.AddOptionValueMutation,
  Operations.AddOptionValueMutationVariables
>;
export const AddOrUpdateUserShoppingCategoriesDocument = gql`
  mutation AddOrUpdateUserShoppingCategories($input: AddOrUpdateUserShoppingCategoriesInput!) {
    addOrUpdateUserShoppingCategories(input: $input) {
      userShoppingCategory {
        id
        isSeller
      }
    }
  }
`;
export type AddOrUpdateUserShoppingCategoriesMutationFn = Apollo.MutationFunction<
  Operations.AddOrUpdateUserShoppingCategoriesMutation,
  Operations.AddOrUpdateUserShoppingCategoriesMutationVariables
>;

/**
 * __useAddOrUpdateUserShoppingCategoriesMutation__
 *
 * To run a mutation, you first call `useAddOrUpdateUserShoppingCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrUpdateUserShoppingCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrUpdateUserShoppingCategoriesMutation, { data, loading, error }] = useAddOrUpdateUserShoppingCategoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrUpdateUserShoppingCategoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.AddOrUpdateUserShoppingCategoriesMutation,
    Operations.AddOrUpdateUserShoppingCategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.AddOrUpdateUserShoppingCategoriesMutation,
    Operations.AddOrUpdateUserShoppingCategoriesMutationVariables
  >(AddOrUpdateUserShoppingCategoriesDocument, options);
}
export type AddOrUpdateUserShoppingCategoriesMutationHookResult = ReturnType<
  typeof useAddOrUpdateUserShoppingCategoriesMutation
>;
export type AddOrUpdateUserShoppingCategoriesMutationResult =
  Apollo.MutationResult<Operations.AddOrUpdateUserShoppingCategoriesMutation>;
export type AddOrUpdateUserShoppingCategoriesMutationOptions = Apollo.BaseMutationOptions<
  Operations.AddOrUpdateUserShoppingCategoriesMutation,
  Operations.AddOrUpdateUserShoppingCategoriesMutationVariables
>;
export const AddOrUpdateUserShoppingSubcategoriesDocument = gql`
  mutation AddOrUpdateUserShoppingSubcategories($input: AddOrUpdateUserShoppingSubcategoriesInput!) {
    addOrUpdateUserShoppingSubcategories(input: $input) {
      userShoppingSubcategory {
        id
      }
    }
  }
`;
export type AddOrUpdateUserShoppingSubcategoriesMutationFn = Apollo.MutationFunction<
  Operations.AddOrUpdateUserShoppingSubcategoriesMutation,
  Operations.AddOrUpdateUserShoppingSubcategoriesMutationVariables
>;

/**
 * __useAddOrUpdateUserShoppingSubcategoriesMutation__
 *
 * To run a mutation, you first call `useAddOrUpdateUserShoppingSubcategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrUpdateUserShoppingSubcategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrUpdateUserShoppingSubcategoriesMutation, { data, loading, error }] = useAddOrUpdateUserShoppingSubcategoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrUpdateUserShoppingSubcategoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.AddOrUpdateUserShoppingSubcategoriesMutation,
    Operations.AddOrUpdateUserShoppingSubcategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.AddOrUpdateUserShoppingSubcategoriesMutation,
    Operations.AddOrUpdateUserShoppingSubcategoriesMutationVariables
  >(AddOrUpdateUserShoppingSubcategoriesDocument, options);
}
export type AddOrUpdateUserShoppingSubcategoriesMutationHookResult = ReturnType<
  typeof useAddOrUpdateUserShoppingSubcategoriesMutation
>;
export type AddOrUpdateUserShoppingSubcategoriesMutationResult =
  Apollo.MutationResult<Operations.AddOrUpdateUserShoppingSubcategoriesMutation>;
export type AddOrUpdateUserShoppingSubcategoriesMutationOptions = Apollo.BaseMutationOptions<
  Operations.AddOrUpdateUserShoppingSubcategoriesMutation,
  Operations.AddOrUpdateUserShoppingSubcategoriesMutationVariables
>;
export const AddProductImagesDocument = gql`
  mutation AddProductImages($input: AddProductImagesInput!) {
    addProductImages(input: $input) {
      productImages {
        ...ProductImage
      }
    }
  }
  ${ProductImageFragmentDoc}
`;
export type AddProductImagesMutationFn = Apollo.MutationFunction<
  Operations.AddProductImagesMutation,
  Operations.AddProductImagesMutationVariables
>;

/**
 * __useAddProductImagesMutation__
 *
 * To run a mutation, you first call `useAddProductImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductImagesMutation, { data, loading, error }] = useAddProductImagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProductImagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.AddProductImagesMutation,
    Operations.AddProductImagesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.AddProductImagesMutation, Operations.AddProductImagesMutationVariables>(
    AddProductImagesDocument,
    options
  );
}
export type AddProductImagesMutationHookResult = ReturnType<typeof useAddProductImagesMutation>;
export type AddProductImagesMutationResult = Apollo.MutationResult<Operations.AddProductImagesMutation>;
export type AddProductImagesMutationOptions = Apollo.BaseMutationOptions<
  Operations.AddProductImagesMutation,
  Operations.AddProductImagesMutationVariables
>;
export const AddShopImagesDocument = gql`
  mutation AddShopImages($input: AddShopImagesInput!) {
    addShopImages(input: $input) {
      shopImages {
        id
        imageId
        isProfileImage
        isCoverPhoto
      }
    }
  }
`;
export type AddShopImagesMutationFn = Apollo.MutationFunction<
  Operations.AddShopImagesMutation,
  Operations.AddShopImagesMutationVariables
>;

/**
 * __useAddShopImagesMutation__
 *
 * To run a mutation, you first call `useAddShopImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddShopImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addShopImagesMutation, { data, loading, error }] = useAddShopImagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddShopImagesMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.AddShopImagesMutation, Operations.AddShopImagesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.AddShopImagesMutation, Operations.AddShopImagesMutationVariables>(
    AddShopImagesDocument,
    options
  );
}
export type AddShopImagesMutationHookResult = ReturnType<typeof useAddShopImagesMutation>;
export type AddShopImagesMutationResult = Apollo.MutationResult<Operations.AddShopImagesMutation>;
export type AddShopImagesMutationOptions = Apollo.BaseMutationOptions<
  Operations.AddShopImagesMutation,
  Operations.AddShopImagesMutationVariables
>;
export const AddShowImageDocument = gql`
  mutation AddShowImage($input: AddShowImageInput!) {
    addShowImage(input: $input) {
      showImage {
        id
        imageId
      }
    }
  }
`;
export type AddShowImageMutationFn = Apollo.MutationFunction<
  Operations.AddShowImageMutation,
  Operations.AddShowImageMutationVariables
>;

/**
 * __useAddShowImageMutation__
 *
 * To run a mutation, you first call `useAddShowImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddShowImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addShowImageMutation, { data, loading, error }] = useAddShowImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddShowImageMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.AddShowImageMutation, Operations.AddShowImageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.AddShowImageMutation, Operations.AddShowImageMutationVariables>(
    AddShowImageDocument,
    options
  );
}
export type AddShowImageMutationHookResult = ReturnType<typeof useAddShowImageMutation>;
export type AddShowImageMutationResult = Apollo.MutationResult<Operations.AddShowImageMutation>;
export type AddShowImageMutationOptions = Apollo.BaseMutationOptions<
  Operations.AddShowImageMutation,
  Operations.AddShowImageMutationVariables
>;
export const AddVariantCardToShowDocument = gql`
  mutation AddVariantCardToShow($input: AddVariantCardToShowInput!) {
    addVariantCardToShow(input: $input) {
      variantShowCard {
        ...FeaturedShowCard
      }
      errors {
        ...Error
      }
    }
  }
  ${FeaturedShowCardFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type AddVariantCardToShowMutationFn = Apollo.MutationFunction<
  Operations.AddVariantCardToShowMutation,
  Operations.AddVariantCardToShowMutationVariables
>;

/**
 * __useAddVariantCardToShowMutation__
 *
 * To run a mutation, you first call `useAddVariantCardToShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVariantCardToShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVariantCardToShowMutation, { data, loading, error }] = useAddVariantCardToShowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVariantCardToShowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.AddVariantCardToShowMutation,
    Operations.AddVariantCardToShowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.AddVariantCardToShowMutation, Operations.AddVariantCardToShowMutationVariables>(
    AddVariantCardToShowDocument,
    options
  );
}
export type AddVariantCardToShowMutationHookResult = ReturnType<typeof useAddVariantCardToShowMutation>;
export type AddVariantCardToShowMutationResult = Apollo.MutationResult<Operations.AddVariantCardToShowMutation>;
export type AddVariantCardToShowMutationOptions = Apollo.BaseMutationOptions<
  Operations.AddVariantCardToShowMutation,
  Operations.AddVariantCardToShowMutationVariables
>;
export const AddVariantImagesDocument = gql`
  mutation AddVariantImages($input: AddVariantImagesInput!) {
    addVariantImages(input: $input) {
      variantImages {
        ...VariantImage
      }
    }
  }
  ${VariantImageFragmentDoc}
`;
export type AddVariantImagesMutationFn = Apollo.MutationFunction<
  Operations.AddVariantImagesMutation,
  Operations.AddVariantImagesMutationVariables
>;

/**
 * __useAddVariantImagesMutation__
 *
 * To run a mutation, you first call `useAddVariantImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVariantImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVariantImagesMutation, { data, loading, error }] = useAddVariantImagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVariantImagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.AddVariantImagesMutation,
    Operations.AddVariantImagesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.AddVariantImagesMutation, Operations.AddVariantImagesMutationVariables>(
    AddVariantImagesDocument,
    options
  );
}
export type AddVariantImagesMutationHookResult = ReturnType<typeof useAddVariantImagesMutation>;
export type AddVariantImagesMutationResult = Apollo.MutationResult<Operations.AddVariantImagesMutation>;
export type AddVariantImagesMutationOptions = Apollo.BaseMutationOptions<
  Operations.AddVariantImagesMutation,
  Operations.AddVariantImagesMutationVariables
>;
export const AddVariantOptionValueDocument = gql`
  mutation AddVariantOptionValue($input: AddVariantOptionValueInput!) {
    addVariantOptionValue(input: $input) {
      variant {
        ...Variant
      }
    }
  }
  ${VariantFragmentDoc}
`;
export type AddVariantOptionValueMutationFn = Apollo.MutationFunction<
  Operations.AddVariantOptionValueMutation,
  Operations.AddVariantOptionValueMutationVariables
>;

/**
 * __useAddVariantOptionValueMutation__
 *
 * To run a mutation, you first call `useAddVariantOptionValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVariantOptionValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVariantOptionValueMutation, { data, loading, error }] = useAddVariantOptionValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVariantOptionValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.AddVariantOptionValueMutation,
    Operations.AddVariantOptionValueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.AddVariantOptionValueMutation,
    Operations.AddVariantOptionValueMutationVariables
  >(AddVariantOptionValueDocument, options);
}
export type AddVariantOptionValueMutationHookResult = ReturnType<typeof useAddVariantOptionValueMutation>;
export type AddVariantOptionValueMutationResult = Apollo.MutationResult<Operations.AddVariantOptionValueMutation>;
export type AddVariantOptionValueMutationOptions = Apollo.BaseMutationOptions<
  Operations.AddVariantOptionValueMutation,
  Operations.AddVariantOptionValueMutationVariables
>;
export const AddVariantToCartDocument = gql`
  mutation AddVariantToCart($input: AddVariantToCartInput!) {
    addVariantToCart(input: $input) {
      cartItem {
        ...CartItem
      }
      errors {
        ...Error
      }
    }
  }
  ${CartItemFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type AddVariantToCartMutationFn = Apollo.MutationFunction<
  Operations.AddVariantToCartMutation,
  Operations.AddVariantToCartMutationVariables
>;

/**
 * __useAddVariantToCartMutation__
 *
 * To run a mutation, you first call `useAddVariantToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVariantToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVariantToCartMutation, { data, loading, error }] = useAddVariantToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVariantToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.AddVariantToCartMutation,
    Operations.AddVariantToCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.AddVariantToCartMutation, Operations.AddVariantToCartMutationVariables>(
    AddVariantToCartDocument,
    options
  );
}
export type AddVariantToCartMutationHookResult = ReturnType<typeof useAddVariantToCartMutation>;
export type AddVariantToCartMutationResult = Apollo.MutationResult<Operations.AddVariantToCartMutation>;
export type AddVariantToCartMutationOptions = Apollo.BaseMutationOptions<
  Operations.AddVariantToCartMutation,
  Operations.AddVariantToCartMutationVariables
>;
export const ArchiveProductDocument = gql`
  mutation ArchiveProduct($input: ArchiveProductInput!) {
    archiveProduct(input: $input) {
      product {
        ...Product
      }
    }
  }
  ${ProductFragmentDoc}
`;
export type ArchiveProductMutationFn = Apollo.MutationFunction<
  Operations.ArchiveProductMutation,
  Operations.ArchiveProductMutationVariables
>;

/**
 * __useArchiveProductMutation__
 *
 * To run a mutation, you first call `useArchiveProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProductMutation, { data, loading, error }] = useArchiveProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.ArchiveProductMutation,
    Operations.ArchiveProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.ArchiveProductMutation, Operations.ArchiveProductMutationVariables>(
    ArchiveProductDocument,
    options
  );
}
export type ArchiveProductMutationHookResult = ReturnType<typeof useArchiveProductMutation>;
export type ArchiveProductMutationResult = Apollo.MutationResult<Operations.ArchiveProductMutation>;
export type ArchiveProductMutationOptions = Apollo.BaseMutationOptions<
  Operations.ArchiveProductMutation,
  Operations.ArchiveProductMutationVariables
>;
export const ArchiveProductVariantDocument = gql`
  mutation ArchiveProductVariant($input: ArchiveProductVariantInput!) {
    archiveProductVariant(input: $input) {
      variant {
        id
        archived
      }
    }
  }
`;
export type ArchiveProductVariantMutationFn = Apollo.MutationFunction<
  Operations.ArchiveProductVariantMutation,
  Operations.ArchiveProductVariantMutationVariables
>;

/**
 * __useArchiveProductVariantMutation__
 *
 * To run a mutation, you first call `useArchiveProductVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProductVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProductVariantMutation, { data, loading, error }] = useArchiveProductVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveProductVariantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.ArchiveProductVariantMutation,
    Operations.ArchiveProductVariantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.ArchiveProductVariantMutation,
    Operations.ArchiveProductVariantMutationVariables
  >(ArchiveProductVariantDocument, options);
}
export type ArchiveProductVariantMutationHookResult = ReturnType<typeof useArchiveProductVariantMutation>;
export type ArchiveProductVariantMutationResult = Apollo.MutationResult<Operations.ArchiveProductVariantMutation>;
export type ArchiveProductVariantMutationOptions = Apollo.BaseMutationOptions<
  Operations.ArchiveProductVariantMutation,
  Operations.ArchiveProductVariantMutationVariables
>;
export const CancelCardReaderActionDocument = gql`
  mutation CancelCardReaderAction($input: CancelCardReaderActionInput!) {
    cancelCardReaderAction(input: $input) {
      payment {
        ...Payment
      }
    }
  }
  ${PaymentFragmentDoc}
`;
export type CancelCardReaderActionMutationFn = Apollo.MutationFunction<
  Operations.CancelCardReaderActionMutation,
  Operations.CancelCardReaderActionMutationVariables
>;

/**
 * __useCancelCardReaderActionMutation__
 *
 * To run a mutation, you first call `useCancelCardReaderActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCardReaderActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCardReaderActionMutation, { data, loading, error }] = useCancelCardReaderActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCardReaderActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CancelCardReaderActionMutation,
    Operations.CancelCardReaderActionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CancelCardReaderActionMutation,
    Operations.CancelCardReaderActionMutationVariables
  >(CancelCardReaderActionDocument, options);
}
export type CancelCardReaderActionMutationHookResult = ReturnType<typeof useCancelCardReaderActionMutation>;
export type CancelCardReaderActionMutationResult = Apollo.MutationResult<Operations.CancelCardReaderActionMutation>;
export type CancelCardReaderActionMutationOptions = Apollo.BaseMutationOptions<
  Operations.CancelCardReaderActionMutation,
  Operations.CancelCardReaderActionMutationVariables
>;
export const CancelOrderDocument = gql`
  mutation CancelOrder($input: CancelOrderInput!) {
    cancelOrder(input: $input) {
      order {
        ...OrderDetails
      }
    }
  }
  ${OrderDetailsFragmentDoc}
`;
export type CancelOrderMutationFn = Apollo.MutationFunction<
  Operations.CancelOrderMutation,
  Operations.CancelOrderMutationVariables
>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.CancelOrderMutation, Operations.CancelOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.CancelOrderMutation, Operations.CancelOrderMutationVariables>(
    CancelOrderDocument,
    options
  );
}
export type CancelOrderMutationHookResult = ReturnType<typeof useCancelOrderMutation>;
export type CancelOrderMutationResult = Apollo.MutationResult<Operations.CancelOrderMutation>;
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<
  Operations.CancelOrderMutation,
  Operations.CancelOrderMutationVariables
>;
export const CaptureOrUpdateUserDemographicDocument = gql`
  mutation CaptureOrUpdateUserDemographic($input: CaptureOrUpdateUserDemographicInput!) {
    captureOrUpdateUserDemographic(input: $input) {
      userDemographic {
        id
        gender
        dateOfBirth
        userId
        zipCode
      }
    }
  }
`;
export type CaptureOrUpdateUserDemographicMutationFn = Apollo.MutationFunction<
  Operations.CaptureOrUpdateUserDemographicMutation,
  Operations.CaptureOrUpdateUserDemographicMutationVariables
>;

/**
 * __useCaptureOrUpdateUserDemographicMutation__
 *
 * To run a mutation, you first call `useCaptureOrUpdateUserDemographicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaptureOrUpdateUserDemographicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [captureOrUpdateUserDemographicMutation, { data, loading, error }] = useCaptureOrUpdateUserDemographicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaptureOrUpdateUserDemographicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CaptureOrUpdateUserDemographicMutation,
    Operations.CaptureOrUpdateUserDemographicMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CaptureOrUpdateUserDemographicMutation,
    Operations.CaptureOrUpdateUserDemographicMutationVariables
  >(CaptureOrUpdateUserDemographicDocument, options);
}
export type CaptureOrUpdateUserDemographicMutationHookResult = ReturnType<
  typeof useCaptureOrUpdateUserDemographicMutation
>;
export type CaptureOrUpdateUserDemographicMutationResult =
  Apollo.MutationResult<Operations.CaptureOrUpdateUserDemographicMutation>;
export type CaptureOrUpdateUserDemographicMutationOptions = Apollo.BaseMutationOptions<
  Operations.CaptureOrUpdateUserDemographicMutation,
  Operations.CaptureOrUpdateUserDemographicMutationVariables
>;
export const CollectCashPaymentForOrderDocument = gql`
  mutation collectCashPaymentForOrder($input: CollectCashPaymentForOrderInput!) {
    collectCashPaymentForOrder(input: $input) {
      order {
        id
        customerId
        email
        total {
          amount
          currency
        }
        shippingTotal {
          amount
          currency
        }
        salesTaxTotal {
          amount
          currency
        }
        payments {
          nodes {
            id
            customerPaymentMethod {
              id
            }
          }
        }
      }
    }
  }
`;
export type CollectCashPaymentForOrderMutationFn = Apollo.MutationFunction<
  Operations.CollectCashPaymentForOrderMutation,
  Operations.CollectCashPaymentForOrderMutationVariables
>;

/**
 * __useCollectCashPaymentForOrderMutation__
 *
 * To run a mutation, you first call `useCollectCashPaymentForOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollectCashPaymentForOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectCashPaymentForOrderMutation, { data, loading, error }] = useCollectCashPaymentForOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCollectCashPaymentForOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CollectCashPaymentForOrderMutation,
    Operations.CollectCashPaymentForOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CollectCashPaymentForOrderMutation,
    Operations.CollectCashPaymentForOrderMutationVariables
  >(CollectCashPaymentForOrderDocument, options);
}
export type CollectCashPaymentForOrderMutationHookResult = ReturnType<typeof useCollectCashPaymentForOrderMutation>;
export type CollectCashPaymentForOrderMutationResult =
  Apollo.MutationResult<Operations.CollectCashPaymentForOrderMutation>;
export type CollectCashPaymentForOrderMutationOptions = Apollo.BaseMutationOptions<
  Operations.CollectCashPaymentForOrderMutation,
  Operations.CollectCashPaymentForOrderMutationVariables
>;
export const ConnectFbBusinessAccountDocument = gql`
  mutation ConnectFbBusinessAccount($accountInput: ConnectFbBusinessAccountInput!) {
    connectFbBusinessAccount(input: $accountInput) {
      fbBusinessAccount {
        ...FbBusinessAccount
      }
      errors {
        ... on Error {
          message
        }
      }
    }
  }
  ${FbBusinessAccountFragmentDoc}
`;
export type ConnectFbBusinessAccountMutationFn = Apollo.MutationFunction<
  Operations.ConnectFbBusinessAccountMutation,
  Operations.ConnectFbBusinessAccountMutationVariables
>;

/**
 * __useConnectFbBusinessAccountMutation__
 *
 * To run a mutation, you first call `useConnectFbBusinessAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectFbBusinessAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectFbBusinessAccountMutation, { data, loading, error }] = useConnectFbBusinessAccountMutation({
 *   variables: {
 *      accountInput: // value for 'accountInput'
 *   },
 * });
 */
export function useConnectFbBusinessAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.ConnectFbBusinessAccountMutation,
    Operations.ConnectFbBusinessAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.ConnectFbBusinessAccountMutation,
    Operations.ConnectFbBusinessAccountMutationVariables
  >(ConnectFbBusinessAccountDocument, options);
}
export type ConnectFbBusinessAccountMutationHookResult = ReturnType<typeof useConnectFbBusinessAccountMutation>;
export type ConnectFbBusinessAccountMutationResult = Apollo.MutationResult<Operations.ConnectFbBusinessAccountMutation>;
export type ConnectFbBusinessAccountMutationOptions = Apollo.BaseMutationOptions<
  Operations.ConnectFbBusinessAccountMutation,
  Operations.ConnectFbBusinessAccountMutationVariables
>;
export const CreateCheckoutFromCartDocument = gql`
  mutation CreateCheckoutFromCart($input: CreateCheckoutFromCartInput!) {
    createCheckoutFromCart(input: $input) {
      checkout {
        ...Checkout
      }
      errors {
        ...Error
      }
    }
  }
  ${CheckoutFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CreateCheckoutFromCartMutationFn = Apollo.MutationFunction<
  Operations.CreateCheckoutFromCartMutation,
  Operations.CreateCheckoutFromCartMutationVariables
>;

/**
 * __useCreateCheckoutFromCartMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutFromCartMutation, { data, loading, error }] = useCreateCheckoutFromCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCheckoutFromCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateCheckoutFromCartMutation,
    Operations.CreateCheckoutFromCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreateCheckoutFromCartMutation,
    Operations.CreateCheckoutFromCartMutationVariables
  >(CreateCheckoutFromCartDocument, options);
}
export type CreateCheckoutFromCartMutationHookResult = ReturnType<typeof useCreateCheckoutFromCartMutation>;
export type CreateCheckoutFromCartMutationResult = Apollo.MutationResult<Operations.CreateCheckoutFromCartMutation>;
export type CreateCheckoutFromCartMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateCheckoutFromCartMutation,
  Operations.CreateCheckoutFromCartMutationVariables
>;
export const CreateCheckoutFromCartPosDocument = gql`
  mutation CreateCheckoutFromCartPos($input: CreateCheckoutFromCartPosInput!) {
    createCheckoutFromCartPos(input: $input) {
      checkout {
        ...Checkout
      }
      errors {
        ...Error
      }
    }
  }
  ${CheckoutFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CreateCheckoutFromCartPosMutationFn = Apollo.MutationFunction<
  Operations.CreateCheckoutFromCartPosMutation,
  Operations.CreateCheckoutFromCartPosMutationVariables
>;

/**
 * __useCreateCheckoutFromCartPosMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutFromCartPosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutFromCartPosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutFromCartPosMutation, { data, loading, error }] = useCreateCheckoutFromCartPosMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCheckoutFromCartPosMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateCheckoutFromCartPosMutation,
    Operations.CreateCheckoutFromCartPosMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreateCheckoutFromCartPosMutation,
    Operations.CreateCheckoutFromCartPosMutationVariables
  >(CreateCheckoutFromCartPosDocument, options);
}
export type CreateCheckoutFromCartPosMutationHookResult = ReturnType<typeof useCreateCheckoutFromCartPosMutation>;
export type CreateCheckoutFromCartPosMutationResult =
  Apollo.MutationResult<Operations.CreateCheckoutFromCartPosMutation>;
export type CreateCheckoutFromCartPosMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateCheckoutFromCartPosMutation,
  Operations.CreateCheckoutFromCartPosMutationVariables
>;
export const CreateCustomPackageDocument = gql`
  mutation CreateCustomPackage($input: CreateCustomPackageInput!) {
    createCustomPackage(input: $input) {
      shippingUserPackage {
        id
        name
        packageType
        description
        weight {
          value
          unit
        }
        dimensions {
          length
          width
          height
          unit
        }
      }
    }
  }
`;
export type CreateCustomPackageMutationFn = Apollo.MutationFunction<
  Operations.CreateCustomPackageMutation,
  Operations.CreateCustomPackageMutationVariables
>;

/**
 * __useCreateCustomPackageMutation__
 *
 * To run a mutation, you first call `useCreateCustomPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomPackageMutation, { data, loading, error }] = useCreateCustomPackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomPackageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateCustomPackageMutation,
    Operations.CreateCustomPackageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.CreateCustomPackageMutation, Operations.CreateCustomPackageMutationVariables>(
    CreateCustomPackageDocument,
    options
  );
}
export type CreateCustomPackageMutationHookResult = ReturnType<typeof useCreateCustomPackageMutation>;
export type CreateCustomPackageMutationResult = Apollo.MutationResult<Operations.CreateCustomPackageMutation>;
export type CreateCustomPackageMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateCustomPackageMutation,
  Operations.CreateCustomPackageMutationVariables
>;
export const CreateCustomerAddressDocument = gql`
  mutation CreateCustomerAddress($input: CreateCustomerAddressInput!) {
    createCustomerAddress(input: $input) {
      customerAddress {
        ...CustomerAddress
      }
      errors {
        ...Error
        ... on InvalidAddressError {
          errors {
            code
            message
            field
          }
          address {
            ...Address
          }
          matchedAddress {
            ...Address
          }
        }
      }
    }
  }
  ${CustomerAddressFragmentDoc}
  ${ErrorFragmentDoc}
  ${AddressFragmentDoc}
`;
export type CreateCustomerAddressMutationFn = Apollo.MutationFunction<
  Operations.CreateCustomerAddressMutation,
  Operations.CreateCustomerAddressMutationVariables
>;

/**
 * __useCreateCustomerAddressMutation__
 *
 * To run a mutation, you first call `useCreateCustomerAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerAddressMutation, { data, loading, error }] = useCreateCustomerAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateCustomerAddressMutation,
    Operations.CreateCustomerAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreateCustomerAddressMutation,
    Operations.CreateCustomerAddressMutationVariables
  >(CreateCustomerAddressDocument, options);
}
export type CreateCustomerAddressMutationHookResult = ReturnType<typeof useCreateCustomerAddressMutation>;
export type CreateCustomerAddressMutationResult = Apollo.MutationResult<Operations.CreateCustomerAddressMutation>;
export type CreateCustomerAddressMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateCustomerAddressMutation,
  Operations.CreateCustomerAddressMutationVariables
>;
export const CreateCustomerAddressPosDocument = gql`
  mutation CreateCustomerAddressPos($input: CreateCustomerAddressPosInput!) {
    createCustomerAddressPos(input: $input) {
      customerAddress {
        ...CustomerAddress
      }
      errors {
        ...Error
        ... on InvalidAddressError {
          errors {
            code
            message
            field
          }
          address {
            ...Address
          }
          matchedAddress {
            ...Address
          }
        }
      }
    }
  }
  ${CustomerAddressFragmentDoc}
  ${ErrorFragmentDoc}
  ${AddressFragmentDoc}
`;
export type CreateCustomerAddressPosMutationFn = Apollo.MutationFunction<
  Operations.CreateCustomerAddressPosMutation,
  Operations.CreateCustomerAddressPosMutationVariables
>;

/**
 * __useCreateCustomerAddressPosMutation__
 *
 * To run a mutation, you first call `useCreateCustomerAddressPosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerAddressPosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerAddressPosMutation, { data, loading, error }] = useCreateCustomerAddressPosMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerAddressPosMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateCustomerAddressPosMutation,
    Operations.CreateCustomerAddressPosMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreateCustomerAddressPosMutation,
    Operations.CreateCustomerAddressPosMutationVariables
  >(CreateCustomerAddressPosDocument, options);
}
export type CreateCustomerAddressPosMutationHookResult = ReturnType<typeof useCreateCustomerAddressPosMutation>;
export type CreateCustomerAddressPosMutationResult = Apollo.MutationResult<Operations.CreateCustomerAddressPosMutation>;
export type CreateCustomerAddressPosMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateCustomerAddressPosMutation,
  Operations.CreateCustomerAddressPosMutationVariables
>;
export const CreateCustomerCardDocument = gql`
  mutation CreateCustomerCard($input: CreateCustomerCardInput!) {
    createCustomerCard(input: $input) {
      customerCard {
        ...CustomerPaymentMethod
      }
    }
  }
  ${CustomerPaymentMethodFragmentDoc}
`;
export type CreateCustomerCardMutationFn = Apollo.MutationFunction<
  Operations.CreateCustomerCardMutation,
  Operations.CreateCustomerCardMutationVariables
>;

/**
 * __useCreateCustomerCardMutation__
 *
 * To run a mutation, you first call `useCreateCustomerCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerCardMutation, { data, loading, error }] = useCreateCustomerCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateCustomerCardMutation,
    Operations.CreateCustomerCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.CreateCustomerCardMutation, Operations.CreateCustomerCardMutationVariables>(
    CreateCustomerCardDocument,
    options
  );
}
export type CreateCustomerCardMutationHookResult = ReturnType<typeof useCreateCustomerCardMutation>;
export type CreateCustomerCardMutationResult = Apollo.MutationResult<Operations.CreateCustomerCardMutation>;
export type CreateCustomerCardMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateCustomerCardMutation,
  Operations.CreateCustomerCardMutationVariables
>;
export const CreateExternalAccountDocument = gql`
  mutation CreateExternalAccount($input: CreateExternalAccountInput!) {
    createExternalAccount(input: $input) {
      externalAccount {
        ...ExternalAccount
      }
      errors {
        ...Error
      }
    }
  }
  ${ExternalAccountFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CreateExternalAccountMutationFn = Apollo.MutationFunction<
  Operations.CreateExternalAccountMutation,
  Operations.CreateExternalAccountMutationVariables
>;

/**
 * __useCreateExternalAccountMutation__
 *
 * To run a mutation, you first call `useCreateExternalAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalAccountMutation, { data, loading, error }] = useCreateExternalAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExternalAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateExternalAccountMutation,
    Operations.CreateExternalAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreateExternalAccountMutation,
    Operations.CreateExternalAccountMutationVariables
  >(CreateExternalAccountDocument, options);
}
export type CreateExternalAccountMutationHookResult = ReturnType<typeof useCreateExternalAccountMutation>;
export type CreateExternalAccountMutationResult = Apollo.MutationResult<Operations.CreateExternalAccountMutation>;
export type CreateExternalAccountMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateExternalAccountMutation,
  Operations.CreateExternalAccountMutationVariables
>;
export const CreateImageUploadsDocument = gql`
  mutation CreateImageUploads($input: CreateImageUploadsInput!) {
    createImageUploads(input: $input) {
      imageUploads {
        ...ImageUpload
      }
    }
  }
  ${ImageUploadFragmentDoc}
`;
export type CreateImageUploadsMutationFn = Apollo.MutationFunction<
  Operations.CreateImageUploadsMutation,
  Operations.CreateImageUploadsMutationVariables
>;

/**
 * __useCreateImageUploadsMutation__
 *
 * To run a mutation, you first call `useCreateImageUploadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImageUploadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImageUploadsMutation, { data, loading, error }] = useCreateImageUploadsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateImageUploadsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateImageUploadsMutation,
    Operations.CreateImageUploadsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.CreateImageUploadsMutation, Operations.CreateImageUploadsMutationVariables>(
    CreateImageUploadsDocument,
    options
  );
}
export type CreateImageUploadsMutationHookResult = ReturnType<typeof useCreateImageUploadsMutation>;
export type CreateImageUploadsMutationResult = Apollo.MutationResult<Operations.CreateImageUploadsMutation>;
export type CreateImageUploadsMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateImageUploadsMutation,
  Operations.CreateImageUploadsMutationVariables
>;
export const CreateInventoryItemDocument = gql`
  mutation CreateInventoryItem($input: CreateInventoryItemInput!) {
    createInventoryItem(input: $input) {
      inventoryItem {
        ...InventoryItem
      }
      errors {
        ... on Error {
          message
        }
      }
    }
  }
  ${InventoryItemFragmentDoc}
`;
export type CreateInventoryItemMutationFn = Apollo.MutationFunction<
  Operations.CreateInventoryItemMutation,
  Operations.CreateInventoryItemMutationVariables
>;

/**
 * __useCreateInventoryItemMutation__
 *
 * To run a mutation, you first call `useCreateInventoryItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInventoryItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInventoryItemMutation, { data, loading, error }] = useCreateInventoryItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInventoryItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateInventoryItemMutation,
    Operations.CreateInventoryItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.CreateInventoryItemMutation, Operations.CreateInventoryItemMutationVariables>(
    CreateInventoryItemDocument,
    options
  );
}
export type CreateInventoryItemMutationHookResult = ReturnType<typeof useCreateInventoryItemMutation>;
export type CreateInventoryItemMutationResult = Apollo.MutationResult<Operations.CreateInventoryItemMutation>;
export type CreateInventoryItemMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateInventoryItemMutation,
  Operations.CreateInventoryItemMutationVariables
>;
export const CreateLinkTokenDocument = gql`
  mutation CreateLinkToken($input: CreateLinkTokenInput!) {
    createLinkToken(input: $input) {
      string
    }
  }
`;
export type CreateLinkTokenMutationFn = Apollo.MutationFunction<
  Operations.CreateLinkTokenMutation,
  Operations.CreateLinkTokenMutationVariables
>;

/**
 * __useCreateLinkTokenMutation__
 *
 * To run a mutation, you first call `useCreateLinkTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinkTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinkTokenMutation, { data, loading, error }] = useCreateLinkTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLinkTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateLinkTokenMutation,
    Operations.CreateLinkTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.CreateLinkTokenMutation, Operations.CreateLinkTokenMutationVariables>(
    CreateLinkTokenDocument,
    options
  );
}
export type CreateLinkTokenMutationHookResult = ReturnType<typeof useCreateLinkTokenMutation>;
export type CreateLinkTokenMutationResult = Apollo.MutationResult<Operations.CreateLinkTokenMutation>;
export type CreateLinkTokenMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateLinkTokenMutation,
  Operations.CreateLinkTokenMutationVariables
>;
export const CreateOptionDocument = gql`
  mutation CreateOption($input: CreateOptionInput!) {
    createOption(input: $input) {
      option {
        ...Option
      }
      errors {
        ...Error
      }
    }
  }
  ${OptionFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CreateOptionMutationFn = Apollo.MutationFunction<
  Operations.CreateOptionMutation,
  Operations.CreateOptionMutationVariables
>;

/**
 * __useCreateOptionMutation__
 *
 * To run a mutation, you first call `useCreateOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOptionMutation, { data, loading, error }] = useCreateOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOptionMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.CreateOptionMutation, Operations.CreateOptionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.CreateOptionMutation, Operations.CreateOptionMutationVariables>(
    CreateOptionDocument,
    options
  );
}
export type CreateOptionMutationHookResult = ReturnType<typeof useCreateOptionMutation>;
export type CreateOptionMutationResult = Apollo.MutationResult<Operations.CreateOptionMutation>;
export type CreateOptionMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateOptionMutation,
  Operations.CreateOptionMutationVariables
>;
export const CreateOrGetGuestCustomerDocument = gql`
  mutation CreateOrGetGuestCustomer($input: CreateOrGetGuestCustomerInput!) {
    createOrGetGuestCustomer(input: $input) {
      customer {
        ...Customer
      }
    }
  }
  ${CustomerFragmentDoc}
`;
export type CreateOrGetGuestCustomerMutationFn = Apollo.MutationFunction<
  Operations.CreateOrGetGuestCustomerMutation,
  Operations.CreateOrGetGuestCustomerMutationVariables
>;

/**
 * __useCreateOrGetGuestCustomerMutation__
 *
 * To run a mutation, you first call `useCreateOrGetGuestCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrGetGuestCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrGetGuestCustomerMutation, { data, loading, error }] = useCreateOrGetGuestCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrGetGuestCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateOrGetGuestCustomerMutation,
    Operations.CreateOrGetGuestCustomerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreateOrGetGuestCustomerMutation,
    Operations.CreateOrGetGuestCustomerMutationVariables
  >(CreateOrGetGuestCustomerDocument, options);
}
export type CreateOrGetGuestCustomerMutationHookResult = ReturnType<typeof useCreateOrGetGuestCustomerMutation>;
export type CreateOrGetGuestCustomerMutationResult = Apollo.MutationResult<Operations.CreateOrGetGuestCustomerMutation>;
export type CreateOrGetGuestCustomerMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateOrGetGuestCustomerMutation,
  Operations.CreateOrGetGuestCustomerMutationVariables
>;
export const CreateOrderFromCheckoutDocument = gql`
  mutation CreateOrderFromCheckout($input: CreateOrderFromCheckoutInput!) {
    createOrderFromCheckout(input: $input) {
      order {
        ...OrderDetails
      }
      errors {
        ...Error
        ... on PaymentError {
          errorCode
          cardDeclineCode
        }
      }
    }
  }
  ${OrderDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CreateOrderFromCheckoutMutationFn = Apollo.MutationFunction<
  Operations.CreateOrderFromCheckoutMutation,
  Operations.CreateOrderFromCheckoutMutationVariables
>;

/**
 * __useCreateOrderFromCheckoutMutation__
 *
 * To run a mutation, you first call `useCreateOrderFromCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderFromCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderFromCheckoutMutation, { data, loading, error }] = useCreateOrderFromCheckoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderFromCheckoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateOrderFromCheckoutMutation,
    Operations.CreateOrderFromCheckoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreateOrderFromCheckoutMutation,
    Operations.CreateOrderFromCheckoutMutationVariables
  >(CreateOrderFromCheckoutDocument, options);
}
export type CreateOrderFromCheckoutMutationHookResult = ReturnType<typeof useCreateOrderFromCheckoutMutation>;
export type CreateOrderFromCheckoutMutationResult = Apollo.MutationResult<Operations.CreateOrderFromCheckoutMutation>;
export type CreateOrderFromCheckoutMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateOrderFromCheckoutMutation,
  Operations.CreateOrderFromCheckoutMutationVariables
>;
export const CreateOrderFromCheckoutPosDocument = gql`
  mutation CreateOrderFromCheckoutPos($input: CreateOrderFromCheckoutPosInput!) {
    createOrderFromCheckoutPos(input: $input) {
      order {
        ...OrderDetails
        orderShipments {
          nodes {
            ...OrderShipment
          }
        }
      }
      errors {
        ...Error
        ... on PaymentError {
          errorCode
          cardDeclineCode
        }
      }
    }
  }
  ${OrderDetailsFragmentDoc}
  ${OrderShipmentFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CreateOrderFromCheckoutPosMutationFn = Apollo.MutationFunction<
  Operations.CreateOrderFromCheckoutPosMutation,
  Operations.CreateOrderFromCheckoutPosMutationVariables
>;

/**
 * __useCreateOrderFromCheckoutPosMutation__
 *
 * To run a mutation, you first call `useCreateOrderFromCheckoutPosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderFromCheckoutPosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderFromCheckoutPosMutation, { data, loading, error }] = useCreateOrderFromCheckoutPosMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderFromCheckoutPosMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateOrderFromCheckoutPosMutation,
    Operations.CreateOrderFromCheckoutPosMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreateOrderFromCheckoutPosMutation,
    Operations.CreateOrderFromCheckoutPosMutationVariables
  >(CreateOrderFromCheckoutPosDocument, options);
}
export type CreateOrderFromCheckoutPosMutationHookResult = ReturnType<typeof useCreateOrderFromCheckoutPosMutation>;
export type CreateOrderFromCheckoutPosMutationResult =
  Apollo.MutationResult<Operations.CreateOrderFromCheckoutPosMutation>;
export type CreateOrderFromCheckoutPosMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateOrderFromCheckoutPosMutation,
  Operations.CreateOrderFromCheckoutPosMutationVariables
>;
export const CreatePackageDocument = gql`
  mutation CreatePackage($input: CreatePackageInput!) {
    createPackage(input: $input) {
      orderPackage {
        id
        status
        orderFulfilmentLineItems {
          id
        }
        shopOrderId
        estimatedDeliveryDate
        orderId
        shippingProvider
        currency
        shippingLabelUrl
        deliveryDays
        packingSlipUrl
        shippingCarrierPackage {
          id
          dimensions {
            length
            width
            height
            unit
          }
          name
        }
        primaryWeight {
          value
          unit
        }
        secondaryWeight {
          value
          unit
        }
        totalWeight {
          value
          unit
        }
      }
    }
  }
`;
export type CreatePackageMutationFn = Apollo.MutationFunction<
  Operations.CreatePackageMutation,
  Operations.CreatePackageMutationVariables
>;

/**
 * __useCreatePackageMutation__
 *
 * To run a mutation, you first call `useCreatePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPackageMutation, { data, loading, error }] = useCreatePackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePackageMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.CreatePackageMutation, Operations.CreatePackageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.CreatePackageMutation, Operations.CreatePackageMutationVariables>(
    CreatePackageDocument,
    options
  );
}
export type CreatePackageMutationHookResult = ReturnType<typeof useCreatePackageMutation>;
export type CreatePackageMutationResult = Apollo.MutationResult<Operations.CreatePackageMutation>;
export type CreatePackageMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreatePackageMutation,
  Operations.CreatePackageMutationVariables
>;
export const CreatePaymentAccountDocument = gql`
  mutation CreatePaymentAccount($input: CreatePaymentAccountInput!) {
    createPaymentAccount(input: $input) {
      paymentAccount {
        ...PaymentAccount
      }
      errors {
        ...Error
      }
    }
  }
  ${PaymentAccountFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CreatePaymentAccountMutationFn = Apollo.MutationFunction<
  Operations.CreatePaymentAccountMutation,
  Operations.CreatePaymentAccountMutationVariables
>;

/**
 * __useCreatePaymentAccountMutation__
 *
 * To run a mutation, you first call `useCreatePaymentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentAccountMutation, { data, loading, error }] = useCreatePaymentAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreatePaymentAccountMutation,
    Operations.CreatePaymentAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.CreatePaymentAccountMutation, Operations.CreatePaymentAccountMutationVariables>(
    CreatePaymentAccountDocument,
    options
  );
}
export type CreatePaymentAccountMutationHookResult = ReturnType<typeof useCreatePaymentAccountMutation>;
export type CreatePaymentAccountMutationResult = Apollo.MutationResult<Operations.CreatePaymentAccountMutation>;
export type CreatePaymentAccountMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreatePaymentAccountMutation,
  Operations.CreatePaymentAccountMutationVariables
>;
export const CreatePaymentAccountOnboardingLinkDocument = gql`
  mutation CreatePaymentAccountOnboardingLink($input: CreatePaymentAccountOnboardingLinkInput!) {
    createPaymentAccountOnboardingLink(input: $input) {
      paymentAccountLink {
        ...PaymentAccountLink
      }
      errors {
        ...Error
      }
    }
  }
  ${PaymentAccountLinkFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CreatePaymentAccountOnboardingLinkMutationFn = Apollo.MutationFunction<
  Operations.CreatePaymentAccountOnboardingLinkMutation,
  Operations.CreatePaymentAccountOnboardingLinkMutationVariables
>;

/**
 * __useCreatePaymentAccountOnboardingLinkMutation__
 *
 * To run a mutation, you first call `useCreatePaymentAccountOnboardingLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentAccountOnboardingLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentAccountOnboardingLinkMutation, { data, loading, error }] = useCreatePaymentAccountOnboardingLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentAccountOnboardingLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreatePaymentAccountOnboardingLinkMutation,
    Operations.CreatePaymentAccountOnboardingLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreatePaymentAccountOnboardingLinkMutation,
    Operations.CreatePaymentAccountOnboardingLinkMutationVariables
  >(CreatePaymentAccountOnboardingLinkDocument, options);
}
export type CreatePaymentAccountOnboardingLinkMutationHookResult = ReturnType<
  typeof useCreatePaymentAccountOnboardingLinkMutation
>;
export type CreatePaymentAccountOnboardingLinkMutationResult =
  Apollo.MutationResult<Operations.CreatePaymentAccountOnboardingLinkMutation>;
export type CreatePaymentAccountOnboardingLinkMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreatePaymentAccountOnboardingLinkMutation,
  Operations.CreatePaymentAccountOnboardingLinkMutationVariables
>;
export const CreateProductDocument = gql`
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      product {
        ...ProductDetails
      }
      errors {
        ...Error
        ...ShopLocationNotFoundError
      }
    }
  }
  ${ProductDetailsFragmentDoc}
  ${ErrorFragmentDoc}
  ${ShopLocationNotFoundErrorFragmentDoc}
`;
export type CreateProductMutationFn = Apollo.MutationFunction<
  Operations.CreateProductMutation,
  Operations.CreateProductMutationVariables
>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.CreateProductMutation, Operations.CreateProductMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.CreateProductMutation, Operations.CreateProductMutationVariables>(
    CreateProductDocument,
    options
  );
}
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<Operations.CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateProductMutation,
  Operations.CreateProductMutationVariables
>;
export const CreateProfileImageUploadsDocument = gql`
  mutation CreateProfileImageUploads($input: CreateProfileImageUploadsInput!) {
    createProfileImageUploads(input: $input) {
      profileImageUploads {
        filename
        profileImageId
        uploadUrl
        focusRegion {
          height
          width
          x
          y
        }
      }
    }
  }
`;
export type CreateProfileImageUploadsMutationFn = Apollo.MutationFunction<
  Operations.CreateProfileImageUploadsMutation,
  Operations.CreateProfileImageUploadsMutationVariables
>;

/**
 * __useCreateProfileImageUploadsMutation__
 *
 * To run a mutation, you first call `useCreateProfileImageUploadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileImageUploadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileImageUploadsMutation, { data, loading, error }] = useCreateProfileImageUploadsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProfileImageUploadsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateProfileImageUploadsMutation,
    Operations.CreateProfileImageUploadsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreateProfileImageUploadsMutation,
    Operations.CreateProfileImageUploadsMutationVariables
  >(CreateProfileImageUploadsDocument, options);
}
export type CreateProfileImageUploadsMutationHookResult = ReturnType<typeof useCreateProfileImageUploadsMutation>;
export type CreateProfileImageUploadsMutationResult =
  Apollo.MutationResult<Operations.CreateProfileImageUploadsMutation>;
export type CreateProfileImageUploadsMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateProfileImageUploadsMutation,
  Operations.CreateProfileImageUploadsMutationVariables
>;
export const CreateShopDocument = gql`
  mutation CreateShop($input: CreateShopInput!) {
    createShop(input: $input) {
      shop {
        ...Shop
      }
      errors {
        ...Error
      }
    }
  }
  ${ShopFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CreateShopMutationFn = Apollo.MutationFunction<
  Operations.CreateShopMutation,
  Operations.CreateShopMutationVariables
>;

/**
 * __useCreateShopMutation__
 *
 * To run a mutation, you first call `useCreateShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShopMutation, { data, loading, error }] = useCreateShopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShopMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.CreateShopMutation, Operations.CreateShopMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.CreateShopMutation, Operations.CreateShopMutationVariables>(
    CreateShopDocument,
    options
  );
}
export type CreateShopMutationHookResult = ReturnType<typeof useCreateShopMutation>;
export type CreateShopMutationResult = Apollo.MutationResult<Operations.CreateShopMutation>;
export type CreateShopMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateShopMutation,
  Operations.CreateShopMutationVariables
>;
export const CreateShopCustomizationDocument = gql`
  mutation CreateShopCustomization($input: CreateShopCustomizationInput!) {
    createShopCustomization(input: $input) {
      shopCustomization {
        shopCoverPhotoId
        shopDescription
        shopCoverPhoto {
          id
        }
        shopProfileImageId
        shopProfileImage {
          id
        }
      }
    }
  }
`;
export type CreateShopCustomizationMutationFn = Apollo.MutationFunction<
  Operations.CreateShopCustomizationMutation,
  Operations.CreateShopCustomizationMutationVariables
>;

/**
 * __useCreateShopCustomizationMutation__
 *
 * To run a mutation, you first call `useCreateShopCustomizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShopCustomizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShopCustomizationMutation, { data, loading, error }] = useCreateShopCustomizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShopCustomizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateShopCustomizationMutation,
    Operations.CreateShopCustomizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreateShopCustomizationMutation,
    Operations.CreateShopCustomizationMutationVariables
  >(CreateShopCustomizationDocument, options);
}
export type CreateShopCustomizationMutationHookResult = ReturnType<typeof useCreateShopCustomizationMutation>;
export type CreateShopCustomizationMutationResult = Apollo.MutationResult<Operations.CreateShopCustomizationMutation>;
export type CreateShopCustomizationMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateShopCustomizationMutation,
  Operations.CreateShopCustomizationMutationVariables
>;
export const CreateShowDocument = gql`
  mutation CreateShow($input: CreateShowInput!) {
    createShow(input: $input) {
      show {
        ...Show
      }
      errors {
        ...Error
      }
    }
  }
  ${ShowFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CreateShowMutationFn = Apollo.MutationFunction<
  Operations.CreateShowMutation,
  Operations.CreateShowMutationVariables
>;

/**
 * __useCreateShowMutation__
 *
 * To run a mutation, you first call `useCreateShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShowMutation, { data, loading, error }] = useCreateShowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShowMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.CreateShowMutation, Operations.CreateShowMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.CreateShowMutation, Operations.CreateShowMutationVariables>(
    CreateShowDocument,
    options
  );
}
export type CreateShowMutationHookResult = ReturnType<typeof useCreateShowMutation>;
export type CreateShowMutationResult = Apollo.MutationResult<Operations.CreateShowMutation>;
export type CreateShowMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateShowMutation,
  Operations.CreateShowMutationVariables
>;
export const CreateStreamDocument = gql`
  mutation CreateStream($input: CreateStreamInput!) {
    createStream(input: $input) {
      stream {
        id
        sessionId
        chatRoomId
        show {
          id
        }
      }
    }
  }
`;
export type CreateStreamMutationFn = Apollo.MutationFunction<
  Operations.CreateStreamMutation,
  Operations.CreateStreamMutationVariables
>;

/**
 * __useCreateStreamMutation__
 *
 * To run a mutation, you first call `useCreateStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStreamMutation, { data, loading, error }] = useCreateStreamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStreamMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.CreateStreamMutation, Operations.CreateStreamMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.CreateStreamMutation, Operations.CreateStreamMutationVariables>(
    CreateStreamDocument,
    options
  );
}
export type CreateStreamMutationHookResult = ReturnType<typeof useCreateStreamMutation>;
export type CreateStreamMutationResult = Apollo.MutationResult<Operations.CreateStreamMutation>;
export type CreateStreamMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateStreamMutation,
  Operations.CreateStreamMutationVariables
>;
export const CreateVariantDocument = gql`
  mutation CreateVariant($input: CreateVariantInput!) {
    createVariant(input: $input) {
      variant {
        ...Variant
      }
      errors {
        ...Error
        ...ShopLocationNotFoundError
        ...DuplicateVariantOptionError
      }
    }
  }
  ${VariantFragmentDoc}
  ${ErrorFragmentDoc}
  ${ShopLocationNotFoundErrorFragmentDoc}
  ${DuplicateVariantOptionErrorFragmentDoc}
`;
export type CreateVariantMutationFn = Apollo.MutationFunction<
  Operations.CreateVariantMutation,
  Operations.CreateVariantMutationVariables
>;

/**
 * __useCreateVariantMutation__
 *
 * To run a mutation, you first call `useCreateVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVariantMutation, { data, loading, error }] = useCreateVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVariantMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.CreateVariantMutation, Operations.CreateVariantMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.CreateVariantMutation, Operations.CreateVariantMutationVariables>(
    CreateVariantDocument,
    options
  );
}
export type CreateVariantMutationHookResult = ReturnType<typeof useCreateVariantMutation>;
export type CreateVariantMutationResult = Apollo.MutationResult<Operations.CreateVariantMutation>;
export type CreateVariantMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateVariantMutation,
  Operations.CreateVariantMutationVariables
>;
export const DeclineAffiliateInviteDocument = gql`
  mutation DeclineAffiliateInvite($input: DeclineAffiliateInviteInput!) {
    declineAffiliateInvite(input: $input) {
      show {
        ...Show
      }
      errors {
        ...Error
      }
    }
  }
  ${ShowFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type DeclineAffiliateInviteMutationFn = Apollo.MutationFunction<
  Operations.DeclineAffiliateInviteMutation,
  Operations.DeclineAffiliateInviteMutationVariables
>;

/**
 * __useDeclineAffiliateInviteMutation__
 *
 * To run a mutation, you first call `useDeclineAffiliateInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineAffiliateInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineAffiliateInviteMutation, { data, loading, error }] = useDeclineAffiliateInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineAffiliateInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.DeclineAffiliateInviteMutation,
    Operations.DeclineAffiliateInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.DeclineAffiliateInviteMutation,
    Operations.DeclineAffiliateInviteMutationVariables
  >(DeclineAffiliateInviteDocument, options);
}
export type DeclineAffiliateInviteMutationHookResult = ReturnType<typeof useDeclineAffiliateInviteMutation>;
export type DeclineAffiliateInviteMutationResult = Apollo.MutationResult<Operations.DeclineAffiliateInviteMutation>;
export type DeclineAffiliateInviteMutationOptions = Apollo.BaseMutationOptions<
  Operations.DeclineAffiliateInviteMutation,
  Operations.DeclineAffiliateInviteMutationVariables
>;
export const DeleteCustomerAddressDocument = gql`
  mutation DeleteCustomerAddress($input: DeleteCustomerAddressInput!) {
    deleteCustomerAddress(input: $input) {
      customerAddress {
        ...CustomerAddress
      }
    }
  }
  ${CustomerAddressFragmentDoc}
`;
export type DeleteCustomerAddressMutationFn = Apollo.MutationFunction<
  Operations.DeleteCustomerAddressMutation,
  Operations.DeleteCustomerAddressMutationVariables
>;

/**
 * __useDeleteCustomerAddressMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerAddressMutation, { data, loading, error }] = useDeleteCustomerAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomerAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.DeleteCustomerAddressMutation,
    Operations.DeleteCustomerAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.DeleteCustomerAddressMutation,
    Operations.DeleteCustomerAddressMutationVariables
  >(DeleteCustomerAddressDocument, options);
}
export type DeleteCustomerAddressMutationHookResult = ReturnType<typeof useDeleteCustomerAddressMutation>;
export type DeleteCustomerAddressMutationResult = Apollo.MutationResult<Operations.DeleteCustomerAddressMutation>;
export type DeleteCustomerAddressMutationOptions = Apollo.BaseMutationOptions<
  Operations.DeleteCustomerAddressMutation,
  Operations.DeleteCustomerAddressMutationVariables
>;
export const DeleteCustomerPaymentMethodDocument = gql`
  mutation DeleteCustomerPaymentMethod($input: DeleteCustomerPaymentMethodInput!) {
    deleteCustomerPaymentMethod(input: $input) {
      customerPaymentMethod {
        ...CustomerPaymentMethod
      }
    }
  }
  ${CustomerPaymentMethodFragmentDoc}
`;
export type DeleteCustomerPaymentMethodMutationFn = Apollo.MutationFunction<
  Operations.DeleteCustomerPaymentMethodMutation,
  Operations.DeleteCustomerPaymentMethodMutationVariables
>;

/**
 * __useDeleteCustomerPaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerPaymentMethodMutation, { data, loading, error }] = useDeleteCustomerPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomerPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.DeleteCustomerPaymentMethodMutation,
    Operations.DeleteCustomerPaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.DeleteCustomerPaymentMethodMutation,
    Operations.DeleteCustomerPaymentMethodMutationVariables
  >(DeleteCustomerPaymentMethodDocument, options);
}
export type DeleteCustomerPaymentMethodMutationHookResult = ReturnType<typeof useDeleteCustomerPaymentMethodMutation>;
export type DeleteCustomerPaymentMethodMutationResult =
  Apollo.MutationResult<Operations.DeleteCustomerPaymentMethodMutation>;
export type DeleteCustomerPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  Operations.DeleteCustomerPaymentMethodMutation,
  Operations.DeleteCustomerPaymentMethodMutationVariables
>;
export const DeleteExternalAccountDocument = gql`
  mutation DeleteExternalAccount($input: DeleteExternalAccountInput!) {
    deleteExternalAccount(input: $input) {
      externalAccount {
        ...ExternalAccount
      }
    }
  }
  ${ExternalAccountFragmentDoc}
`;
export type DeleteExternalAccountMutationFn = Apollo.MutationFunction<
  Operations.DeleteExternalAccountMutation,
  Operations.DeleteExternalAccountMutationVariables
>;

/**
 * __useDeleteExternalAccountMutation__
 *
 * To run a mutation, you first call `useDeleteExternalAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExternalAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExternalAccountMutation, { data, loading, error }] = useDeleteExternalAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteExternalAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.DeleteExternalAccountMutation,
    Operations.DeleteExternalAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.DeleteExternalAccountMutation,
    Operations.DeleteExternalAccountMutationVariables
  >(DeleteExternalAccountDocument, options);
}
export type DeleteExternalAccountMutationHookResult = ReturnType<typeof useDeleteExternalAccountMutation>;
export type DeleteExternalAccountMutationResult = Apollo.MutationResult<Operations.DeleteExternalAccountMutation>;
export type DeleteExternalAccountMutationOptions = Apollo.BaseMutationOptions<
  Operations.DeleteExternalAccountMutation,
  Operations.DeleteExternalAccountMutationVariables
>;
export const DeleteOptionDocument = gql`
  mutation DeleteOption($input: DeleteOptionInput!) {
    deleteOption(input: $input) {
      option {
        ...Option
      }
    }
  }
  ${OptionFragmentDoc}
`;
export type DeleteOptionMutationFn = Apollo.MutationFunction<
  Operations.DeleteOptionMutation,
  Operations.DeleteOptionMutationVariables
>;

/**
 * __useDeleteOptionMutation__
 *
 * To run a mutation, you first call `useDeleteOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOptionMutation, { data, loading, error }] = useDeleteOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOptionMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.DeleteOptionMutation, Operations.DeleteOptionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.DeleteOptionMutation, Operations.DeleteOptionMutationVariables>(
    DeleteOptionDocument,
    options
  );
}
export type DeleteOptionMutationHookResult = ReturnType<typeof useDeleteOptionMutation>;
export type DeleteOptionMutationResult = Apollo.MutationResult<Operations.DeleteOptionMutation>;
export type DeleteOptionMutationOptions = Apollo.BaseMutationOptions<
  Operations.DeleteOptionMutation,
  Operations.DeleteOptionMutationVariables
>;
export const DeleteProductImageDocument = gql`
  mutation DeleteProductImage($input: DeleteProductImageInput!) {
    deleteProductImage(input: $input) {
      productImage {
        ...ProductImage
      }
    }
  }
  ${ProductImageFragmentDoc}
`;
export type DeleteProductImageMutationFn = Apollo.MutationFunction<
  Operations.DeleteProductImageMutation,
  Operations.DeleteProductImageMutationVariables
>;

/**
 * __useDeleteProductImageMutation__
 *
 * To run a mutation, you first call `useDeleteProductImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductImageMutation, { data, loading, error }] = useDeleteProductImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProductImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.DeleteProductImageMutation,
    Operations.DeleteProductImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.DeleteProductImageMutation, Operations.DeleteProductImageMutationVariables>(
    DeleteProductImageDocument,
    options
  );
}
export type DeleteProductImageMutationHookResult = ReturnType<typeof useDeleteProductImageMutation>;
export type DeleteProductImageMutationResult = Apollo.MutationResult<Operations.DeleteProductImageMutation>;
export type DeleteProductImageMutationOptions = Apollo.BaseMutationOptions<
  Operations.DeleteProductImageMutation,
  Operations.DeleteProductImageMutationVariables
>;
export const DeleteVariantDocument = gql`
  mutation DeleteVariant($input: DeleteVariantInput!) {
    deleteVariant(input: $input) {
      variant {
        ...Variant
      }
    }
  }
  ${VariantFragmentDoc}
`;
export type DeleteVariantMutationFn = Apollo.MutationFunction<
  Operations.DeleteVariantMutation,
  Operations.DeleteVariantMutationVariables
>;

/**
 * __useDeleteVariantMutation__
 *
 * To run a mutation, you first call `useDeleteVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVariantMutation, { data, loading, error }] = useDeleteVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVariantMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.DeleteVariantMutation, Operations.DeleteVariantMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.DeleteVariantMutation, Operations.DeleteVariantMutationVariables>(
    DeleteVariantDocument,
    options
  );
}
export type DeleteVariantMutationHookResult = ReturnType<typeof useDeleteVariantMutation>;
export type DeleteVariantMutationResult = Apollo.MutationResult<Operations.DeleteVariantMutation>;
export type DeleteVariantMutationOptions = Apollo.BaseMutationOptions<
  Operations.DeleteVariantMutation,
  Operations.DeleteVariantMutationVariables
>;
export const DeleteVariantImageDocument = gql`
  mutation DeleteVariantImage($input: DeleteVariantImageInput!) {
    deleteVariantImage(input: $input) {
      variantImage {
        ...VariantImage
      }
    }
  }
  ${VariantImageFragmentDoc}
`;
export type DeleteVariantImageMutationFn = Apollo.MutationFunction<
  Operations.DeleteVariantImageMutation,
  Operations.DeleteVariantImageMutationVariables
>;

/**
 * __useDeleteVariantImageMutation__
 *
 * To run a mutation, you first call `useDeleteVariantImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVariantImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVariantImageMutation, { data, loading, error }] = useDeleteVariantImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVariantImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.DeleteVariantImageMutation,
    Operations.DeleteVariantImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.DeleteVariantImageMutation, Operations.DeleteVariantImageMutationVariables>(
    DeleteVariantImageDocument,
    options
  );
}
export type DeleteVariantImageMutationHookResult = ReturnType<typeof useDeleteVariantImageMutation>;
export type DeleteVariantImageMutationResult = Apollo.MutationResult<Operations.DeleteVariantImageMutation>;
export type DeleteVariantImageMutationOptions = Apollo.BaseMutationOptions<
  Operations.DeleteVariantImageMutation,
  Operations.DeleteVariantImageMutationVariables
>;
export const DisableFbCommentsDocument = gql`
  mutation DisableFbComments($input: DisableFbCommentsInput!) {
    disableFbComments(input: $input) {
      fbCommentsSession {
        ...FbCommentsSession
      }
    }
  }
  ${FbCommentsSessionFragmentDoc}
`;
export type DisableFbCommentsMutationFn = Apollo.MutationFunction<
  Operations.DisableFbCommentsMutation,
  Operations.DisableFbCommentsMutationVariables
>;

/**
 * __useDisableFbCommentsMutation__
 *
 * To run a mutation, you first call `useDisableFbCommentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableFbCommentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableFbCommentsMutation, { data, loading, error }] = useDisableFbCommentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisableFbCommentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.DisableFbCommentsMutation,
    Operations.DisableFbCommentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.DisableFbCommentsMutation, Operations.DisableFbCommentsMutationVariables>(
    DisableFbCommentsDocument,
    options
  );
}
export type DisableFbCommentsMutationHookResult = ReturnType<typeof useDisableFbCommentsMutation>;
export type DisableFbCommentsMutationResult = Apollo.MutationResult<Operations.DisableFbCommentsMutation>;
export type DisableFbCommentsMutationOptions = Apollo.BaseMutationOptions<
  Operations.DisableFbCommentsMutation,
  Operations.DisableFbCommentsMutationVariables
>;
export const DisconnectFbBusinessAccountDocument = gql`
  mutation DisconnectFbBusinessAccount($input: DisconnectFbBusinessAccountInput!) {
    disconnectFbBusinessAccount(input: $input) {
      fbBusinessAccount {
        ...FbBusinessAccount
      }
      errors {
        ... on Error {
          message
        }
      }
    }
  }
  ${FbBusinessAccountFragmentDoc}
`;
export type DisconnectFbBusinessAccountMutationFn = Apollo.MutationFunction<
  Operations.DisconnectFbBusinessAccountMutation,
  Operations.DisconnectFbBusinessAccountMutationVariables
>;

/**
 * __useDisconnectFbBusinessAccountMutation__
 *
 * To run a mutation, you first call `useDisconnectFbBusinessAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectFbBusinessAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectFbBusinessAccountMutation, { data, loading, error }] = useDisconnectFbBusinessAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisconnectFbBusinessAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.DisconnectFbBusinessAccountMutation,
    Operations.DisconnectFbBusinessAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.DisconnectFbBusinessAccountMutation,
    Operations.DisconnectFbBusinessAccountMutationVariables
  >(DisconnectFbBusinessAccountDocument, options);
}
export type DisconnectFbBusinessAccountMutationHookResult = ReturnType<typeof useDisconnectFbBusinessAccountMutation>;
export type DisconnectFbBusinessAccountMutationResult =
  Apollo.MutationResult<Operations.DisconnectFbBusinessAccountMutation>;
export type DisconnectFbBusinessAccountMutationOptions = Apollo.BaseMutationOptions<
  Operations.DisconnectFbBusinessAccountMutation,
  Operations.DisconnectFbBusinessAccountMutationVariables
>;
export const EnableFbCommentsDocument = gql`
  mutation EnableFbComments($input: EnableFbCommentsInput!) {
    enableFbComments(input: $input) {
      fbCommentsSession {
        ...FbCommentsSession
      }
      errors {
        ...Error
      }
    }
  }
  ${FbCommentsSessionFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type EnableFbCommentsMutationFn = Apollo.MutationFunction<
  Operations.EnableFbCommentsMutation,
  Operations.EnableFbCommentsMutationVariables
>;

/**
 * __useEnableFbCommentsMutation__
 *
 * To run a mutation, you first call `useEnableFbCommentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableFbCommentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableFbCommentsMutation, { data, loading, error }] = useEnableFbCommentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableFbCommentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.EnableFbCommentsMutation,
    Operations.EnableFbCommentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.EnableFbCommentsMutation, Operations.EnableFbCommentsMutationVariables>(
    EnableFbCommentsDocument,
    options
  );
}
export type EnableFbCommentsMutationHookResult = ReturnType<typeof useEnableFbCommentsMutation>;
export type EnableFbCommentsMutationResult = Apollo.MutationResult<Operations.EnableFbCommentsMutation>;
export type EnableFbCommentsMutationOptions = Apollo.BaseMutationOptions<
  Operations.EnableFbCommentsMutation,
  Operations.EnableFbCommentsMutationVariables
>;
export const ExchangePublicTokenDocument = gql`
  mutation ExchangePublicToken($input: ExchangePublicTokenInput!) {
    exchangePublicToken(input: $input) {
      string
    }
  }
`;
export type ExchangePublicTokenMutationFn = Apollo.MutationFunction<
  Operations.ExchangePublicTokenMutation,
  Operations.ExchangePublicTokenMutationVariables
>;

/**
 * __useExchangePublicTokenMutation__
 *
 * To run a mutation, you first call `useExchangePublicTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExchangePublicTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exchangePublicTokenMutation, { data, loading, error }] = useExchangePublicTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExchangePublicTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.ExchangePublicTokenMutation,
    Operations.ExchangePublicTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.ExchangePublicTokenMutation, Operations.ExchangePublicTokenMutationVariables>(
    ExchangePublicTokenDocument,
    options
  );
}
export type ExchangePublicTokenMutationHookResult = ReturnType<typeof useExchangePublicTokenMutation>;
export type ExchangePublicTokenMutationResult = Apollo.MutationResult<Operations.ExchangePublicTokenMutation>;
export type ExchangePublicTokenMutationOptions = Apollo.BaseMutationOptions<
  Operations.ExchangePublicTokenMutation,
  Operations.ExchangePublicTokenMutationVariables
>;
export const FulfillOrderShipmentLineItemDocument = gql`
  mutation FulfillOrderShipmentLineItem($input: FulfillOrderShipmentLineItemInput!) {
    fulfillOrderShipmentLineItem(input: $input) {
      orderShipmentLineItem {
        ...OrderShipmentLineItem
        orderShipment {
          shopOrder {
            ...ShopOrder
          }
        }
      }
      errors {
        ...Error
      }
    }
  }
  ${OrderShipmentLineItemFragmentDoc}
  ${ShopOrderFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type FulfillOrderShipmentLineItemMutationFn = Apollo.MutationFunction<
  Operations.FulfillOrderShipmentLineItemMutation,
  Operations.FulfillOrderShipmentLineItemMutationVariables
>;

/**
 * __useFulfillOrderShipmentLineItemMutation__
 *
 * To run a mutation, you first call `useFulfillOrderShipmentLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFulfillOrderShipmentLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fulfillOrderShipmentLineItemMutation, { data, loading, error }] = useFulfillOrderShipmentLineItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFulfillOrderShipmentLineItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.FulfillOrderShipmentLineItemMutation,
    Operations.FulfillOrderShipmentLineItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.FulfillOrderShipmentLineItemMutation,
    Operations.FulfillOrderShipmentLineItemMutationVariables
  >(FulfillOrderShipmentLineItemDocument, options);
}
export type FulfillOrderShipmentLineItemMutationHookResult = ReturnType<typeof useFulfillOrderShipmentLineItemMutation>;
export type FulfillOrderShipmentLineItemMutationResult =
  Apollo.MutationResult<Operations.FulfillOrderShipmentLineItemMutation>;
export type FulfillOrderShipmentLineItemMutationOptions = Apollo.BaseMutationOptions<
  Operations.FulfillOrderShipmentLineItemMutation,
  Operations.FulfillOrderShipmentLineItemMutationVariables
>;
export const InviteShowAffiliateDocument = gql`
  mutation InviteShowAffiliate($input: InviteShowAffiliateInput!) {
    inviteShowAffiliate(input: $input) {
      show {
        id
        name
        description
        shopId
        start
        end
        startedAt
        endedAt
        slug
      }
    }
  }
`;
export type InviteShowAffiliateMutationFn = Apollo.MutationFunction<
  Operations.InviteShowAffiliateMutation,
  Operations.InviteShowAffiliateMutationVariables
>;

/**
 * __useInviteShowAffiliateMutation__
 *
 * To run a mutation, you first call `useInviteShowAffiliateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteShowAffiliateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteShowAffiliateMutation, { data, loading, error }] = useInviteShowAffiliateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteShowAffiliateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.InviteShowAffiliateMutation,
    Operations.InviteShowAffiliateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.InviteShowAffiliateMutation, Operations.InviteShowAffiliateMutationVariables>(
    InviteShowAffiliateDocument,
    options
  );
}
export type InviteShowAffiliateMutationHookResult = ReturnType<typeof useInviteShowAffiliateMutation>;
export type InviteShowAffiliateMutationResult = Apollo.MutationResult<Operations.InviteShowAffiliateMutation>;
export type InviteShowAffiliateMutationOptions = Apollo.BaseMutationOptions<
  Operations.InviteShowAffiliateMutation,
  Operations.InviteShowAffiliateMutationVariables
>;
export const LinkFbPageUserDocument = gql`
  mutation LinkFbPageUser($commentId: ID!) {
    linkFbPageUser(input: { commentId: $commentId }) {
      fbPageUser {
        pageScopedUserId
        userId
        pageId
        businessAccountId
      }
      errors {
        ... on Error {
          message
        }
      }
    }
  }
`;
export type LinkFbPageUserMutationFn = Apollo.MutationFunction<
  Operations.LinkFbPageUserMutation,
  Operations.LinkFbPageUserMutationVariables
>;

/**
 * __useLinkFbPageUserMutation__
 *
 * To run a mutation, you first call `useLinkFbPageUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkFbPageUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkFbPageUserMutation, { data, loading, error }] = useLinkFbPageUserMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useLinkFbPageUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.LinkFbPageUserMutation,
    Operations.LinkFbPageUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.LinkFbPageUserMutation, Operations.LinkFbPageUserMutationVariables>(
    LinkFbPageUserDocument,
    options
  );
}
export type LinkFbPageUserMutationHookResult = ReturnType<typeof useLinkFbPageUserMutation>;
export type LinkFbPageUserMutationResult = Apollo.MutationResult<Operations.LinkFbPageUserMutation>;
export type LinkFbPageUserMutationOptions = Apollo.BaseMutationOptions<
  Operations.LinkFbPageUserMutation,
  Operations.LinkFbPageUserMutationVariables
>;
export const MarkShopOrderAsPickedDocument = gql`
  mutation MarkShopOrderAsPicked($input: MarkShopOrderAsPickedInput!) {
    markShopOrderAsPicked(input: $input) {
      shopOrder {
        ...ShopOrder
      }
    }
  }
  ${ShopOrderFragmentDoc}
`;
export type MarkShopOrderAsPickedMutationFn = Apollo.MutationFunction<
  Operations.MarkShopOrderAsPickedMutation,
  Operations.MarkShopOrderAsPickedMutationVariables
>;

/**
 * __useMarkShopOrderAsPickedMutation__
 *
 * To run a mutation, you first call `useMarkShopOrderAsPickedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkShopOrderAsPickedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markShopOrderAsPickedMutation, { data, loading, error }] = useMarkShopOrderAsPickedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkShopOrderAsPickedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.MarkShopOrderAsPickedMutation,
    Operations.MarkShopOrderAsPickedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.MarkShopOrderAsPickedMutation,
    Operations.MarkShopOrderAsPickedMutationVariables
  >(MarkShopOrderAsPickedDocument, options);
}
export type MarkShopOrderAsPickedMutationHookResult = ReturnType<typeof useMarkShopOrderAsPickedMutation>;
export type MarkShopOrderAsPickedMutationResult = Apollo.MutationResult<Operations.MarkShopOrderAsPickedMutation>;
export type MarkShopOrderAsPickedMutationOptions = Apollo.BaseMutationOptions<
  Operations.MarkShopOrderAsPickedMutation,
  Operations.MarkShopOrderAsPickedMutationVariables
>;
export const MarkShopOrderAsFulfilledDocument = gql`
  mutation MarkShopOrderAsFulfilled($input: MarkShopOrderAsFulfilledInput!) {
    markShopOrderAsFulfilled(input: $input) {
      shopOrder {
        ...ShopOrder
      }
    }
  }
  ${ShopOrderFragmentDoc}
`;
export type MarkShopOrderAsFulfilledMutationFn = Apollo.MutationFunction<
  Operations.MarkShopOrderAsFulfilledMutation,
  Operations.MarkShopOrderAsFulfilledMutationVariables
>;

/**
 * __useMarkShopOrderAsFulfilledMutation__
 *
 * To run a mutation, you first call `useMarkShopOrderAsFulfilledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkShopOrderAsFulfilledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markShopOrderAsFulfilledMutation, { data, loading, error }] = useMarkShopOrderAsFulfilledMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkShopOrderAsFulfilledMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.MarkShopOrderAsFulfilledMutation,
    Operations.MarkShopOrderAsFulfilledMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.MarkShopOrderAsFulfilledMutation,
    Operations.MarkShopOrderAsFulfilledMutationVariables
  >(MarkShopOrderAsFulfilledDocument, options);
}
export type MarkShopOrderAsFulfilledMutationHookResult = ReturnType<typeof useMarkShopOrderAsFulfilledMutation>;
export type MarkShopOrderAsFulfilledMutationResult = Apollo.MutationResult<Operations.MarkShopOrderAsFulfilledMutation>;
export type MarkShopOrderAsFulfilledMutationOptions = Apollo.BaseMutationOptions<
  Operations.MarkShopOrderAsFulfilledMutation,
  Operations.MarkShopOrderAsFulfilledMutationVariables
>;
export const PackItemsDocument = gql`
  mutation PackItems($input: PackItemsInput!) {
    packItems(input: $input) {
      orderPackage {
        id
        status
      }
    }
  }
`;
export type PackItemsMutationFn = Apollo.MutationFunction<
  Operations.PackItemsMutation,
  Operations.PackItemsMutationVariables
>;

/**
 * __usePackItemsMutation__
 *
 * To run a mutation, you first call `usePackItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePackItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [packItemsMutation, { data, loading, error }] = usePackItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePackItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.PackItemsMutation, Operations.PackItemsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.PackItemsMutation, Operations.PackItemsMutationVariables>(
    PackItemsDocument,
    options
  );
}
export type PackItemsMutationHookResult = ReturnType<typeof usePackItemsMutation>;
export type PackItemsMutationResult = Apollo.MutationResult<Operations.PackItemsMutation>;
export type PackItemsMutationOptions = Apollo.BaseMutationOptions<
  Operations.PackItemsMutation,
  Operations.PackItemsMutationVariables
>;
export const PrintPackagePackingSlipDocument = gql`
  mutation PrintPackagePackingSlip($input: PrintPackagePackingSlipInput!) {
    printPackagePackingSlip(input: $input) {
      orderPackage {
        id
        packingSlipUrl
        packingSlipUrlExpiry
        shippoOrderId
      }
    }
  }
`;
export type PrintPackagePackingSlipMutationFn = Apollo.MutationFunction<
  Operations.PrintPackagePackingSlipMutation,
  Operations.PrintPackagePackingSlipMutationVariables
>;

/**
 * __usePrintPackagePackingSlipMutation__
 *
 * To run a mutation, you first call `usePrintPackagePackingSlipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintPackagePackingSlipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printPackagePackingSlipMutation, { data, loading, error }] = usePrintPackagePackingSlipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrintPackagePackingSlipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.PrintPackagePackingSlipMutation,
    Operations.PrintPackagePackingSlipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.PrintPackagePackingSlipMutation,
    Operations.PrintPackagePackingSlipMutationVariables
  >(PrintPackagePackingSlipDocument, options);
}
export type PrintPackagePackingSlipMutationHookResult = ReturnType<typeof usePrintPackagePackingSlipMutation>;
export type PrintPackagePackingSlipMutationResult = Apollo.MutationResult<Operations.PrintPackagePackingSlipMutation>;
export type PrintPackagePackingSlipMutationOptions = Apollo.BaseMutationOptions<
  Operations.PrintPackagePackingSlipMutation,
  Operations.PrintPackagePackingSlipMutationVariables
>;
export const PrintPackingSlipDocument = gql`
  mutation PrintPackingSlip($input: PrintPackingSlipInput!) {
    printPackingSlip(input: $input) {
      shopOrder {
        status
        shopId
        orderLineItems {
          nodes {
            id
            quantity
            status
          }
        }
        orderPackages {
          nodes {
            id
            status
            orderFulfilmentLineItems {
              id
              status
              quantity
            }
            shopOrderId
            estimatedDeliveryDate
            orderId
            shippingProvider
            currency
            shipmentTrackingId
            shippingLabelUrl
            deliveryDays
            packingSlipUrl
            shippingUserPackage {
              id
              shippingProviderObjectId
              dimensions {
                length
                width
                height
                unit
              }
              name
            }
            shippingCarrierPackage {
              id
              dimensions {
                length
                width
                height
                unit
              }
              name
            }
            primaryWeight {
              value
              unit
            }
            secondaryWeight {
              value
              unit
            }
            totalWeight {
              value
              unit
            }
          }
        }
        orderFulfilmentlineItems {
          nodes {
            id
            quantity
            status
            orderShipmentLineItemId
            orderLineItemId
            orderPackageId
          }
        }
        packingSlipUrl
        packingSlipUrlExpiry
        shippoOrderId
      }
    }
  }
`;
export type PrintPackingSlipMutationFn = Apollo.MutationFunction<
  Operations.PrintPackingSlipMutation,
  Operations.PrintPackingSlipMutationVariables
>;

/**
 * __usePrintPackingSlipMutation__
 *
 * To run a mutation, you first call `usePrintPackingSlipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintPackingSlipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printPackingSlipMutation, { data, loading, error }] = usePrintPackingSlipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrintPackingSlipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.PrintPackingSlipMutation,
    Operations.PrintPackingSlipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.PrintPackingSlipMutation, Operations.PrintPackingSlipMutationVariables>(
    PrintPackingSlipDocument,
    options
  );
}
export type PrintPackingSlipMutationHookResult = ReturnType<typeof usePrintPackingSlipMutation>;
export type PrintPackingSlipMutationResult = Apollo.MutationResult<Operations.PrintPackingSlipMutation>;
export type PrintPackingSlipMutationOptions = Apollo.BaseMutationOptions<
  Operations.PrintPackingSlipMutation,
  Operations.PrintPackingSlipMutationVariables
>;
export const ProcessCardReaderPaymentIntentDocument = gql`
  mutation ProcessCardReaderPaymentIntent($input: ProcessCardReaderPaymentIntentInput!) {
    processCardReaderPaymentIntent(input: $input) {
      order {
        ...Order
      }
    }
  }
  ${OrderFragmentDoc}
`;
export type ProcessCardReaderPaymentIntentMutationFn = Apollo.MutationFunction<
  Operations.ProcessCardReaderPaymentIntentMutation,
  Operations.ProcessCardReaderPaymentIntentMutationVariables
>;

/**
 * __useProcessCardReaderPaymentIntentMutation__
 *
 * To run a mutation, you first call `useProcessCardReaderPaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessCardReaderPaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processCardReaderPaymentIntentMutation, { data, loading, error }] = useProcessCardReaderPaymentIntentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessCardReaderPaymentIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.ProcessCardReaderPaymentIntentMutation,
    Operations.ProcessCardReaderPaymentIntentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.ProcessCardReaderPaymentIntentMutation,
    Operations.ProcessCardReaderPaymentIntentMutationVariables
  >(ProcessCardReaderPaymentIntentDocument, options);
}
export type ProcessCardReaderPaymentIntentMutationHookResult = ReturnType<
  typeof useProcessCardReaderPaymentIntentMutation
>;
export type ProcessCardReaderPaymentIntentMutationResult =
  Apollo.MutationResult<Operations.ProcessCardReaderPaymentIntentMutation>;
export type ProcessCardReaderPaymentIntentMutationOptions = Apollo.BaseMutationOptions<
  Operations.ProcessCardReaderPaymentIntentMutation,
  Operations.ProcessCardReaderPaymentIntentMutationVariables
>;
export const PublishProductDocument = gql`
  mutation PublishProduct($input: PublishProductInput!) {
    publishProduct(input: $input) {
      product {
        ...ProductDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${ProductDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type PublishProductMutationFn = Apollo.MutationFunction<
  Operations.PublishProductMutation,
  Operations.PublishProductMutationVariables
>;

/**
 * __usePublishProductMutation__
 *
 * To run a mutation, you first call `usePublishProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishProductMutation, { data, loading, error }] = usePublishProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.PublishProductMutation,
    Operations.PublishProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.PublishProductMutation, Operations.PublishProductMutationVariables>(
    PublishProductDocument,
    options
  );
}
export type PublishProductMutationHookResult = ReturnType<typeof usePublishProductMutation>;
export type PublishProductMutationResult = Apollo.MutationResult<Operations.PublishProductMutation>;
export type PublishProductMutationOptions = Apollo.BaseMutationOptions<
  Operations.PublishProductMutation,
  Operations.PublishProductMutationVariables
>;
export const PurchaseShippingLabelDocument = gql`
  mutation PurchaseShippingLabel($input: PurchaseShippingLabelInput!) {
    purchaseShippingLabel(input: $input) {
      shopOrder {
        status
        shopId
        orderLineItems {
          nodes {
            id
          }
        }
        orderPackages {
          nodes {
            id
            status
            orderFulfilmentLineItems {
              id
              status
              quantity
            }
            shopOrderId
            estimatedDeliveryDate
            orderId
            shippingProvider
            currency
            shippingLabelUrl
            deliveryDays
            packingSlipUrl
            shippingUserPackage {
              id
              shippingProviderObjectId
              dimensions {
                length
                width
                height
                unit
              }
              name
            }
            shippingCarrierPackage {
              id
              dimensions {
                length
                width
                height
                unit
              }
              name
            }
            primaryWeight {
              value
              unit
            }
            secondaryWeight {
              value
              unit
            }
            totalWeight {
              value
              unit
            }
          }
        }
        orderFulfilmentlineItems {
          nodes {
            id
            quantity
            status
            orderShipmentLineItemId
            orderLineItemId
            orderPackageId
          }
        }
      }
    }
  }
`;
export type PurchaseShippingLabelMutationFn = Apollo.MutationFunction<
  Operations.PurchaseShippingLabelMutation,
  Operations.PurchaseShippingLabelMutationVariables
>;

/**
 * __usePurchaseShippingLabelMutation__
 *
 * To run a mutation, you first call `usePurchaseShippingLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseShippingLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseShippingLabelMutation, { data, loading, error }] = usePurchaseShippingLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseShippingLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.PurchaseShippingLabelMutation,
    Operations.PurchaseShippingLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.PurchaseShippingLabelMutation,
    Operations.PurchaseShippingLabelMutationVariables
  >(PurchaseShippingLabelDocument, options);
}
export type PurchaseShippingLabelMutationHookResult = ReturnType<typeof usePurchaseShippingLabelMutation>;
export type PurchaseShippingLabelMutationResult = Apollo.MutationResult<Operations.PurchaseShippingLabelMutation>;
export type PurchaseShippingLabelMutationOptions = Apollo.BaseMutationOptions<
  Operations.PurchaseShippingLabelMutation,
  Operations.PurchaseShippingLabelMutationVariables
>;
export const ReconnectFbBusinessAccountDocument = gql`
  mutation ReconnectFbBusinessAccount($input: ReconnectFbBusinessAccountInput!) {
    reconnectFbBusinessAccount(input: $input) {
      fbBusinessAccount {
        ...FbBusinessAccount
      }
      errors {
        ... on Error {
          message
        }
      }
    }
  }
  ${FbBusinessAccountFragmentDoc}
`;
export type ReconnectFbBusinessAccountMutationFn = Apollo.MutationFunction<
  Operations.ReconnectFbBusinessAccountMutation,
  Operations.ReconnectFbBusinessAccountMutationVariables
>;

/**
 * __useReconnectFbBusinessAccountMutation__
 *
 * To run a mutation, you first call `useReconnectFbBusinessAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReconnectFbBusinessAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reconnectFbBusinessAccountMutation, { data, loading, error }] = useReconnectFbBusinessAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReconnectFbBusinessAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.ReconnectFbBusinessAccountMutation,
    Operations.ReconnectFbBusinessAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.ReconnectFbBusinessAccountMutation,
    Operations.ReconnectFbBusinessAccountMutationVariables
  >(ReconnectFbBusinessAccountDocument, options);
}
export type ReconnectFbBusinessAccountMutationHookResult = ReturnType<typeof useReconnectFbBusinessAccountMutation>;
export type ReconnectFbBusinessAccountMutationResult =
  Apollo.MutationResult<Operations.ReconnectFbBusinessAccountMutation>;
export type ReconnectFbBusinessAccountMutationOptions = Apollo.BaseMutationOptions<
  Operations.ReconnectFbBusinessAccountMutation,
  Operations.ReconnectFbBusinessAccountMutationVariables
>;
export const RemoveItemFromCartDocument = gql`
  mutation RemoveItemFromCart($input: RemoveItemFromCartInput!) {
    removeItemFromCart(input: $input) {
      cartItem {
        ...CartItem
      }
    }
  }
  ${CartItemFragmentDoc}
`;
export type RemoveItemFromCartMutationFn = Apollo.MutationFunction<
  Operations.RemoveItemFromCartMutation,
  Operations.RemoveItemFromCartMutationVariables
>;

/**
 * __useRemoveItemFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveItemFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemFromCartMutation, { data, loading, error }] = useRemoveItemFromCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveItemFromCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.RemoveItemFromCartMutation,
    Operations.RemoveItemFromCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.RemoveItemFromCartMutation, Operations.RemoveItemFromCartMutationVariables>(
    RemoveItemFromCartDocument,
    options
  );
}
export type RemoveItemFromCartMutationHookResult = ReturnType<typeof useRemoveItemFromCartMutation>;
export type RemoveItemFromCartMutationResult = Apollo.MutationResult<Operations.RemoveItemFromCartMutation>;
export type RemoveItemFromCartMutationOptions = Apollo.BaseMutationOptions<
  Operations.RemoveItemFromCartMutation,
  Operations.RemoveItemFromCartMutationVariables
>;
export const RemoveItemFromCartPosDocument = gql`
  mutation RemoveItemFromCartPos($input: RemoveItemFromCartPosInput!) {
    removeItemFromCartPos(input: $input) {
      cartItem {
        ...CartItem
      }
    }
  }
  ${CartItemFragmentDoc}
`;
export type RemoveItemFromCartPosMutationFn = Apollo.MutationFunction<
  Operations.RemoveItemFromCartPosMutation,
  Operations.RemoveItemFromCartPosMutationVariables
>;

/**
 * __useRemoveItemFromCartPosMutation__
 *
 * To run a mutation, you first call `useRemoveItemFromCartPosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemFromCartPosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemFromCartPosMutation, { data, loading, error }] = useRemoveItemFromCartPosMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveItemFromCartPosMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.RemoveItemFromCartPosMutation,
    Operations.RemoveItemFromCartPosMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.RemoveItemFromCartPosMutation,
    Operations.RemoveItemFromCartPosMutationVariables
  >(RemoveItemFromCartPosDocument, options);
}
export type RemoveItemFromCartPosMutationHookResult = ReturnType<typeof useRemoveItemFromCartPosMutation>;
export type RemoveItemFromCartPosMutationResult = Apollo.MutationResult<Operations.RemoveItemFromCartPosMutation>;
export type RemoveItemFromCartPosMutationOptions = Apollo.BaseMutationOptions<
  Operations.RemoveItemFromCartPosMutation,
  Operations.RemoveItemFromCartPosMutationVariables
>;
export const RemoveOptionValueDocument = gql`
  mutation RemoveOptionValue($input: RemoveOptionValueInput!) {
    removeOptionValue(input: $input) {
      optionValue {
        ...OptionValue
      }
    }
  }
  ${OptionValueFragmentDoc}
`;
export type RemoveOptionValueMutationFn = Apollo.MutationFunction<
  Operations.RemoveOptionValueMutation,
  Operations.RemoveOptionValueMutationVariables
>;

/**
 * __useRemoveOptionValueMutation__
 *
 * To run a mutation, you first call `useRemoveOptionValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOptionValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOptionValueMutation, { data, loading, error }] = useRemoveOptionValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOptionValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.RemoveOptionValueMutation,
    Operations.RemoveOptionValueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.RemoveOptionValueMutation, Operations.RemoveOptionValueMutationVariables>(
    RemoveOptionValueDocument,
    options
  );
}
export type RemoveOptionValueMutationHookResult = ReturnType<typeof useRemoveOptionValueMutation>;
export type RemoveOptionValueMutationResult = Apollo.MutationResult<Operations.RemoveOptionValueMutation>;
export type RemoveOptionValueMutationOptions = Apollo.BaseMutationOptions<
  Operations.RemoveOptionValueMutation,
  Operations.RemoveOptionValueMutationVariables
>;
export const RemoveVariantOptionValueDocument = gql`
  mutation RemoveVariantOptionValue($input: RemoveVariantOptionValueInput!) {
    removeVariantOptionValue(input: $input) {
      variant {
        ...Variant
      }
    }
  }
  ${VariantFragmentDoc}
`;
export type RemoveVariantOptionValueMutationFn = Apollo.MutationFunction<
  Operations.RemoveVariantOptionValueMutation,
  Operations.RemoveVariantOptionValueMutationVariables
>;

/**
 * __useRemoveVariantOptionValueMutation__
 *
 * To run a mutation, you first call `useRemoveVariantOptionValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVariantOptionValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVariantOptionValueMutation, { data, loading, error }] = useRemoveVariantOptionValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveVariantOptionValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.RemoveVariantOptionValueMutation,
    Operations.RemoveVariantOptionValueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.RemoveVariantOptionValueMutation,
    Operations.RemoveVariantOptionValueMutationVariables
  >(RemoveVariantOptionValueDocument, options);
}
export type RemoveVariantOptionValueMutationHookResult = ReturnType<typeof useRemoveVariantOptionValueMutation>;
export type RemoveVariantOptionValueMutationResult = Apollo.MutationResult<Operations.RemoveVariantOptionValueMutation>;
export type RemoveVariantOptionValueMutationOptions = Apollo.BaseMutationOptions<
  Operations.RemoveVariantOptionValueMutation,
  Operations.RemoveVariantOptionValueMutationVariables
>;
export const RequestEmailVerificationDocument = gql`
  mutation RequestEmailVerification($input: RequestEmailVerificationInput!) {
    requestEmailVerification(input: $input) {
      emailVerificationResponse {
        message
        success
      }
    }
  }
`;
export type RequestEmailVerificationMutationFn = Apollo.MutationFunction<
  Operations.RequestEmailVerificationMutation,
  Operations.RequestEmailVerificationMutationVariables
>;

/**
 * __useRequestEmailVerificationMutation__
 *
 * To run a mutation, you first call `useRequestEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestEmailVerificationMutation, { data, loading, error }] = useRequestEmailVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestEmailVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.RequestEmailVerificationMutation,
    Operations.RequestEmailVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.RequestEmailVerificationMutation,
    Operations.RequestEmailVerificationMutationVariables
  >(RequestEmailVerificationDocument, options);
}
export type RequestEmailVerificationMutationHookResult = ReturnType<typeof useRequestEmailVerificationMutation>;
export type RequestEmailVerificationMutationResult = Apollo.MutationResult<Operations.RequestEmailVerificationMutation>;
export type RequestEmailVerificationMutationOptions = Apollo.BaseMutationOptions<
  Operations.RequestEmailVerificationMutation,
  Operations.RequestEmailVerificationMutationVariables
>;
export const SelectCheckoutShipmentShippingRateDocument = gql`
  mutation SelectCheckoutShipmentShippingRate($input: SelectCheckoutShipmentShippingRateInput!) {
    selectCheckoutShipmentShippingRate(input: $input) {
      checkout {
        ...Checkout
      }
      errors {
        ...Error
      }
    }
  }
  ${CheckoutFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type SelectCheckoutShipmentShippingRateMutationFn = Apollo.MutationFunction<
  Operations.SelectCheckoutShipmentShippingRateMutation,
  Operations.SelectCheckoutShipmentShippingRateMutationVariables
>;

/**
 * __useSelectCheckoutShipmentShippingRateMutation__
 *
 * To run a mutation, you first call `useSelectCheckoutShipmentShippingRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectCheckoutShipmentShippingRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectCheckoutShipmentShippingRateMutation, { data, loading, error }] = useSelectCheckoutShipmentShippingRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelectCheckoutShipmentShippingRateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.SelectCheckoutShipmentShippingRateMutation,
    Operations.SelectCheckoutShipmentShippingRateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.SelectCheckoutShipmentShippingRateMutation,
    Operations.SelectCheckoutShipmentShippingRateMutationVariables
  >(SelectCheckoutShipmentShippingRateDocument, options);
}
export type SelectCheckoutShipmentShippingRateMutationHookResult = ReturnType<
  typeof useSelectCheckoutShipmentShippingRateMutation
>;
export type SelectCheckoutShipmentShippingRateMutationResult =
  Apollo.MutationResult<Operations.SelectCheckoutShipmentShippingRateMutation>;
export type SelectCheckoutShipmentShippingRateMutationOptions = Apollo.BaseMutationOptions<
  Operations.SelectCheckoutShipmentShippingRateMutation,
  Operations.SelectCheckoutShipmentShippingRateMutationVariables
>;
export const SendConfirmationEmailToCustomerPosDocument = gql`
  mutation SendConfirmationEmailToCustomerPOS($input: SendConfirmationEmailToCustomerPOSInput!) {
    sendConfirmationEmailToCustomerPOS(input: $input) {
      order {
        ...Order
      }
      errors {
        ... on CustomerEmailRequiredError {
          message
        }
      }
    }
  }
  ${OrderFragmentDoc}
`;
export type SendConfirmationEmailToCustomerPosMutationFn = Apollo.MutationFunction<
  Operations.SendConfirmationEmailToCustomerPosMutation,
  Operations.SendConfirmationEmailToCustomerPosMutationVariables
>;

/**
 * __useSendConfirmationEmailToCustomerPosMutation__
 *
 * To run a mutation, you first call `useSendConfirmationEmailToCustomerPosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendConfirmationEmailToCustomerPosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendConfirmationEmailToCustomerPosMutation, { data, loading, error }] = useSendConfirmationEmailToCustomerPosMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendConfirmationEmailToCustomerPosMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.SendConfirmationEmailToCustomerPosMutation,
    Operations.SendConfirmationEmailToCustomerPosMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.SendConfirmationEmailToCustomerPosMutation,
    Operations.SendConfirmationEmailToCustomerPosMutationVariables
  >(SendConfirmationEmailToCustomerPosDocument, options);
}
export type SendConfirmationEmailToCustomerPosMutationHookResult = ReturnType<
  typeof useSendConfirmationEmailToCustomerPosMutation
>;
export type SendConfirmationEmailToCustomerPosMutationResult =
  Apollo.MutationResult<Operations.SendConfirmationEmailToCustomerPosMutation>;
export type SendConfirmationEmailToCustomerPosMutationOptions = Apollo.BaseMutationOptions<
  Operations.SendConfirmationEmailToCustomerPosMutation,
  Operations.SendConfirmationEmailToCustomerPosMutationVariables
>;
export const SendVerificationCodeDocument = gql`
  mutation SendVerificationCode($input: SendVerificationCodeInput!) {
    sendVerificationCode(input: $input) {
      phoneNumberVerificationResponse {
        message
        success
        __typename
      }
    }
  }
`;
export type SendVerificationCodeMutationFn = Apollo.MutationFunction<
  Operations.SendVerificationCodeMutation,
  Operations.SendVerificationCodeMutationVariables
>;

/**
 * __useSendVerificationCodeMutation__
 *
 * To run a mutation, you first call `useSendVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerificationCodeMutation, { data, loading, error }] = useSendVerificationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.SendVerificationCodeMutation,
    Operations.SendVerificationCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.SendVerificationCodeMutation, Operations.SendVerificationCodeMutationVariables>(
    SendVerificationCodeDocument,
    options
  );
}
export type SendVerificationCodeMutationHookResult = ReturnType<typeof useSendVerificationCodeMutation>;
export type SendVerificationCodeMutationResult = Apollo.MutationResult<Operations.SendVerificationCodeMutation>;
export type SendVerificationCodeMutationOptions = Apollo.BaseMutationOptions<
  Operations.SendVerificationCodeMutation,
  Operations.SendVerificationCodeMutationVariables
>;
export const SetupShopDocument = gql`
  mutation SetupShop($input: SetupShopInput!) {
    setupShop(input: $input) {
      shop {
        ...Shop
      }
    }
  }
  ${ShopFragmentDoc}
`;
export type SetupShopMutationFn = Apollo.MutationFunction<
  Operations.SetupShopMutation,
  Operations.SetupShopMutationVariables
>;

/**
 * __useSetupShopMutation__
 *
 * To run a mutation, you first call `useSetupShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupShopMutation, { data, loading, error }] = useSetupShopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetupShopMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.SetupShopMutation, Operations.SetupShopMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.SetupShopMutation, Operations.SetupShopMutationVariables>(
    SetupShopDocument,
    options
  );
}
export type SetupShopMutationHookResult = ReturnType<typeof useSetupShopMutation>;
export type SetupShopMutationResult = Apollo.MutationResult<Operations.SetupShopMutation>;
export type SetupShopMutationOptions = Apollo.BaseMutationOptions<
  Operations.SetupShopMutation,
  Operations.SetupShopMutationVariables
>;
export const ShipmentRatesDocument = gql`
  mutation ShipmentRates($input: ShipmentRatesInput!) {
    shipmentRates(input: $input) {
      rate {
        amount
        providerImage75
        providerImage200
        estimatedDays
        currency
        objectId
        servicelevel {
          name
          extendedToken
        }
      }
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ShipmentRatesMutationFn = Apollo.MutationFunction<
  Operations.ShipmentRatesMutation,
  Operations.ShipmentRatesMutationVariables
>;

/**
 * __useShipmentRatesMutation__
 *
 * To run a mutation, you first call `useShipmentRatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShipmentRatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shipmentRatesMutation, { data, loading, error }] = useShipmentRatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShipmentRatesMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.ShipmentRatesMutation, Operations.ShipmentRatesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.ShipmentRatesMutation, Operations.ShipmentRatesMutationVariables>(
    ShipmentRatesDocument,
    options
  );
}
export type ShipmentRatesMutationHookResult = ReturnType<typeof useShipmentRatesMutation>;
export type ShipmentRatesMutationResult = Apollo.MutationResult<Operations.ShipmentRatesMutation>;
export type ShipmentRatesMutationOptions = Apollo.BaseMutationOptions<
  Operations.ShipmentRatesMutation,
  Operations.ShipmentRatesMutationVariables
>;
export const UnfulfillOrderShipmentLineItemDocument = gql`
  mutation UnfulfillOrderShipmentLineItem($input: UnfulfillOrderShipmentLineItemInput!) {
    unfulfillOrderShipmentLineItem(input: $input) {
      orderShipmentLineItem {
        ...OrderShipmentLineItem
        orderShipment {
          shopOrder {
            ...ShopOrder
          }
        }
      }
      errors {
        ...Error
      }
    }
  }
  ${OrderShipmentLineItemFragmentDoc}
  ${ShopOrderFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UnfulfillOrderShipmentLineItemMutationFn = Apollo.MutationFunction<
  Operations.UnfulfillOrderShipmentLineItemMutation,
  Operations.UnfulfillOrderShipmentLineItemMutationVariables
>;

/**
 * __useUnfulfillOrderShipmentLineItemMutation__
 *
 * To run a mutation, you first call `useUnfulfillOrderShipmentLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfulfillOrderShipmentLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfulfillOrderShipmentLineItemMutation, { data, loading, error }] = useUnfulfillOrderShipmentLineItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnfulfillOrderShipmentLineItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UnfulfillOrderShipmentLineItemMutation,
    Operations.UnfulfillOrderShipmentLineItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UnfulfillOrderShipmentLineItemMutation,
    Operations.UnfulfillOrderShipmentLineItemMutationVariables
  >(UnfulfillOrderShipmentLineItemDocument, options);
}
export type UnfulfillOrderShipmentLineItemMutationHookResult = ReturnType<
  typeof useUnfulfillOrderShipmentLineItemMutation
>;
export type UnfulfillOrderShipmentLineItemMutationResult =
  Apollo.MutationResult<Operations.UnfulfillOrderShipmentLineItemMutation>;
export type UnfulfillOrderShipmentLineItemMutationOptions = Apollo.BaseMutationOptions<
  Operations.UnfulfillOrderShipmentLineItemMutation,
  Operations.UnfulfillOrderShipmentLineItemMutationVariables
>;
export const UnpublishProductDocument = gql`
  mutation UnpublishProduct($input: UnpublishProductInput!) {
    unpublishProduct(input: $input) {
      product {
        ...ProductDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${ProductDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UnpublishProductMutationFn = Apollo.MutationFunction<
  Operations.UnpublishProductMutation,
  Operations.UnpublishProductMutationVariables
>;

/**
 * __useUnpublishProductMutation__
 *
 * To run a mutation, you first call `useUnpublishProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishProductMutation, { data, loading, error }] = useUnpublishProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UnpublishProductMutation,
    Operations.UnpublishProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UnpublishProductMutation, Operations.UnpublishProductMutationVariables>(
    UnpublishProductDocument,
    options
  );
}
export type UnpublishProductMutationHookResult = ReturnType<typeof useUnpublishProductMutation>;
export type UnpublishProductMutationResult = Apollo.MutationResult<Operations.UnpublishProductMutation>;
export type UnpublishProductMutationOptions = Apollo.BaseMutationOptions<
  Operations.UnpublishProductMutation,
  Operations.UnpublishProductMutationVariables
>;
export const UpdateCartItemDocument = gql`
  mutation UpdateCartItem($input: UpdateCartItemInput!) {
    updateCartItem(input: $input) {
      cartItem {
        ...CartItem
      }
      errors {
        ...Error
      }
    }
  }
  ${CartItemFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UpdateCartItemMutationFn = Apollo.MutationFunction<
  Operations.UpdateCartItemMutation,
  Operations.UpdateCartItemMutationVariables
>;

/**
 * __useUpdateCartItemMutation__
 *
 * To run a mutation, you first call `useUpdateCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartItemMutation, { data, loading, error }] = useUpdateCartItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateCartItemMutation,
    Operations.UpdateCartItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UpdateCartItemMutation, Operations.UpdateCartItemMutationVariables>(
    UpdateCartItemDocument,
    options
  );
}
export type UpdateCartItemMutationHookResult = ReturnType<typeof useUpdateCartItemMutation>;
export type UpdateCartItemMutationResult = Apollo.MutationResult<Operations.UpdateCartItemMutation>;
export type UpdateCartItemMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateCartItemMutation,
  Operations.UpdateCartItemMutationVariables
>;
export const UpdateCartItemCustomizationDocument = gql`
  mutation UpdateCartItemCustomization($input: UpdateCartItemCustomizationInput!) {
    updateCartItemCustomization(input: $input) {
      cartItemCustomization {
        ...CartItemCustomization
      }
    }
  }
  ${CartItemCustomizationFragmentDoc}
`;
export type UpdateCartItemCustomizationMutationFn = Apollo.MutationFunction<
  Operations.UpdateCartItemCustomizationMutation,
  Operations.UpdateCartItemCustomizationMutationVariables
>;

/**
 * __useUpdateCartItemCustomizationMutation__
 *
 * To run a mutation, you first call `useUpdateCartItemCustomizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartItemCustomizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartItemCustomizationMutation, { data, loading, error }] = useUpdateCartItemCustomizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartItemCustomizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateCartItemCustomizationMutation,
    Operations.UpdateCartItemCustomizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateCartItemCustomizationMutation,
    Operations.UpdateCartItemCustomizationMutationVariables
  >(UpdateCartItemCustomizationDocument, options);
}
export type UpdateCartItemCustomizationMutationHookResult = ReturnType<typeof useUpdateCartItemCustomizationMutation>;
export type UpdateCartItemCustomizationMutationResult =
  Apollo.MutationResult<Operations.UpdateCartItemCustomizationMutation>;
export type UpdateCartItemCustomizationMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateCartItemCustomizationMutation,
  Operations.UpdateCartItemCustomizationMutationVariables
>;
export const UpdateCartItemPosDocument = gql`
  mutation UpdateCartItemPos($input: UpdateCartItemPosInput!) {
    updateCartItemPos(input: $input) {
      cartItem {
        ...CartItem
      }
      errors {
        ...Error
      }
    }
  }
  ${CartItemFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UpdateCartItemPosMutationFn = Apollo.MutationFunction<
  Operations.UpdateCartItemPosMutation,
  Operations.UpdateCartItemPosMutationVariables
>;

/**
 * __useUpdateCartItemPosMutation__
 *
 * To run a mutation, you first call `useUpdateCartItemPosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartItemPosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartItemPosMutation, { data, loading, error }] = useUpdateCartItemPosMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartItemPosMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateCartItemPosMutation,
    Operations.UpdateCartItemPosMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UpdateCartItemPosMutation, Operations.UpdateCartItemPosMutationVariables>(
    UpdateCartItemPosDocument,
    options
  );
}
export type UpdateCartItemPosMutationHookResult = ReturnType<typeof useUpdateCartItemPosMutation>;
export type UpdateCartItemPosMutationResult = Apollo.MutationResult<Operations.UpdateCartItemPosMutation>;
export type UpdateCartItemPosMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateCartItemPosMutation,
  Operations.UpdateCartItemPosMutationVariables
>;
export const UpdateCheckoutDocument = gql`
  mutation UpdateCheckout($input: UpdateCheckoutInput!) {
    updateCheckout(input: $input) {
      checkout {
        ...Checkout
      }
      errors {
        ...Error
      }
    }
  }
  ${CheckoutFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UpdateCheckoutMutationFn = Apollo.MutationFunction<
  Operations.UpdateCheckoutMutation,
  Operations.UpdateCheckoutMutationVariables
>;

/**
 * __useUpdateCheckoutMutation__
 *
 * To run a mutation, you first call `useUpdateCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCheckoutMutation, { data, loading, error }] = useUpdateCheckoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCheckoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateCheckoutMutation,
    Operations.UpdateCheckoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UpdateCheckoutMutation, Operations.UpdateCheckoutMutationVariables>(
    UpdateCheckoutDocument,
    options
  );
}
export type UpdateCheckoutMutationHookResult = ReturnType<typeof useUpdateCheckoutMutation>;
export type UpdateCheckoutMutationResult = Apollo.MutationResult<Operations.UpdateCheckoutMutation>;
export type UpdateCheckoutMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateCheckoutMutation,
  Operations.UpdateCheckoutMutationVariables
>;
export const UpdateCheckoutPosDocument = gql`
  mutation UpdateCheckoutPos($input: UpdateCheckoutPosInput!) {
    updateCheckoutPos(input: $input) {
      checkout {
        ...Checkout
      }
      errors {
        ...Error
      }
    }
  }
  ${CheckoutFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UpdateCheckoutPosMutationFn = Apollo.MutationFunction<
  Operations.UpdateCheckoutPosMutation,
  Operations.UpdateCheckoutPosMutationVariables
>;

/**
 * __useUpdateCheckoutPosMutation__
 *
 * To run a mutation, you first call `useUpdateCheckoutPosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCheckoutPosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCheckoutPosMutation, { data, loading, error }] = useUpdateCheckoutPosMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCheckoutPosMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateCheckoutPosMutation,
    Operations.UpdateCheckoutPosMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UpdateCheckoutPosMutation, Operations.UpdateCheckoutPosMutationVariables>(
    UpdateCheckoutPosDocument,
    options
  );
}
export type UpdateCheckoutPosMutationHookResult = ReturnType<typeof useUpdateCheckoutPosMutation>;
export type UpdateCheckoutPosMutationResult = Apollo.MutationResult<Operations.UpdateCheckoutPosMutation>;
export type UpdateCheckoutPosMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateCheckoutPosMutation,
  Operations.UpdateCheckoutPosMutationVariables
>;
export const UpdateCustomerDocument = gql`
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      customer {
        ...Customer
      }
    }
  }
  ${CustomerFragmentDoc}
`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<
  Operations.UpdateCustomerMutation,
  Operations.UpdateCustomerMutationVariables
>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateCustomerMutation,
    Operations.UpdateCustomerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UpdateCustomerMutation, Operations.UpdateCustomerMutationVariables>(
    UpdateCustomerDocument,
    options
  );
}
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<Operations.UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateCustomerMutation,
  Operations.UpdateCustomerMutationVariables
>;
export const UpdateEmailAddressDocument = gql`
  mutation UpdateEmailAddress($input: UpdateEmailAddressInput!) {
    updateEmailAddress(input: $input) {
      updateEmailAddressResponse {
        success
        message
      }
    }
  }
`;
export type UpdateEmailAddressMutationFn = Apollo.MutationFunction<
  Operations.UpdateEmailAddressMutation,
  Operations.UpdateEmailAddressMutationVariables
>;

/**
 * __useUpdateEmailAddressMutation__
 *
 * To run a mutation, you first call `useUpdateEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailAddressMutation, { data, loading, error }] = useUpdateEmailAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateEmailAddressMutation,
    Operations.UpdateEmailAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UpdateEmailAddressMutation, Operations.UpdateEmailAddressMutationVariables>(
    UpdateEmailAddressDocument,
    options
  );
}
export type UpdateEmailAddressMutationHookResult = ReturnType<typeof useUpdateEmailAddressMutation>;
export type UpdateEmailAddressMutationResult = Apollo.MutationResult<Operations.UpdateEmailAddressMutation>;
export type UpdateEmailAddressMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateEmailAddressMutation,
  Operations.UpdateEmailAddressMutationVariables
>;
export const UpdateImageDocument = gql`
  mutation UpdateImage($input: UpdateImageInput!) {
    updateImage(input: $input) {
      image {
        ...Image
      }
    }
  }
  ${ImageFragmentDoc}
`;
export type UpdateImageMutationFn = Apollo.MutationFunction<
  Operations.UpdateImageMutation,
  Operations.UpdateImageMutationVariables
>;

/**
 * __useUpdateImageMutation__
 *
 * To run a mutation, you first call `useUpdateImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateImageMutation, { data, loading, error }] = useUpdateImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateImageMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.UpdateImageMutation, Operations.UpdateImageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UpdateImageMutation, Operations.UpdateImageMutationVariables>(
    UpdateImageDocument,
    options
  );
}
export type UpdateImageMutationHookResult = ReturnType<typeof useUpdateImageMutation>;
export type UpdateImageMutationResult = Apollo.MutationResult<Operations.UpdateImageMutation>;
export type UpdateImageMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateImageMutation,
  Operations.UpdateImageMutationVariables
>;
export const UpdateInventoryItemDocument = gql`
  mutation UpdateInventoryItem($input: UpdateInventoryItemInput!) {
    updateInventoryItem(input: $input) {
      inventoryItem {
        ...InventoryItem
      }
    }
  }
  ${InventoryItemFragmentDoc}
`;
export type UpdateInventoryItemMutationFn = Apollo.MutationFunction<
  Operations.UpdateInventoryItemMutation,
  Operations.UpdateInventoryItemMutationVariables
>;

/**
 * __useUpdateInventoryItemMutation__
 *
 * To run a mutation, you first call `useUpdateInventoryItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInventoryItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInventoryItemMutation, { data, loading, error }] = useUpdateInventoryItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInventoryItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateInventoryItemMutation,
    Operations.UpdateInventoryItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UpdateInventoryItemMutation, Operations.UpdateInventoryItemMutationVariables>(
    UpdateInventoryItemDocument,
    options
  );
}
export type UpdateInventoryItemMutationHookResult = ReturnType<typeof useUpdateInventoryItemMutation>;
export type UpdateInventoryItemMutationResult = Apollo.MutationResult<Operations.UpdateInventoryItemMutation>;
export type UpdateInventoryItemMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateInventoryItemMutation,
  Operations.UpdateInventoryItemMutationVariables
>;
export const UpdateLocationInventoryDocument = gql`
  mutation UpdateLocationInventory($input: UpdateLocationInventoryInput!) {
    updateLocationInventory(input: $input) {
      locationInventory {
        ...LocationInventory
        inventoryItem {
          id
          quantity {
            ...Quantity
          }
          product {
            id
            quantity {
              ...Quantity
            }
          }
          variant {
            id
            quantity {
              ...Quantity
            }
          }
        }
      }
      errors {
        ...Error
      }
    }
  }
  ${LocationInventoryFragmentDoc}
  ${QuantityFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UpdateLocationInventoryMutationFn = Apollo.MutationFunction<
  Operations.UpdateLocationInventoryMutation,
  Operations.UpdateLocationInventoryMutationVariables
>;

/**
 * __useUpdateLocationInventoryMutation__
 *
 * To run a mutation, you first call `useUpdateLocationInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationInventoryMutation, { data, loading, error }] = useUpdateLocationInventoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationInventoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateLocationInventoryMutation,
    Operations.UpdateLocationInventoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateLocationInventoryMutation,
    Operations.UpdateLocationInventoryMutationVariables
  >(UpdateLocationInventoryDocument, options);
}
export type UpdateLocationInventoryMutationHookResult = ReturnType<typeof useUpdateLocationInventoryMutation>;
export type UpdateLocationInventoryMutationResult = Apollo.MutationResult<Operations.UpdateLocationInventoryMutation>;
export type UpdateLocationInventoryMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateLocationInventoryMutation,
  Operations.UpdateLocationInventoryMutationVariables
>;
export const UpdateOptionDocument = gql`
  mutation UpdateOption($input: UpdateOptionInput!) {
    updateOption(input: $input) {
      option {
        ...Option
      }
    }
  }
  ${OptionFragmentDoc}
`;
export type UpdateOptionMutationFn = Apollo.MutationFunction<
  Operations.UpdateOptionMutation,
  Operations.UpdateOptionMutationVariables
>;

/**
 * __useUpdateOptionMutation__
 *
 * To run a mutation, you first call `useUpdateOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOptionMutation, { data, loading, error }] = useUpdateOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOptionMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.UpdateOptionMutation, Operations.UpdateOptionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UpdateOptionMutation, Operations.UpdateOptionMutationVariables>(
    UpdateOptionDocument,
    options
  );
}
export type UpdateOptionMutationHookResult = ReturnType<typeof useUpdateOptionMutation>;
export type UpdateOptionMutationResult = Apollo.MutationResult<Operations.UpdateOptionMutation>;
export type UpdateOptionMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateOptionMutation,
  Operations.UpdateOptionMutationVariables
>;
export const UpdateOrderPaymentMethodDocument = gql`
  mutation updateOrderPaymentMethod($input: UpdateOrderPaymentMethodInput!) {
    updateOrderPaymentMethod(input: $input) {
      order {
        id
        customerId
        email
        total {
          amount
          currency
        }
        shippingTotal {
          amount
          currency
        }
        salesTaxTotal {
          amount
          currency
        }
        payments {
          nodes {
            id
            customerPaymentMethod {
              id
            }
          }
        }
      }
    }
  }
`;
export type UpdateOrderPaymentMethodMutationFn = Apollo.MutationFunction<
  Operations.UpdateOrderPaymentMethodMutation,
  Operations.UpdateOrderPaymentMethodMutationVariables
>;

/**
 * __useUpdateOrderPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateOrderPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderPaymentMethodMutation, { data, loading, error }] = useUpdateOrderPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateOrderPaymentMethodMutation,
    Operations.UpdateOrderPaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateOrderPaymentMethodMutation,
    Operations.UpdateOrderPaymentMethodMutationVariables
  >(UpdateOrderPaymentMethodDocument, options);
}
export type UpdateOrderPaymentMethodMutationHookResult = ReturnType<typeof useUpdateOrderPaymentMethodMutation>;
export type UpdateOrderPaymentMethodMutationResult = Apollo.MutationResult<Operations.UpdateOrderPaymentMethodMutation>;
export type UpdateOrderPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateOrderPaymentMethodMutation,
  Operations.UpdateOrderPaymentMethodMutationVariables
>;
export const UpdatePackageDocument = gql`
  mutation UpdatePackage($input: UpdatePackageInput!) {
    updatePackage(input: $input) {
      orderPackage {
        id
      }
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type UpdatePackageMutationFn = Apollo.MutationFunction<
  Operations.UpdatePackageMutation,
  Operations.UpdatePackageMutationVariables
>;

/**
 * __useUpdatePackageMutation__
 *
 * To run a mutation, you first call `useUpdatePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePackageMutation, { data, loading, error }] = useUpdatePackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePackageMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.UpdatePackageMutation, Operations.UpdatePackageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UpdatePackageMutation, Operations.UpdatePackageMutationVariables>(
    UpdatePackageDocument,
    options
  );
}
export type UpdatePackageMutationHookResult = ReturnType<typeof useUpdatePackageMutation>;
export type UpdatePackageMutationResult = Apollo.MutationResult<Operations.UpdatePackageMutation>;
export type UpdatePackageMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdatePackageMutation,
  Operations.UpdatePackageMutationVariables
>;
export const UpdatePasswordDocument = gql`
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      updatePasswordResponse {
        success
        message
      }
    }
  }
`;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<
  Operations.UpdatePasswordMutation,
  Operations.UpdatePasswordMutationVariables
>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdatePasswordMutation,
    Operations.UpdatePasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UpdatePasswordMutation, Operations.UpdatePasswordMutationVariables>(
    UpdatePasswordDocument,
    options
  );
}
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<Operations.UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdatePasswordMutation,
  Operations.UpdatePasswordMutationVariables
>;
export const UpdatePaymentDocument = gql`
  mutation UpdatePayment($input: UpdatePaymentInput!) {
    updatePayment(input: $input) {
      payment {
        ...Payment
      }
      errors {
        ...Error
      }
    }
  }
  ${PaymentFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UpdatePaymentMutationFn = Apollo.MutationFunction<
  Operations.UpdatePaymentMutation,
  Operations.UpdatePaymentMutationVariables
>;

/**
 * __useUpdatePaymentMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMutation, { data, loading, error }] = useUpdatePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.UpdatePaymentMutation, Operations.UpdatePaymentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UpdatePaymentMutation, Operations.UpdatePaymentMutationVariables>(
    UpdatePaymentDocument,
    options
  );
}
export type UpdatePaymentMutationHookResult = ReturnType<typeof useUpdatePaymentMutation>;
export type UpdatePaymentMutationResult = Apollo.MutationResult<Operations.UpdatePaymentMutation>;
export type UpdatePaymentMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdatePaymentMutation,
  Operations.UpdatePaymentMutationVariables
>;
export const UpdatePhoneNumberDocument = gql`
  mutation UpdatePhoneNumber($input: UpdatePhoneInput!) {
    updatePhone(input: $input) {
      phoneNumberVerificationResponse {
        message
        success
      }
    }
  }
`;
export type UpdatePhoneNumberMutationFn = Apollo.MutationFunction<
  Operations.UpdatePhoneNumberMutation,
  Operations.UpdatePhoneNumberMutationVariables
>;

/**
 * __useUpdatePhoneNumberMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneNumberMutation, { data, loading, error }] = useUpdatePhoneNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePhoneNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdatePhoneNumberMutation,
    Operations.UpdatePhoneNumberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UpdatePhoneNumberMutation, Operations.UpdatePhoneNumberMutationVariables>(
    UpdatePhoneNumberDocument,
    options
  );
}
export type UpdatePhoneNumberMutationHookResult = ReturnType<typeof useUpdatePhoneNumberMutation>;
export type UpdatePhoneNumberMutationResult = Apollo.MutationResult<Operations.UpdatePhoneNumberMutation>;
export type UpdatePhoneNumberMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdatePhoneNumberMutation,
  Operations.UpdatePhoneNumberMutationVariables
>;
export const UpdateProductDocument = gql`
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      product {
        ...ProductDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${ProductDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UpdateProductMutationFn = Apollo.MutationFunction<
  Operations.UpdateProductMutation,
  Operations.UpdateProductMutationVariables
>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.UpdateProductMutation, Operations.UpdateProductMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UpdateProductMutation, Operations.UpdateProductMutationVariables>(
    UpdateProductDocument,
    options
  );
}
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<Operations.UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateProductMutation,
  Operations.UpdateProductMutationVariables
>;
export const UpdateShopDocument = gql`
  mutation UpdateShop($input: UpdateShopInput!) {
    updateShop(input: $input) {
      shop {
        ...Shop
      }
    }
  }
  ${ShopFragmentDoc}
`;
export type UpdateShopMutationFn = Apollo.MutationFunction<
  Operations.UpdateShopMutation,
  Operations.UpdateShopMutationVariables
>;

/**
 * __useUpdateShopMutation__
 *
 * To run a mutation, you first call `useUpdateShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopMutation, { data, loading, error }] = useUpdateShopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShopMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.UpdateShopMutation, Operations.UpdateShopMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UpdateShopMutation, Operations.UpdateShopMutationVariables>(
    UpdateShopDocument,
    options
  );
}
export type UpdateShopMutationHookResult = ReturnType<typeof useUpdateShopMutation>;
export type UpdateShopMutationResult = Apollo.MutationResult<Operations.UpdateShopMutation>;
export type UpdateShopMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateShopMutation,
  Operations.UpdateShopMutationVariables
>;
export const UpdateShopCategoriesDocument = gql`
  mutation UpdateShopCategories($input: UpdateShopCategoriesInput!) {
    updateShopCategories(input: $input) {
      shopShoppingCategory {
        shopId
        shoppingCategoryId
      }
    }
  }
`;
export type UpdateShopCategoriesMutationFn = Apollo.MutationFunction<
  Operations.UpdateShopCategoriesMutation,
  Operations.UpdateShopCategoriesMutationVariables
>;

/**
 * __useUpdateShopCategoriesMutation__
 *
 * To run a mutation, you first call `useUpdateShopCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopCategoriesMutation, { data, loading, error }] = useUpdateShopCategoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShopCategoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateShopCategoriesMutation,
    Operations.UpdateShopCategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UpdateShopCategoriesMutation, Operations.UpdateShopCategoriesMutationVariables>(
    UpdateShopCategoriesDocument,
    options
  );
}
export type UpdateShopCategoriesMutationHookResult = ReturnType<typeof useUpdateShopCategoriesMutation>;
export type UpdateShopCategoriesMutationResult = Apollo.MutationResult<Operations.UpdateShopCategoriesMutation>;
export type UpdateShopCategoriesMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateShopCategoriesMutation,
  Operations.UpdateShopCategoriesMutationVariables
>;
export const UpdateShopSubcategoriesDocument = gql`
  mutation UpdateShopSubcategories($input: UpdateShopSubcategoriesInput!) {
    updateShopSubcategories(input: $input) {
      shopShoppingSubcategory {
        shopId
        shoppingSubcategoryId
      }
    }
  }
`;
export type UpdateShopSubcategoriesMutationFn = Apollo.MutationFunction<
  Operations.UpdateShopSubcategoriesMutation,
  Operations.UpdateShopSubcategoriesMutationVariables
>;

/**
 * __useUpdateShopSubcategoriesMutation__
 *
 * To run a mutation, you first call `useUpdateShopSubcategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopSubcategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopSubcategoriesMutation, { data, loading, error }] = useUpdateShopSubcategoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShopSubcategoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateShopSubcategoriesMutation,
    Operations.UpdateShopSubcategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.UpdateShopSubcategoriesMutation,
    Operations.UpdateShopSubcategoriesMutationVariables
  >(UpdateShopSubcategoriesDocument, options);
}
export type UpdateShopSubcategoriesMutationHookResult = ReturnType<typeof useUpdateShopSubcategoriesMutation>;
export type UpdateShopSubcategoriesMutationResult = Apollo.MutationResult<Operations.UpdateShopSubcategoriesMutation>;
export type UpdateShopSubcategoriesMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateShopSubcategoriesMutation,
  Operations.UpdateShopSubcategoriesMutationVariables
>;
export const UpdateUserNamesDocument = gql`
  mutation UpdateUserNames($input: UpdateUserNamesInput!) {
    updateUserNames(input: $input) {
      user {
        username
      }
    }
  }
`;
export type UpdateUserNamesMutationFn = Apollo.MutationFunction<
  Operations.UpdateUserNamesMutation,
  Operations.UpdateUserNamesMutationVariables
>;

/**
 * __useUpdateUserNamesMutation__
 *
 * To run a mutation, you first call `useUpdateUserNamesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNamesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNamesMutation, { data, loading, error }] = useUpdateUserNamesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserNamesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.UpdateUserNamesMutation,
    Operations.UpdateUserNamesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UpdateUserNamesMutation, Operations.UpdateUserNamesMutationVariables>(
    UpdateUserNamesDocument,
    options
  );
}
export type UpdateUserNamesMutationHookResult = ReturnType<typeof useUpdateUserNamesMutation>;
export type UpdateUserNamesMutationResult = Apollo.MutationResult<Operations.UpdateUserNamesMutation>;
export type UpdateUserNamesMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateUserNamesMutation,
  Operations.UpdateUserNamesMutationVariables
>;
export const UpdateVariantDocument = gql`
  mutation UpdateVariant($input: UpdateVariantInput!) {
    updateVariant(input: $input) {
      variant {
        ...Variant
      }
      errors {
        ...Error
      }
    }
  }
  ${VariantFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UpdateVariantMutationFn = Apollo.MutationFunction<
  Operations.UpdateVariantMutation,
  Operations.UpdateVariantMutationVariables
>;

/**
 * __useUpdateVariantMutation__
 *
 * To run a mutation, you first call `useUpdateVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVariantMutation, { data, loading, error }] = useUpdateVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVariantMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.UpdateVariantMutation, Operations.UpdateVariantMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.UpdateVariantMutation, Operations.UpdateVariantMutationVariables>(
    UpdateVariantDocument,
    options
  );
}
export type UpdateVariantMutationHookResult = ReturnType<typeof useUpdateVariantMutation>;
export type UpdateVariantMutationResult = Apollo.MutationResult<Operations.UpdateVariantMutation>;
export type UpdateVariantMutationOptions = Apollo.BaseMutationOptions<
  Operations.UpdateVariantMutation,
  Operations.UpdateVariantMutationVariables
>;
export const VerifyCodeDocument = gql`
  mutation VerifyCode($input: VerifyCodeInput!) {
    verifyCode(input: $input) {
      phoneNumberVerificationResponse {
        message
        success
      }
    }
  }
`;
export type VerifyCodeMutationFn = Apollo.MutationFunction<
  Operations.VerifyCodeMutation,
  Operations.VerifyCodeMutationVariables
>;

/**
 * __useVerifyCodeMutation__
 *
 * To run a mutation, you first call `useVerifyCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyCodeMutation, { data, loading, error }] = useVerifyCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.VerifyCodeMutation, Operations.VerifyCodeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.VerifyCodeMutation, Operations.VerifyCodeMutationVariables>(
    VerifyCodeDocument,
    options
  );
}
export type VerifyCodeMutationHookResult = ReturnType<typeof useVerifyCodeMutation>;
export type VerifyCodeMutationResult = Apollo.MutationResult<Operations.VerifyCodeMutation>;
export type VerifyCodeMutationOptions = Apollo.BaseMutationOptions<
  Operations.VerifyCodeMutation,
  Operations.VerifyCodeMutationVariables
>;
export const VerifyUserLoginDocument = gql`
  mutation VerifyUserLogin($input: VerifyUserLoginInput!) {
    verifyUserLogin(input: $input) {
      verifyLoginResponse {
        message
        success
      }
    }
  }
`;
export type VerifyUserLoginMutationFn = Apollo.MutationFunction<
  Operations.VerifyUserLoginMutation,
  Operations.VerifyUserLoginMutationVariables
>;

/**
 * __useVerifyUserLoginMutation__
 *
 * To run a mutation, you first call `useVerifyUserLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserLoginMutation, { data, loading, error }] = useVerifyUserLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyUserLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.VerifyUserLoginMutation,
    Operations.VerifyUserLoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.VerifyUserLoginMutation, Operations.VerifyUserLoginMutationVariables>(
    VerifyUserLoginDocument,
    options
  );
}
export type VerifyUserLoginMutationHookResult = ReturnType<typeof useVerifyUserLoginMutation>;
export type VerifyUserLoginMutationResult = Apollo.MutationResult<Operations.VerifyUserLoginMutation>;
export type VerifyUserLoginMutationOptions = Apollo.BaseMutationOptions<
  Operations.VerifyUserLoginMutation,
  Operations.VerifyUserLoginMutationVariables
>;
export const AddVariantToCartPosDocument = gql`
  mutation addVariantToCartPOS($input: AddVariantToCartPOSInput!) {
    addVariantToCartPOS(input: $input) {
      cartItem {
        ...CartItem
      }
      errors {
        ...Error
      }
    }
  }
  ${CartItemFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type AddVariantToCartPosMutationFn = Apollo.MutationFunction<
  Operations.AddVariantToCartPosMutation,
  Operations.AddVariantToCartPosMutationVariables
>;

/**
 * __useAddVariantToCartPosMutation__
 *
 * To run a mutation, you first call `useAddVariantToCartPosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVariantToCartPosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVariantToCartPosMutation, { data, loading, error }] = useAddVariantToCartPosMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVariantToCartPosMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.AddVariantToCartPosMutation,
    Operations.AddVariantToCartPosMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.AddVariantToCartPosMutation, Operations.AddVariantToCartPosMutationVariables>(
    AddVariantToCartPosDocument,
    options
  );
}
export type AddVariantToCartPosMutationHookResult = ReturnType<typeof useAddVariantToCartPosMutation>;
export type AddVariantToCartPosMutationResult = Apollo.MutationResult<Operations.AddVariantToCartPosMutation>;
export type AddVariantToCartPosMutationOptions = Apollo.BaseMutationOptions<
  Operations.AddVariantToCartPosMutation,
  Operations.AddVariantToCartPosMutationVariables
>;
export const CreateExternalAccountWithDetailsDocument = gql`
  mutation createExternalAccountWithDetails($input: CreateExternalAccountWithDetailsInput!) {
    createExternalAccountWithDetails(input: $input) {
      externalAccount {
        ...ExternalAccount
      }
      errors {
        ...Error
      }
    }
  }
  ${ExternalAccountFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CreateExternalAccountWithDetailsMutationFn = Apollo.MutationFunction<
  Operations.CreateExternalAccountWithDetailsMutation,
  Operations.CreateExternalAccountWithDetailsMutationVariables
>;

/**
 * __useCreateExternalAccountWithDetailsMutation__
 *
 * To run a mutation, you first call `useCreateExternalAccountWithDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalAccountWithDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalAccountWithDetailsMutation, { data, loading, error }] = useCreateExternalAccountWithDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExternalAccountWithDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Operations.CreateExternalAccountWithDetailsMutation,
    Operations.CreateExternalAccountWithDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Operations.CreateExternalAccountWithDetailsMutation,
    Operations.CreateExternalAccountWithDetailsMutationVariables
  >(CreateExternalAccountWithDetailsDocument, options);
}
export type CreateExternalAccountWithDetailsMutationHookResult = ReturnType<
  typeof useCreateExternalAccountWithDetailsMutation
>;
export type CreateExternalAccountWithDetailsMutationResult =
  Apollo.MutationResult<Operations.CreateExternalAccountWithDetailsMutation>;
export type CreateExternalAccountWithDetailsMutationOptions = Apollo.BaseMutationOptions<
  Operations.CreateExternalAccountWithDetailsMutation,
  Operations.CreateExternalAccountWithDetailsMutationVariables
>;
export const DeletePackageDocument = gql`
  mutation DeletePackage($input: DeletePackageInput!) {
    deletePackage(input: $input) {
      shopOrder {
        orderPackages {
          nodes {
            id
            orderFulfilmentLineItems {
              id
            }
          }
        }
      }
    }
  }
`;
export type DeletePackageMutationFn = Apollo.MutationFunction<
  Operations.DeletePackageMutation,
  Operations.DeletePackageMutationVariables
>;

/**
 * __useDeletePackageMutation__
 *
 * To run a mutation, you first call `useDeletePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePackageMutation, { data, loading, error }] = useDeletePackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePackageMutation(
  baseOptions?: Apollo.MutationHookOptions<Operations.DeletePackageMutation, Operations.DeletePackageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Operations.DeletePackageMutation, Operations.DeletePackageMutationVariables>(
    DeletePackageDocument,
    options
  );
}
export type DeletePackageMutationHookResult = ReturnType<typeof useDeletePackageMutation>;
export type DeletePackageMutationResult = Apollo.MutationResult<Operations.DeletePackageMutation>;
export type DeletePackageMutationOptions = Apollo.BaseMutationOptions<
  Operations.DeletePackageMutation,
  Operations.DeletePackageMutationVariables
>;
export const AllShoppingCategoriesDocument = gql`
  query AllShoppingCategories {
    allShoppingCategories {
      id
      name
      imageUrl
    }
  }
`;

/**
 * __useAllShoppingCategoriesQuery__
 *
 * To run a query within a React component, call `useAllShoppingCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllShoppingCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllShoppingCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllShoppingCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Operations.AllShoppingCategoriesQuery,
    Operations.AllShoppingCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.AllShoppingCategoriesQuery, Operations.AllShoppingCategoriesQueryVariables>(
    AllShoppingCategoriesDocument,
    options
  );
}
export function useAllShoppingCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.AllShoppingCategoriesQuery,
    Operations.AllShoppingCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.AllShoppingCategoriesQuery, Operations.AllShoppingCategoriesQueryVariables>(
    AllShoppingCategoriesDocument,
    options
  );
}
export type AllShoppingCategoriesQueryHookResult = ReturnType<typeof useAllShoppingCategoriesQuery>;
export type AllShoppingCategoriesLazyQueryHookResult = ReturnType<typeof useAllShoppingCategoriesLazyQuery>;
export type AllShoppingCategoriesQueryResult = Apollo.QueryResult<
  Operations.AllShoppingCategoriesQuery,
  Operations.AllShoppingCategoriesQueryVariables
>;
export const AnyShopBySlugDocument = gql`
  query AnyShopBySlug($slug: String!) {
    anyShopBySlug(slug: $slug) {
      ...Shop
    }
  }
  ${ShopFragmentDoc}
`;

/**
 * __useAnyShopBySlugQuery__
 *
 * To run a query within a React component, call `useAnyShopBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnyShopBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnyShopBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useAnyShopBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.AnyShopBySlugQuery, Operations.AnyShopBySlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.AnyShopBySlugQuery, Operations.AnyShopBySlugQueryVariables>(
    AnyShopBySlugDocument,
    options
  );
}
export function useAnyShopBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.AnyShopBySlugQuery, Operations.AnyShopBySlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.AnyShopBySlugQuery, Operations.AnyShopBySlugQueryVariables>(
    AnyShopBySlugDocument,
    options
  );
}
export type AnyShopBySlugQueryHookResult = ReturnType<typeof useAnyShopBySlugQuery>;
export type AnyShopBySlugLazyQueryHookResult = ReturnType<typeof useAnyShopBySlugLazyQuery>;
export type AnyShopBySlugQueryResult = Apollo.QueryResult<
  Operations.AnyShopBySlugQuery,
  Operations.AnyShopBySlugQueryVariables
>;
export const CartDocument = gql`
  query Cart($cartId: ID!) {
    cart(id: $cartId) {
      cartItems {
        nodes {
          ...CartItem
        }
      }
    }
  }
  ${CartItemFragmentDoc}
`;

/**
 * __useCartQuery__
 *
 * To run a query within a React component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQuery({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useCartQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.CartQuery, Operations.CartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.CartQuery, Operations.CartQueryVariables>(CartDocument, options);
}
export function useCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.CartQuery, Operations.CartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.CartQuery, Operations.CartQueryVariables>(CartDocument, options);
}
export type CartQueryHookResult = ReturnType<typeof useCartQuery>;
export type CartLazyQueryHookResult = ReturnType<typeof useCartLazyQuery>;
export type CartQueryResult = Apollo.QueryResult<Operations.CartQuery, Operations.CartQueryVariables>;
export const CartsByCustomerDocument = gql`
  query CartsByCustomer($customerId: ID!) {
    cartsByCustomer(customerId: $customerId) {
      nodes {
        id
        shopId
      }
    }
  }
`;

/**
 * __useCartsByCustomerQuery__
 *
 * To run a query within a React component, call `useCartsByCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartsByCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartsByCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCartsByCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.CartsByCustomerQuery, Operations.CartsByCustomerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.CartsByCustomerQuery, Operations.CartsByCustomerQueryVariables>(
    CartsByCustomerDocument,
    options
  );
}
export function useCartsByCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.CartsByCustomerQuery, Operations.CartsByCustomerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.CartsByCustomerQuery, Operations.CartsByCustomerQueryVariables>(
    CartsByCustomerDocument,
    options
  );
}
export type CartsByCustomerQueryHookResult = ReturnType<typeof useCartsByCustomerQuery>;
export type CartsByCustomerLazyQueryHookResult = ReturnType<typeof useCartsByCustomerLazyQuery>;
export type CartsByCustomerQueryResult = Apollo.QueryResult<
  Operations.CartsByCustomerQuery,
  Operations.CartsByCustomerQueryVariables
>;
export const CategoryDocument = gql`
  query Category($id: ID!) {
    category(id: $id) {
      ...CategoryChildren
    }
  }
  ${CategoryChildrenFragmentDoc}
`;

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.CategoryQuery, Operations.CategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.CategoryQuery, Operations.CategoryQueryVariables>(CategoryDocument, options);
}
export function useCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.CategoryQuery, Operations.CategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.CategoryQuery, Operations.CategoryQueryVariables>(CategoryDocument, options);
}
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategoryQueryResult = Apollo.QueryResult<Operations.CategoryQuery, Operations.CategoryQueryVariables>;
export const CheckShopSlugDocument = gql`
  query CheckShopSlug($slug: String!) {
    shopBySlug(slug: $slug) {
      id
    }
  }
`;

/**
 * __useCheckShopSlugQuery__
 *
 * To run a query within a React component, call `useCheckShopSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckShopSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckShopSlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCheckShopSlugQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.CheckShopSlugQuery, Operations.CheckShopSlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.CheckShopSlugQuery, Operations.CheckShopSlugQueryVariables>(
    CheckShopSlugDocument,
    options
  );
}
export function useCheckShopSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.CheckShopSlugQuery, Operations.CheckShopSlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.CheckShopSlugQuery, Operations.CheckShopSlugQueryVariables>(
    CheckShopSlugDocument,
    options
  );
}
export type CheckShopSlugQueryHookResult = ReturnType<typeof useCheckShopSlugQuery>;
export type CheckShopSlugLazyQueryHookResult = ReturnType<typeof useCheckShopSlugLazyQuery>;
export type CheckShopSlugQueryResult = Apollo.QueryResult<
  Operations.CheckShopSlugQuery,
  Operations.CheckShopSlugQueryVariables
>;
export const CheckShowSlugDocument = gql`
  query CheckShowSlug($shopSlug: String!, $showSlug: String!) {
    showBySlug(shopSlug: $shopSlug, showSlug: $showSlug) {
      id
    }
  }
`;

/**
 * __useCheckShowSlugQuery__
 *
 * To run a query within a React component, call `useCheckShowSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckShowSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckShowSlugQuery({
 *   variables: {
 *      shopSlug: // value for 'shopSlug'
 *      showSlug: // value for 'showSlug'
 *   },
 * });
 */
export function useCheckShowSlugQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.CheckShowSlugQuery, Operations.CheckShowSlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.CheckShowSlugQuery, Operations.CheckShowSlugQueryVariables>(
    CheckShowSlugDocument,
    options
  );
}
export function useCheckShowSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.CheckShowSlugQuery, Operations.CheckShowSlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.CheckShowSlugQuery, Operations.CheckShowSlugQueryVariables>(
    CheckShowSlugDocument,
    options
  );
}
export type CheckShowSlugQueryHookResult = ReturnType<typeof useCheckShowSlugQuery>;
export type CheckShowSlugLazyQueryHookResult = ReturnType<typeof useCheckShowSlugLazyQuery>;
export type CheckShowSlugQueryResult = Apollo.QueryResult<
  Operations.CheckShowSlugQuery,
  Operations.CheckShowSlugQueryVariables
>;
export const CheckoutDocument = gql`
  query Checkout($id: ID!) {
    checkout(id: $id) {
      ...Checkout
    }
  }
  ${CheckoutFragmentDoc}
`;

/**
 * __useCheckoutQuery__
 *
 * To run a query within a React component, call `useCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckoutQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.CheckoutQuery, Operations.CheckoutQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.CheckoutQuery, Operations.CheckoutQueryVariables>(CheckoutDocument, options);
}
export function useCheckoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.CheckoutQuery, Operations.CheckoutQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.CheckoutQuery, Operations.CheckoutQueryVariables>(CheckoutDocument, options);
}
export type CheckoutQueryHookResult = ReturnType<typeof useCheckoutQuery>;
export type CheckoutLazyQueryHookResult = ReturnType<typeof useCheckoutLazyQuery>;
export type CheckoutQueryResult = Apollo.QueryResult<Operations.CheckoutQuery, Operations.CheckoutQueryVariables>;
export const CustomerPaymentMethodDocument = gql`
  query CustomerPaymentMethod($id: ID!) {
    customerPaymentMethod(id: $id) {
      ...CustomerPaymentMethod
    }
  }
  ${CustomerPaymentMethodFragmentDoc}
`;

/**
 * __useCustomerPaymentMethodQuery__
 *
 * To run a query within a React component, call `useCustomerPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerPaymentMethodQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerPaymentMethodQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.CustomerPaymentMethodQuery,
    Operations.CustomerPaymentMethodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.CustomerPaymentMethodQuery, Operations.CustomerPaymentMethodQueryVariables>(
    CustomerPaymentMethodDocument,
    options
  );
}
export function useCustomerPaymentMethodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.CustomerPaymentMethodQuery,
    Operations.CustomerPaymentMethodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.CustomerPaymentMethodQuery, Operations.CustomerPaymentMethodQueryVariables>(
    CustomerPaymentMethodDocument,
    options
  );
}
export type CustomerPaymentMethodQueryHookResult = ReturnType<typeof useCustomerPaymentMethodQuery>;
export type CustomerPaymentMethodLazyQueryHookResult = ReturnType<typeof useCustomerPaymentMethodLazyQuery>;
export type CustomerPaymentMethodQueryResult = Apollo.QueryResult<
  Operations.CustomerPaymentMethodQuery,
  Operations.CustomerPaymentMethodQueryVariables
>;
export const DefaultVariantDocument = gql`
  query DefaultVariant($productId: ID!) {
    product(id: $productId) {
      id
      defaultVariant {
        ...Variant
      }
    }
  }
  ${VariantFragmentDoc}
`;

/**
 * __useDefaultVariantQuery__
 *
 * To run a query within a React component, call `useDefaultVariantQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultVariantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultVariantQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useDefaultVariantQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.DefaultVariantQuery, Operations.DefaultVariantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.DefaultVariantQuery, Operations.DefaultVariantQueryVariables>(
    DefaultVariantDocument,
    options
  );
}
export function useDefaultVariantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.DefaultVariantQuery, Operations.DefaultVariantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.DefaultVariantQuery, Operations.DefaultVariantQueryVariables>(
    DefaultVariantDocument,
    options
  );
}
export type DefaultVariantQueryHookResult = ReturnType<typeof useDefaultVariantQuery>;
export type DefaultVariantLazyQueryHookResult = ReturnType<typeof useDefaultVariantLazyQuery>;
export type DefaultVariantQueryResult = Apollo.QueryResult<
  Operations.DefaultVariantQuery,
  Operations.DefaultVariantQueryVariables
>;
export const ExternalAccountsByPaymentAccountDocument = gql`
  query ExternalAccountsByPaymentAccount($paymentAccountId: ID!) {
    externalAccountsByPaymentAccount(paymentAccountId: $paymentAccountId) {
      nodes {
        ...ExternalAccount
      }
    }
  }
  ${ExternalAccountFragmentDoc}
`;

/**
 * __useExternalAccountsByPaymentAccountQuery__
 *
 * To run a query within a React component, call `useExternalAccountsByPaymentAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalAccountsByPaymentAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalAccountsByPaymentAccountQuery({
 *   variables: {
 *      paymentAccountId: // value for 'paymentAccountId'
 *   },
 * });
 */
export function useExternalAccountsByPaymentAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.ExternalAccountsByPaymentAccountQuery,
    Operations.ExternalAccountsByPaymentAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.ExternalAccountsByPaymentAccountQuery,
    Operations.ExternalAccountsByPaymentAccountQueryVariables
  >(ExternalAccountsByPaymentAccountDocument, options);
}
export function useExternalAccountsByPaymentAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.ExternalAccountsByPaymentAccountQuery,
    Operations.ExternalAccountsByPaymentAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.ExternalAccountsByPaymentAccountQuery,
    Operations.ExternalAccountsByPaymentAccountQueryVariables
  >(ExternalAccountsByPaymentAccountDocument, options);
}
export type ExternalAccountsByPaymentAccountQueryHookResult = ReturnType<
  typeof useExternalAccountsByPaymentAccountQuery
>;
export type ExternalAccountsByPaymentAccountLazyQueryHookResult = ReturnType<
  typeof useExternalAccountsByPaymentAccountLazyQuery
>;
export type ExternalAccountsByPaymentAccountQueryResult = Apollo.QueryResult<
  Operations.ExternalAccountsByPaymentAccountQuery,
  Operations.ExternalAccountsByPaymentAccountQueryVariables
>;
export const FbBusinessAccountByShopDocument = gql`
  query FbBusinessAccountByShop($shopId: ID!) {
    fbBusinessAccountByShop(shopId: $shopId) {
      ...FbBusinessAccount
    }
  }
  ${FbBusinessAccountFragmentDoc}
`;

/**
 * __useFbBusinessAccountByShopQuery__
 *
 * To run a query within a React component, call `useFbBusinessAccountByShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useFbBusinessAccountByShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFbBusinessAccountByShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useFbBusinessAccountByShopQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.FbBusinessAccountByShopQuery,
    Operations.FbBusinessAccountByShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.FbBusinessAccountByShopQuery, Operations.FbBusinessAccountByShopQueryVariables>(
    FbBusinessAccountByShopDocument,
    options
  );
}
export function useFbBusinessAccountByShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.FbBusinessAccountByShopQuery,
    Operations.FbBusinessAccountByShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.FbBusinessAccountByShopQuery, Operations.FbBusinessAccountByShopQueryVariables>(
    FbBusinessAccountByShopDocument,
    options
  );
}
export type FbBusinessAccountByShopQueryHookResult = ReturnType<typeof useFbBusinessAccountByShopQuery>;
export type FbBusinessAccountByShopLazyQueryHookResult = ReturnType<typeof useFbBusinessAccountByShopLazyQuery>;
export type FbBusinessAccountByShopQueryResult = Apollo.QueryResult<
  Operations.FbBusinessAccountByShopQuery,
  Operations.FbBusinessAccountByShopQueryVariables
>;
export const FbBusinessAccountLivesByShopDocument = gql`
  query FbBusinessAccountLivesByShop($shopId: ID!) {
    fbBusinessAccountByShop(shopId: $shopId) {
      ...FbBusinessAccount
      pages {
        nodes {
          ...FbPageLives
        }
      }
    }
  }
  ${FbBusinessAccountFragmentDoc}
  ${FbPageLivesFragmentDoc}
`;

/**
 * __useFbBusinessAccountLivesByShopQuery__
 *
 * To run a query within a React component, call `useFbBusinessAccountLivesByShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useFbBusinessAccountLivesByShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFbBusinessAccountLivesByShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useFbBusinessAccountLivesByShopQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.FbBusinessAccountLivesByShopQuery,
    Operations.FbBusinessAccountLivesByShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.FbBusinessAccountLivesByShopQuery,
    Operations.FbBusinessAccountLivesByShopQueryVariables
  >(FbBusinessAccountLivesByShopDocument, options);
}
export function useFbBusinessAccountLivesByShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.FbBusinessAccountLivesByShopQuery,
    Operations.FbBusinessAccountLivesByShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.FbBusinessAccountLivesByShopQuery,
    Operations.FbBusinessAccountLivesByShopQueryVariables
  >(FbBusinessAccountLivesByShopDocument, options);
}
export type FbBusinessAccountLivesByShopQueryHookResult = ReturnType<typeof useFbBusinessAccountLivesByShopQuery>;
export type FbBusinessAccountLivesByShopLazyQueryHookResult = ReturnType<
  typeof useFbBusinessAccountLivesByShopLazyQuery
>;
export type FbBusinessAccountLivesByShopQueryResult = Apollo.QueryResult<
  Operations.FbBusinessAccountLivesByShopQuery,
  Operations.FbBusinessAccountLivesByShopQueryVariables
>;
export const FbCommentsSessionByShowDocument = gql`
  query FbCommentsSessionByShow($showId: ID!) {
    fbCommentsSessionByShow(showId: $showId) {
      ...FbCommentsSession
    }
  }
  ${FbCommentsSessionFragmentDoc}
`;

/**
 * __useFbCommentsSessionByShowQuery__
 *
 * To run a query within a React component, call `useFbCommentsSessionByShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useFbCommentsSessionByShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFbCommentsSessionByShowQuery({
 *   variables: {
 *      showId: // value for 'showId'
 *   },
 * });
 */
export function useFbCommentsSessionByShowQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.FbCommentsSessionByShowQuery,
    Operations.FbCommentsSessionByShowQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.FbCommentsSessionByShowQuery, Operations.FbCommentsSessionByShowQueryVariables>(
    FbCommentsSessionByShowDocument,
    options
  );
}
export function useFbCommentsSessionByShowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.FbCommentsSessionByShowQuery,
    Operations.FbCommentsSessionByShowQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.FbCommentsSessionByShowQuery, Operations.FbCommentsSessionByShowQueryVariables>(
    FbCommentsSessionByShowDocument,
    options
  );
}
export type FbCommentsSessionByShowQueryHookResult = ReturnType<typeof useFbCommentsSessionByShowQuery>;
export type FbCommentsSessionByShowLazyQueryHookResult = ReturnType<typeof useFbCommentsSessionByShowLazyQuery>;
export type FbCommentsSessionByShowQueryResult = Apollo.QueryResult<
  Operations.FbCommentsSessionByShowQuery,
  Operations.FbCommentsSessionByShowQueryVariables
>;
export const FbPageUserByCommentDocument = gql`
  query FbPageUserByComment($commentId: ID!) {
    fbPageUserByComment(commentId: $commentId) {
      userId
      pageId
      businessAccountId
      pageScopedUserId
    }
  }
`;

/**
 * __useFbPageUserByCommentQuery__
 *
 * To run a query within a React component, call `useFbPageUserByCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFbPageUserByCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFbPageUserByCommentQuery({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useFbPageUserByCommentQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.FbPageUserByCommentQuery,
    Operations.FbPageUserByCommentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.FbPageUserByCommentQuery, Operations.FbPageUserByCommentQueryVariables>(
    FbPageUserByCommentDocument,
    options
  );
}
export function useFbPageUserByCommentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.FbPageUserByCommentQuery,
    Operations.FbPageUserByCommentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.FbPageUserByCommentQuery, Operations.FbPageUserByCommentQueryVariables>(
    FbPageUserByCommentDocument,
    options
  );
}
export type FbPageUserByCommentQueryHookResult = ReturnType<typeof useFbPageUserByCommentQuery>;
export type FbPageUserByCommentLazyQueryHookResult = ReturnType<typeof useFbPageUserByCommentLazyQuery>;
export type FbPageUserByCommentQueryResult = Apollo.QueryResult<
  Operations.FbPageUserByCommentQuery,
  Operations.FbPageUserByCommentQueryVariables
>;
export const FeaturedShowCardRefreshDocument = gql`
  query FeaturedShowCardRefresh($id: ID!) {
    showCard(id: $id) {
      ...FeaturedShowCard
    }
  }
  ${FeaturedShowCardFragmentDoc}
`;

/**
 * __useFeaturedShowCardRefreshQuery__
 *
 * To run a query within a React component, call `useFeaturedShowCardRefreshQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedShowCardRefreshQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedShowCardRefreshQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFeaturedShowCardRefreshQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.FeaturedShowCardRefreshQuery,
    Operations.FeaturedShowCardRefreshQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.FeaturedShowCardRefreshQuery, Operations.FeaturedShowCardRefreshQueryVariables>(
    FeaturedShowCardRefreshDocument,
    options
  );
}
export function useFeaturedShowCardRefreshLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.FeaturedShowCardRefreshQuery,
    Operations.FeaturedShowCardRefreshQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.FeaturedShowCardRefreshQuery, Operations.FeaturedShowCardRefreshQueryVariables>(
    FeaturedShowCardRefreshDocument,
    options
  );
}
export type FeaturedShowCardRefreshQueryHookResult = ReturnType<typeof useFeaturedShowCardRefreshQuery>;
export type FeaturedShowCardRefreshLazyQueryHookResult = ReturnType<typeof useFeaturedShowCardRefreshLazyQuery>;
export type FeaturedShowCardRefreshQueryResult = Apollo.QueryResult<
  Operations.FeaturedShowCardRefreshQuery,
  Operations.FeaturedShowCardRefreshQueryVariables
>;
export const InventoryItemDocument = gql`
  query InventoryItem($id: ID!) {
    inventoryItem(id: $id) {
      ...InventoryItem
    }
  }
  ${InventoryItemFragmentDoc}
`;

/**
 * __useInventoryItemQuery__
 *
 * To run a query within a React component, call `useInventoryItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInventoryItemQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.InventoryItemQuery, Operations.InventoryItemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.InventoryItemQuery, Operations.InventoryItemQueryVariables>(
    InventoryItemDocument,
    options
  );
}
export function useInventoryItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.InventoryItemQuery, Operations.InventoryItemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.InventoryItemQuery, Operations.InventoryItemQueryVariables>(
    InventoryItemDocument,
    options
  );
}
export type InventoryItemQueryHookResult = ReturnType<typeof useInventoryItemQuery>;
export type InventoryItemLazyQueryHookResult = ReturnType<typeof useInventoryItemLazyQuery>;
export type InventoryItemQueryResult = Apollo.QueryResult<
  Operations.InventoryItemQuery,
  Operations.InventoryItemQueryVariables
>;
export const InventoryItemsByBarcodeDocument = gql`
  query InventoryItemsByBarcode($shopId: ID!, $barcode: Barcode!, $first: Int = 10, $cursor: String) {
    inventoryItemsByBarcode(shopId: $shopId, barcode: $barcode, first: $first, after: $cursor) {
      nodes {
        id
        product {
          ...Product
        }
        variant {
          ...Variant
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ProductFragmentDoc}
  ${VariantFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useInventoryItemsByBarcodeQuery__
 *
 * To run a query within a React component, call `useInventoryItemsByBarcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryItemsByBarcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryItemsByBarcodeQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      barcode: // value for 'barcode'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useInventoryItemsByBarcodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.InventoryItemsByBarcodeQuery,
    Operations.InventoryItemsByBarcodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.InventoryItemsByBarcodeQuery, Operations.InventoryItemsByBarcodeQueryVariables>(
    InventoryItemsByBarcodeDocument,
    options
  );
}
export function useInventoryItemsByBarcodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.InventoryItemsByBarcodeQuery,
    Operations.InventoryItemsByBarcodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.InventoryItemsByBarcodeQuery, Operations.InventoryItemsByBarcodeQueryVariables>(
    InventoryItemsByBarcodeDocument,
    options
  );
}
export type InventoryItemsByBarcodeQueryHookResult = ReturnType<typeof useInventoryItemsByBarcodeQuery>;
export type InventoryItemsByBarcodeLazyQueryHookResult = ReturnType<typeof useInventoryItemsByBarcodeLazyQuery>;
export type InventoryItemsByBarcodeQueryResult = Apollo.QueryResult<
  Operations.InventoryItemsByBarcodeQuery,
  Operations.InventoryItemsByBarcodeQueryVariables
>;
export const OrderDocument = gql`
  query Order($orderId: ID!) {
    order(id: $orderId) {
      ...OrderDetails
    }
  }
  ${OrderDetailsFragmentDoc}
`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.OrderQuery, Operations.OrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.OrderQuery, Operations.OrderQueryVariables>(OrderDocument, options);
}
export function useOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.OrderQuery, Operations.OrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.OrderQuery, Operations.OrderQueryVariables>(OrderDocument, options);
}
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<Operations.OrderQuery, Operations.OrderQueryVariables>;
export const PaymentDocument = gql`
  query Payment($id: ID!) {
    payment(id: $id) {
      ...Payment
    }
  }
  ${PaymentFragmentDoc}
`;

/**
 * __usePaymentQuery__
 *
 * To run a query within a React component, call `usePaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.PaymentQuery, Operations.PaymentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.PaymentQuery, Operations.PaymentQueryVariables>(PaymentDocument, options);
}
export function usePaymentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.PaymentQuery, Operations.PaymentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.PaymentQuery, Operations.PaymentQueryVariables>(PaymentDocument, options);
}
export type PaymentQueryHookResult = ReturnType<typeof usePaymentQuery>;
export type PaymentLazyQueryHookResult = ReturnType<typeof usePaymentLazyQuery>;
export type PaymentQueryResult = Apollo.QueryResult<Operations.PaymentQuery, Operations.PaymentQueryVariables>;
export const ProductDocument = gql`
  query Product($id: ID!) {
    product(id: $id) {
      ...ProductDetails
    }
  }
  ${ProductDetailsFragmentDoc}
`;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.ProductQuery, Operations.ProductQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ProductQuery, Operations.ProductQueryVariables>(ProductDocument, options);
}
export function useProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ProductQuery, Operations.ProductQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ProductQuery, Operations.ProductQueryVariables>(ProductDocument, options);
}
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<Operations.ProductQuery, Operations.ProductQueryVariables>;
export const ProductPublicDocument = gql`
  query ProductPublic($id: ID!) {
    product(id: $id) {
      ...ProductDetailsPublic
    }
  }
  ${ProductDetailsPublicFragmentDoc}
`;

/**
 * __useProductPublicQuery__
 *
 * To run a query within a React component, call `useProductPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductPublicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductPublicQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.ProductPublicQuery, Operations.ProductPublicQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ProductPublicQuery, Operations.ProductPublicQueryVariables>(
    ProductPublicDocument,
    options
  );
}
export function useProductPublicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ProductPublicQuery, Operations.ProductPublicQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ProductPublicQuery, Operations.ProductPublicQueryVariables>(
    ProductPublicDocument,
    options
  );
}
export type ProductPublicQueryHookResult = ReturnType<typeof useProductPublicQuery>;
export type ProductPublicLazyQueryHookResult = ReturnType<typeof useProductPublicLazyQuery>;
export type ProductPublicQueryResult = Apollo.QueryResult<
  Operations.ProductPublicQuery,
  Operations.ProductPublicQueryVariables
>;
export const ProductsByShopSlugDocument = gql`
  query ProductsByShopSlug($shopSlug: String!) {
    productsByShopSlug(shopSlug: $shopSlug) {
      nodes {
        id
        name
        defaultImage {
          transformedSrc
        }
        defaultVariant {
          prices {
            price
          }
        }
        options {
          nodes {
            name
            optionValues {
              nodes {
                id
                label
              }
            }
          }
        }
        variants {
          nodes {
            id
            default
            prices {
              price
            }
            optionValues {
              nodes {
                label
                option {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useProductsByShopSlugQuery__
 *
 * To run a query within a React component, call `useProductsByShopSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsByShopSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsByShopSlugQuery({
 *   variables: {
 *      shopSlug: // value for 'shopSlug'
 *   },
 * });
 */
export function useProductsByShopSlugQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.ProductsByShopSlugQuery, Operations.ProductsByShopSlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ProductsByShopSlugQuery, Operations.ProductsByShopSlugQueryVariables>(
    ProductsByShopSlugDocument,
    options
  );
}
export function useProductsByShopSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.ProductsByShopSlugQuery,
    Operations.ProductsByShopSlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ProductsByShopSlugQuery, Operations.ProductsByShopSlugQueryVariables>(
    ProductsByShopSlugDocument,
    options
  );
}
export type ProductsByShopSlugQueryHookResult = ReturnType<typeof useProductsByShopSlugQuery>;
export type ProductsByShopSlugLazyQueryHookResult = ReturnType<typeof useProductsByShopSlugLazyQuery>;
export type ProductsByShopSlugQueryResult = Apollo.QueryResult<
  Operations.ProductsByShopSlugQuery,
  Operations.ProductsByShopSlugQueryVariables
>;
export const RootCategoriesDocument = gql`
  query RootCategories {
    categoryTree(first: 100) {
      nodes {
        ...CategoryChildren
      }
    }
  }
  ${CategoryChildrenFragmentDoc}
`;

/**
 * __useRootCategoriesQuery__
 *
 * To run a query within a React component, call `useRootCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRootCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRootCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRootCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<Operations.RootCategoriesQuery, Operations.RootCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.RootCategoriesQuery, Operations.RootCategoriesQueryVariables>(
    RootCategoriesDocument,
    options
  );
}
export function useRootCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.RootCategoriesQuery, Operations.RootCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.RootCategoriesQuery, Operations.RootCategoriesQueryVariables>(
    RootCategoriesDocument,
    options
  );
}
export type RootCategoriesQueryHookResult = ReturnType<typeof useRootCategoriesQuery>;
export type RootCategoriesLazyQueryHookResult = ReturnType<typeof useRootCategoriesLazyQuery>;
export type RootCategoriesQueryResult = Apollo.QueryResult<
  Operations.RootCategoriesQuery,
  Operations.RootCategoriesQueryVariables
>;
export const SearchProductsByShopDocument = gql`
  query SearchProductsByShop($shopId: ID!, $query: String!, $first: Int = 15, $cursor: String) {
    searchProductsByShop(
      shopId: $shopId
      query: $query
      filter: { status: { eq: PUBLISHED } }
      order: { updatedAt: DESC }
      first: $first
      after: $cursor
    ) {
      edges {
        node {
          ...Product
        }
        cursor
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ProductFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useSearchProductsByShopQuery__
 *
 * To run a query within a React component, call `useSearchProductsByShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductsByShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductsByShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSearchProductsByShopQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.SearchProductsByShopQuery,
    Operations.SearchProductsByShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.SearchProductsByShopQuery, Operations.SearchProductsByShopQueryVariables>(
    SearchProductsByShopDocument,
    options
  );
}
export function useSearchProductsByShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.SearchProductsByShopQuery,
    Operations.SearchProductsByShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.SearchProductsByShopQuery, Operations.SearchProductsByShopQueryVariables>(
    SearchProductsByShopDocument,
    options
  );
}
export type SearchProductsByShopQueryHookResult = ReturnType<typeof useSearchProductsByShopQuery>;
export type SearchProductsByShopLazyQueryHookResult = ReturnType<typeof useSearchProductsByShopLazyQuery>;
export type SearchProductsByShopQueryResult = Apollo.QueryResult<
  Operations.SearchProductsByShopQuery,
  Operations.SearchProductsByShopQueryVariables
>;
export const SearchShopOrdersByShopDocument = gql`
  query SearchShopOrdersByShop($shopId: ID!, $query: String!, $first: Int = 15, $cursor: String) {
    searchShopOrdersByShop(shopId: $shopId, query: $query, order: { createdAt: DESC }, first: $first, after: $cursor) {
      edges {
        node {
          ...ShopOrder
        }
        cursor
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ShopOrderFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useSearchShopOrdersByShopQuery__
 *
 * To run a query within a React component, call `useSearchShopOrdersByShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchShopOrdersByShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchShopOrdersByShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSearchShopOrdersByShopQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.SearchShopOrdersByShopQuery,
    Operations.SearchShopOrdersByShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.SearchShopOrdersByShopQuery, Operations.SearchShopOrdersByShopQueryVariables>(
    SearchShopOrdersByShopDocument,
    options
  );
}
export function useSearchShopOrdersByShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.SearchShopOrdersByShopQuery,
    Operations.SearchShopOrdersByShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.SearchShopOrdersByShopQuery, Operations.SearchShopOrdersByShopQueryVariables>(
    SearchShopOrdersByShopDocument,
    options
  );
}
export type SearchShopOrdersByShopQueryHookResult = ReturnType<typeof useSearchShopOrdersByShopQuery>;
export type SearchShopOrdersByShopLazyQueryHookResult = ReturnType<typeof useSearchShopOrdersByShopLazyQuery>;
export type SearchShopOrdersByShopQueryResult = Apollo.QueryResult<
  Operations.SearchShopOrdersByShopQuery,
  Operations.SearchShopOrdersByShopQueryVariables
>;
export const SearchShopsDocument = gql`
  query SearchShops($query: String!, $first: Int = 15, $cursor: String) {
    searchShops(query: $query, first: $first, after: $cursor) {
      edges {
        node {
          ...Shop
        }
        cursor
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ShopFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useSearchShopsQuery__
 *
 * To run a query within a React component, call `useSearchShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchShopsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSearchShopsQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.SearchShopsQuery, Operations.SearchShopsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.SearchShopsQuery, Operations.SearchShopsQueryVariables>(
    SearchShopsDocument,
    options
  );
}
export function useSearchShopsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.SearchShopsQuery, Operations.SearchShopsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.SearchShopsQuery, Operations.SearchShopsQueryVariables>(
    SearchShopsDocument,
    options
  );
}
export type SearchShopsQueryHookResult = ReturnType<typeof useSearchShopsQuery>;
export type SearchShopsLazyQueryHookResult = ReturnType<typeof useSearchShopsLazyQuery>;
export type SearchShopsQueryResult = Apollo.QueryResult<
  Operations.SearchShopsQuery,
  Operations.SearchShopsQueryVariables
>;
export const SearchShowsByShopDocument = gql`
  query SearchShowsByShop($shopId: ID!, $query: String!) {
    searchShowsByShop(shopId: $shopId, query: $query) {
      edges {
        node {
          ...Show
        }
      }
    }
  }
  ${ShowFragmentDoc}
`;

/**
 * __useSearchShowsByShopQuery__
 *
 * To run a query within a React component, call `useSearchShowsByShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchShowsByShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchShowsByShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchShowsByShopQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.SearchShowsByShopQuery, Operations.SearchShowsByShopQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.SearchShowsByShopQuery, Operations.SearchShowsByShopQueryVariables>(
    SearchShowsByShopDocument,
    options
  );
}
export function useSearchShowsByShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.SearchShowsByShopQuery,
    Operations.SearchShowsByShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.SearchShowsByShopQuery, Operations.SearchShowsByShopQueryVariables>(
    SearchShowsByShopDocument,
    options
  );
}
export type SearchShowsByShopQueryHookResult = ReturnType<typeof useSearchShowsByShopQuery>;
export type SearchShowsByShopLazyQueryHookResult = ReturnType<typeof useSearchShowsByShopLazyQuery>;
export type SearchShowsByShopQueryResult = Apollo.QueryResult<
  Operations.SearchShowsByShopQuery,
  Operations.SearchShowsByShopQueryVariables
>;
export const SearchTaxCategoriesDocument = gql`
  query SearchTaxCategories($query: String!, $cursor: String) {
    searchTaxCategories(first: 25, query: $query, after: $cursor) {
      edges {
        node {
          ...TaxCategory
        }
        cursor
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${TaxCategoryFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useSearchTaxCategoriesQuery__
 *
 * To run a query within a React component, call `useSearchTaxCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTaxCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTaxCategoriesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSearchTaxCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.SearchTaxCategoriesQuery,
    Operations.SearchTaxCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.SearchTaxCategoriesQuery, Operations.SearchTaxCategoriesQueryVariables>(
    SearchTaxCategoriesDocument,
    options
  );
}
export function useSearchTaxCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.SearchTaxCategoriesQuery,
    Operations.SearchTaxCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.SearchTaxCategoriesQuery, Operations.SearchTaxCategoriesQueryVariables>(
    SearchTaxCategoriesDocument,
    options
  );
}
export type SearchTaxCategoriesQueryHookResult = ReturnType<typeof useSearchTaxCategoriesQuery>;
export type SearchTaxCategoriesLazyQueryHookResult = ReturnType<typeof useSearchTaxCategoriesLazyQuery>;
export type SearchTaxCategoriesQueryResult = Apollo.QueryResult<
  Operations.SearchTaxCategoriesQuery,
  Operations.SearchTaxCategoriesQueryVariables
>;
export const SellerCategoriesDocument = gql`
  query SellerCategories($first: Int = 100, $cursor: String) {
    sellerCategories(first: $first, after: $cursor) {
      nodes {
        id
        name
        description
        slug
      }
    }
  }
`;

/**
 * __useSellerCategoriesQuery__
 *
 * To run a query within a React component, call `useSellerCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerCategoriesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSellerCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<Operations.SellerCategoriesQuery, Operations.SellerCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.SellerCategoriesQuery, Operations.SellerCategoriesQueryVariables>(
    SellerCategoriesDocument,
    options
  );
}
export function useSellerCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.SellerCategoriesQuery, Operations.SellerCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.SellerCategoriesQuery, Operations.SellerCategoriesQueryVariables>(
    SellerCategoriesDocument,
    options
  );
}
export type SellerCategoriesQueryHookResult = ReturnType<typeof useSellerCategoriesQuery>;
export type SellerCategoriesLazyQueryHookResult = ReturnType<typeof useSellerCategoriesLazyQuery>;
export type SellerCategoriesQueryResult = Apollo.QueryResult<
  Operations.SellerCategoriesQuery,
  Operations.SellerCategoriesQueryVariables
>;
export const SellerSubCategoriesDocument = gql`
  query SellerSubCategories($input: [ID!]!) {
    sellerSubCategories(categoryIds: $input) {
      name
      categories {
        id
        name
      }
    }
  }
`;

/**
 * __useSellerSubCategoriesQuery__
 *
 * To run a query within a React component, call `useSellerSubCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerSubCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerSubCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSellerSubCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.SellerSubCategoriesQuery,
    Operations.SellerSubCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.SellerSubCategoriesQuery, Operations.SellerSubCategoriesQueryVariables>(
    SellerSubCategoriesDocument,
    options
  );
}
export function useSellerSubCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.SellerSubCategoriesQuery,
    Operations.SellerSubCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.SellerSubCategoriesQuery, Operations.SellerSubCategoriesQueryVariables>(
    SellerSubCategoriesDocument,
    options
  );
}
export type SellerSubCategoriesQueryHookResult = ReturnType<typeof useSellerSubCategoriesQuery>;
export type SellerSubCategoriesLazyQueryHookResult = ReturnType<typeof useSellerSubCategoriesLazyQuery>;
export type SellerSubCategoriesQueryResult = Apollo.QueryResult<
  Operations.SellerSubCategoriesQuery,
  Operations.SellerSubCategoriesQueryVariables
>;
export const ShippingPackagesForShopDocument = gql`
  query shippingPackagesForShop($shopId: ID!) {
    shippingPackagesForShop(shopId: $shopId) {
      userPackages {
        id
        shippingProviderObjectId
        name
        packageType
        description
        weight {
          value
          unit
        }
        dimensions {
          length
          width
          height
          unit
        }
      }
      carrierPackages {
        id
        name
        tag
        imageUrl
        packageType
        dimensions {
          length
          width
          height
          unit
        }
        maxDimensions {
          length
          width
          height
          unit
        }
        minDimensions {
          length
          width
          height
          unit
        }
        maxGirthAndLength {
          value
          unit
        }
        maxWeight {
          value
          unit
        }
        minWeight {
          value
          unit
        }
      }
    }
  }
`;

/**
 * __useShippingPackagesForShopQuery__
 *
 * To run a query within a React component, call `useShippingPackagesForShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useShippingPackagesForShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShippingPackagesForShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useShippingPackagesForShopQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.ShippingPackagesForShopQuery,
    Operations.ShippingPackagesForShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ShippingPackagesForShopQuery, Operations.ShippingPackagesForShopQueryVariables>(
    ShippingPackagesForShopDocument,
    options
  );
}
export function useShippingPackagesForShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.ShippingPackagesForShopQuery,
    Operations.ShippingPackagesForShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ShippingPackagesForShopQuery, Operations.ShippingPackagesForShopQueryVariables>(
    ShippingPackagesForShopDocument,
    options
  );
}
export type ShippingPackagesForShopQueryHookResult = ReturnType<typeof useShippingPackagesForShopQuery>;
export type ShippingPackagesForShopLazyQueryHookResult = ReturnType<typeof useShippingPackagesForShopLazyQuery>;
export type ShippingPackagesForShopQueryResult = Apollo.QueryResult<
  Operations.ShippingPackagesForShopQuery,
  Operations.ShippingPackagesForShopQueryVariables
>;
export const ShopByIdDocument = gql`
  query ShopById($shopId: ID!) {
    shop(id: $shopId) {
      ...Shop
    }
  }
  ${ShopFragmentDoc}
`;

/**
 * __useShopByIdQuery__
 *
 * To run a query within a React component, call `useShopByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopByIdQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useShopByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.ShopByIdQuery, Operations.ShopByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ShopByIdQuery, Operations.ShopByIdQueryVariables>(ShopByIdDocument, options);
}
export function useShopByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShopByIdQuery, Operations.ShopByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ShopByIdQuery, Operations.ShopByIdQueryVariables>(ShopByIdDocument, options);
}
export type ShopByIdQueryHookResult = ReturnType<typeof useShopByIdQuery>;
export type ShopByIdLazyQueryHookResult = ReturnType<typeof useShopByIdLazyQuery>;
export type ShopByIdQueryResult = Apollo.QueryResult<Operations.ShopByIdQuery, Operations.ShopByIdQueryVariables>;
export const ShopBySlugDocument = gql`
  query ShopBySlug($slug: String!) {
    shopBySlug(slug: $slug) {
      ...Shop
    }
  }
  ${ShopFragmentDoc}
`;

/**
 * __useShopBySlugQuery__
 *
 * To run a query within a React component, call `useShopBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useShopBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.ShopBySlugQuery, Operations.ShopBySlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ShopBySlugQuery, Operations.ShopBySlugQueryVariables>(ShopBySlugDocument, options);
}
export function useShopBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShopBySlugQuery, Operations.ShopBySlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ShopBySlugQuery, Operations.ShopBySlugQueryVariables>(
    ShopBySlugDocument,
    options
  );
}
export type ShopBySlugQueryHookResult = ReturnType<typeof useShopBySlugQuery>;
export type ShopBySlugLazyQueryHookResult = ReturnType<typeof useShopBySlugLazyQuery>;
export type ShopBySlugQueryResult = Apollo.QueryResult<Operations.ShopBySlugQuery, Operations.ShopBySlugQueryVariables>;
export const ShopOrderDocument = gql`
  query ShopOrder($id: ID!) {
    shopOrder(id: $id) {
      ...ShopOrderDetails
      referenceNumber
      orderPackages(order: { createdAt: ASC }) {
        nodes {
          id
          status
          orderFulfilmentLineItems {
            id
            status
            quantity
          }
          shippingLabelPriceMoney {
            amount
            currency
          }
          shopOrderId
          estimatedDeliveryDate
          shipmentTrackingId
          orderId
          shippingProvider
          currency
          shippingLabelUrl
          deliveryDays
          packingSlipUrl
          shippingUserPackage {
            id
            shippingProviderObjectId
            dimensions {
              length
              width
              height
              unit
            }
            name
          }
          shippingCarrierPackage {
            id
            packageType
            dimensions {
              length
              width
              height
              unit
            }
            name
          }
          primaryWeight {
            value
            unit
          }
          secondaryWeight {
            value
            unit
          }
          totalWeight {
            value
            unit
          }
        }
      }
    }
  }
  ${ShopOrderDetailsFragmentDoc}
`;

/**
 * __useShopOrderQuery__
 *
 * To run a query within a React component, call `useShopOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShopOrderQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.ShopOrderQuery, Operations.ShopOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ShopOrderQuery, Operations.ShopOrderQueryVariables>(ShopOrderDocument, options);
}
export function useShopOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShopOrderQuery, Operations.ShopOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ShopOrderQuery, Operations.ShopOrderQueryVariables>(ShopOrderDocument, options);
}
export type ShopOrderQueryHookResult = ReturnType<typeof useShopOrderQuery>;
export type ShopOrderLazyQueryHookResult = ReturnType<typeof useShopOrderLazyQuery>;
export type ShopOrderQueryResult = Apollo.QueryResult<Operations.ShopOrderQuery, Operations.ShopOrderQueryVariables>;
export const ShopOrdersDocument = gql`
  query ShopOrders($shopId: ID!, $first: Int = 10, $cursor: String) {
    shopOrdersByShop(shopId: $shopId, first: $first, after: $cursor) {
      edges {
        node {
          ...ShopOrder
        }
        cursor
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ShopOrderFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useShopOrdersQuery__
 *
 * To run a query within a React component, call `useShopOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopOrdersQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useShopOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.ShopOrdersQuery, Operations.ShopOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ShopOrdersQuery, Operations.ShopOrdersQueryVariables>(ShopOrdersDocument, options);
}
export function useShopOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShopOrdersQuery, Operations.ShopOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ShopOrdersQuery, Operations.ShopOrdersQueryVariables>(
    ShopOrdersDocument,
    options
  );
}
export type ShopOrdersQueryHookResult = ReturnType<typeof useShopOrdersQuery>;
export type ShopOrdersLazyQueryHookResult = ReturnType<typeof useShopOrdersLazyQuery>;
export type ShopOrdersQueryResult = Apollo.QueryResult<Operations.ShopOrdersQuery, Operations.ShopOrdersQueryVariables>;
export const ShopPaymentAccountsDocument = gql`
  query ShopPaymentAccounts($shopId: ID!) {
    paymentAccountsByShop(shopId: $shopId) {
      nodes {
        ...PaymentAccount
      }
    }
  }
  ${PaymentAccountFragmentDoc}
`;

/**
 * __useShopPaymentAccountsQuery__
 *
 * To run a query within a React component, call `useShopPaymentAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopPaymentAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopPaymentAccountsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useShopPaymentAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.ShopPaymentAccountsQuery,
    Operations.ShopPaymentAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ShopPaymentAccountsQuery, Operations.ShopPaymentAccountsQueryVariables>(
    ShopPaymentAccountsDocument,
    options
  );
}
export function useShopPaymentAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.ShopPaymentAccountsQuery,
    Operations.ShopPaymentAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ShopPaymentAccountsQuery, Operations.ShopPaymentAccountsQueryVariables>(
    ShopPaymentAccountsDocument,
    options
  );
}
export type ShopPaymentAccountsQueryHookResult = ReturnType<typeof useShopPaymentAccountsQuery>;
export type ShopPaymentAccountsLazyQueryHookResult = ReturnType<typeof useShopPaymentAccountsLazyQuery>;
export type ShopPaymentAccountsQueryResult = Apollo.QueryResult<
  Operations.ShopPaymentAccountsQuery,
  Operations.ShopPaymentAccountsQueryVariables
>;
export const ShopPaymentAccountsBySlugDocument = gql`
  query ShopPaymentAccountsBySlug($slug: String!) {
    paymentAccountsByShopSlug(slug: $slug) {
      nodes {
        ...PaymentAccount
      }
    }
  }
  ${PaymentAccountFragmentDoc}
`;

/**
 * __useShopPaymentAccountsBySlugQuery__
 *
 * To run a query within a React component, call `useShopPaymentAccountsBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopPaymentAccountsBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopPaymentAccountsBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useShopPaymentAccountsBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.ShopPaymentAccountsBySlugQuery,
    Operations.ShopPaymentAccountsBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ShopPaymentAccountsBySlugQuery, Operations.ShopPaymentAccountsBySlugQueryVariables>(
    ShopPaymentAccountsBySlugDocument,
    options
  );
}
export function useShopPaymentAccountsBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.ShopPaymentAccountsBySlugQuery,
    Operations.ShopPaymentAccountsBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.ShopPaymentAccountsBySlugQuery,
    Operations.ShopPaymentAccountsBySlugQueryVariables
  >(ShopPaymentAccountsBySlugDocument, options);
}
export type ShopPaymentAccountsBySlugQueryHookResult = ReturnType<typeof useShopPaymentAccountsBySlugQuery>;
export type ShopPaymentAccountsBySlugLazyQueryHookResult = ReturnType<typeof useShopPaymentAccountsBySlugLazyQuery>;
export type ShopPaymentAccountsBySlugQueryResult = Apollo.QueryResult<
  Operations.ShopPaymentAccountsBySlugQuery,
  Operations.ShopPaymentAccountsBySlugQueryVariables
>;
export const ShopProductsDocument = gql`
  query ShopProducts($shopId: ID!, $first: Int = 10, $cursor: String) {
    productsByShop(shopId: $shopId, first: $first, after: $cursor, order: [{ createdAt: DESC }]) {
      edges {
        node {
          ...Product
          variants {
            nodes {
              id
              defaultImage {
                originalSrc
              }
              prices {
                price
              }
              optionValues {
                edges {
                  node {
                    label
                    id
                  }
                }
              }
              quantity {
                available
              }
            }
          }
        }
        cursor
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ProductFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useShopProductsQuery__
 *
 * To run a query within a React component, call `useShopProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopProductsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useShopProductsQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.ShopProductsQuery, Operations.ShopProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ShopProductsQuery, Operations.ShopProductsQueryVariables>(
    ShopProductsDocument,
    options
  );
}
export function useShopProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShopProductsQuery, Operations.ShopProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ShopProductsQuery, Operations.ShopProductsQueryVariables>(
    ShopProductsDocument,
    options
  );
}
export type ShopProductsQueryHookResult = ReturnType<typeof useShopProductsQuery>;
export type ShopProductsLazyQueryHookResult = ReturnType<typeof useShopProductsLazyQuery>;
export type ShopProductsQueryResult = Apollo.QueryResult<
  Operations.ShopProductsQuery,
  Operations.ShopProductsQueryVariables
>;
export const ShopShippingProfilesDocument = gql`
  query ShopShippingProfiles($shopId: ID!) {
    shippingProfilesByShop(shopId: $shopId) {
      edges {
        node {
          ...ShippingProfile
        }
        cursor
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ShippingProfileFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useShopShippingProfilesQuery__
 *
 * To run a query within a React component, call `useShopShippingProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopShippingProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopShippingProfilesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useShopShippingProfilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.ShopShippingProfilesQuery,
    Operations.ShopShippingProfilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ShopShippingProfilesQuery, Operations.ShopShippingProfilesQueryVariables>(
    ShopShippingProfilesDocument,
    options
  );
}
export function useShopShippingProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.ShopShippingProfilesQuery,
    Operations.ShopShippingProfilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ShopShippingProfilesQuery, Operations.ShopShippingProfilesQueryVariables>(
    ShopShippingProfilesDocument,
    options
  );
}
export type ShopShippingProfilesQueryHookResult = ReturnType<typeof useShopShippingProfilesQuery>;
export type ShopShippingProfilesLazyQueryHookResult = ReturnType<typeof useShopShippingProfilesLazyQuery>;
export type ShopShippingProfilesQueryResult = Apollo.QueryResult<
  Operations.ShopShippingProfilesQuery,
  Operations.ShopShippingProfilesQueryVariables
>;
export const ShopShowsDocument = gql`
  query ShopShows($shopId: ID!, $first: Int = 10, $cursor: String) {
    showsByShop(shopId: $shopId, first: $first, after: $cursor, order: { createdAt: DESC }) {
      edges {
        node {
          ...Show
        }
        cursor
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ShowFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useShopShowsQuery__
 *
 * To run a query within a React component, call `useShopShowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopShowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopShowsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useShopShowsQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.ShopShowsQuery, Operations.ShopShowsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ShopShowsQuery, Operations.ShopShowsQueryVariables>(ShopShowsDocument, options);
}
export function useShopShowsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShopShowsQuery, Operations.ShopShowsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ShopShowsQuery, Operations.ShopShowsQueryVariables>(ShopShowsDocument, options);
}
export type ShopShowsQueryHookResult = ReturnType<typeof useShopShowsQuery>;
export type ShopShowsLazyQueryHookResult = ReturnType<typeof useShopShowsLazyQuery>;
export type ShopShowsQueryResult = Apollo.QueryResult<Operations.ShopShowsQuery, Operations.ShopShowsQueryVariables>;
export const ShoppingSubcategoriesByIdsDocument = gql`
  query ShoppingSubcategoriesByIds($categoryIds: [ID!]!) {
    shoppingSubcategoriesByIds(categoryIds: $categoryIds) {
      id
      name
      shoppingCategory {
        id
        name
      }
    }
  }
`;

/**
 * __useShoppingSubcategoriesByIdsQuery__
 *
 * To run a query within a React component, call `useShoppingSubcategoriesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShoppingSubcategoriesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShoppingSubcategoriesByIdsQuery({
 *   variables: {
 *      categoryIds: // value for 'categoryIds'
 *   },
 * });
 */
export function useShoppingSubcategoriesByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.ShoppingSubcategoriesByIdsQuery,
    Operations.ShoppingSubcategoriesByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Operations.ShoppingSubcategoriesByIdsQuery,
    Operations.ShoppingSubcategoriesByIdsQueryVariables
  >(ShoppingSubcategoriesByIdsDocument, options);
}
export function useShoppingSubcategoriesByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.ShoppingSubcategoriesByIdsQuery,
    Operations.ShoppingSubcategoriesByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.ShoppingSubcategoriesByIdsQuery,
    Operations.ShoppingSubcategoriesByIdsQueryVariables
  >(ShoppingSubcategoriesByIdsDocument, options);
}
export type ShoppingSubcategoriesByIdsQueryHookResult = ReturnType<typeof useShoppingSubcategoriesByIdsQuery>;
export type ShoppingSubcategoriesByIdsLazyQueryHookResult = ReturnType<typeof useShoppingSubcategoriesByIdsLazyQuery>;
export type ShoppingSubcategoriesByIdsQueryResult = Apollo.QueryResult<
  Operations.ShoppingSubcategoriesByIdsQuery,
  Operations.ShoppingSubcategoriesByIdsQueryVariables
>;
export const ShopsDocument = gql`
  query Shops($first: Int = 25, $cursor: String, $filter: ShopFilterInput) {
    shops(first: $first, after: $cursor, order: { createdAt: DESC }, filter: $filter) {
      edges {
        node {
          defaultCommission
          ...Shop
        }
        cursor
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ShopFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useShopsQuery__
 *
 * To run a query within a React component, call `useShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useShopsQuery(
  baseOptions?: Apollo.QueryHookOptions<Operations.ShopsQuery, Operations.ShopsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ShopsQuery, Operations.ShopsQueryVariables>(ShopsDocument, options);
}
export function useShopsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShopsQuery, Operations.ShopsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ShopsQuery, Operations.ShopsQueryVariables>(ShopsDocument, options);
}
export type ShopsQueryHookResult = ReturnType<typeof useShopsQuery>;
export type ShopsLazyQueryHookResult = ReturnType<typeof useShopsLazyQuery>;
export type ShopsQueryResult = Apollo.QueryResult<Operations.ShopsQuery, Operations.ShopsQueryVariables>;
export const ShowDocument = gql`
  query Show($id: ID!, $first: Int = 5, $cursor: String) {
    show(id: $id) {
      ...Show
      featuredShowCard {
        ...FeaturedShowCard
      }
      showCards(first: $first, after: $cursor) {
        edges {
          node {
            ...ShowCard
          }
          cursor
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${ShowFragmentDoc}
  ${FeaturedShowCardFragmentDoc}
  ${ShowCardFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useShowQuery__
 *
 * To run a query within a React component, call `useShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useShowQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.ShowQuery, Operations.ShowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ShowQuery, Operations.ShowQueryVariables>(ShowDocument, options);
}
export function useShowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShowQuery, Operations.ShowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ShowQuery, Operations.ShowQueryVariables>(ShowDocument, options);
}
export type ShowQueryHookResult = ReturnType<typeof useShowQuery>;
export type ShowLazyQueryHookResult = ReturnType<typeof useShowLazyQuery>;
export type ShowQueryResult = Apollo.QueryResult<Operations.ShowQuery, Operations.ShowQueryVariables>;
export const ShowBySlugDocument = gql`
  query ShowBySlug($shopSlug: String!, $showSlug: String!, $first: Int = 5, $cursor: String) {
    showBySlug(shopSlug: $shopSlug, showSlug: $showSlug) {
      ...Show
      featuredShowCard {
        ...FeaturedShowCard
      }
      showCards(first: $first, after: $cursor, order: { createdAt: DESC }) {
        edges {
          node {
            ...ShowCard
          }
          cursor
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${ShowFragmentDoc}
  ${FeaturedShowCardFragmentDoc}
  ${ShowCardFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useShowBySlugQuery__
 *
 * To run a query within a React component, call `useShowBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowBySlugQuery({
 *   variables: {
 *      shopSlug: // value for 'shopSlug'
 *      showSlug: // value for 'showSlug'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useShowBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.ShowBySlugQuery, Operations.ShowBySlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ShowBySlugQuery, Operations.ShowBySlugQueryVariables>(ShowBySlugDocument, options);
}
export function useShowBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShowBySlugQuery, Operations.ShowBySlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ShowBySlugQuery, Operations.ShowBySlugQueryVariables>(
    ShowBySlugDocument,
    options
  );
}
export type ShowBySlugQueryHookResult = ReturnType<typeof useShowBySlugQuery>;
export type ShowBySlugLazyQueryHookResult = ReturnType<typeof useShowBySlugLazyQuery>;
export type ShowBySlugQueryResult = Apollo.QueryResult<Operations.ShowBySlugQuery, Operations.ShowBySlugQueryVariables>;
export const ShowBySlugPublicDocument = gql`
  query ShowBySlugPublic($shopSlug: String!, $showSlug: String!, $first: Int = 5, $cursor: String) {
    showBySlug(shopSlug: $shopSlug, showSlug: $showSlug) {
      ...ShowWithoutShopOrders
      featuredShowCard {
        ...FeaturedShowCardPublic
      }
      showCards(first: $first, after: $cursor, order: { createdAt: DESC }) {
        edges {
          node {
            ...ShowCardPublic
          }
          cursor
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${ShowWithoutShopOrdersFragmentDoc}
  ${FeaturedShowCardPublicFragmentDoc}
  ${ShowCardPublicFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useShowBySlugPublicQuery__
 *
 * To run a query within a React component, call `useShowBySlugPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowBySlugPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowBySlugPublicQuery({
 *   variables: {
 *      shopSlug: // value for 'shopSlug'
 *      showSlug: // value for 'showSlug'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useShowBySlugPublicQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.ShowBySlugPublicQuery, Operations.ShowBySlugPublicQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ShowBySlugPublicQuery, Operations.ShowBySlugPublicQueryVariables>(
    ShowBySlugPublicDocument,
    options
  );
}
export function useShowBySlugPublicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShowBySlugPublicQuery, Operations.ShowBySlugPublicQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ShowBySlugPublicQuery, Operations.ShowBySlugPublicQueryVariables>(
    ShowBySlugPublicDocument,
    options
  );
}
export type ShowBySlugPublicQueryHookResult = ReturnType<typeof useShowBySlugPublicQuery>;
export type ShowBySlugPublicLazyQueryHookResult = ReturnType<typeof useShowBySlugPublicLazyQuery>;
export type ShowBySlugPublicQueryResult = Apollo.QueryResult<
  Operations.ShowBySlugPublicQuery,
  Operations.ShowBySlugPublicQueryVariables
>;
export const ShowCardDocument = gql`
  query ShowCard($id: ID!) {
    showCard(id: $id) {
      ...ShowCard
    }
  }
  ${ShowCardFragmentDoc}
`;

/**
 * __useShowCardQuery__
 *
 * To run a query within a React component, call `useShowCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShowCardQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.ShowCardQuery, Operations.ShowCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ShowCardQuery, Operations.ShowCardQueryVariables>(ShowCardDocument, options);
}
export function useShowCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShowCardQuery, Operations.ShowCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ShowCardQuery, Operations.ShowCardQueryVariables>(ShowCardDocument, options);
}
export type ShowCardQueryHookResult = ReturnType<typeof useShowCardQuery>;
export type ShowCardLazyQueryHookResult = ReturnType<typeof useShowCardLazyQuery>;
export type ShowCardQueryResult = Apollo.QueryResult<Operations.ShowCardQuery, Operations.ShowCardQueryVariables>;
export const ShowCardsByShowDocument = gql`
  query ShowCardsByShow($showId: ID!, $first: Int = 10, $cursor: String) {
    showCardsByShow(showId: $showId, first: $first, after: $cursor, order: { createdAt: DESC }) {
      edges {
        node {
          ...ShowCard
        }
        cursor
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ShowCardFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useShowCardsByShowQuery__
 *
 * To run a query within a React component, call `useShowCardsByShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowCardsByShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowCardsByShowQuery({
 *   variables: {
 *      showId: // value for 'showId'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useShowCardsByShowQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.ShowCardsByShowQuery, Operations.ShowCardsByShowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ShowCardsByShowQuery, Operations.ShowCardsByShowQueryVariables>(
    ShowCardsByShowDocument,
    options
  );
}
export function useShowCardsByShowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShowCardsByShowQuery, Operations.ShowCardsByShowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ShowCardsByShowQuery, Operations.ShowCardsByShowQueryVariables>(
    ShowCardsByShowDocument,
    options
  );
}
export type ShowCardsByShowQueryHookResult = ReturnType<typeof useShowCardsByShowQuery>;
export type ShowCardsByShowLazyQueryHookResult = ReturnType<typeof useShowCardsByShowLazyQuery>;
export type ShowCardsByShowQueryResult = Apollo.QueryResult<
  Operations.ShowCardsByShowQuery,
  Operations.ShowCardsByShowQueryVariables
>;
export const ShowsByShopSlugDocument = gql`
  query ShowsByShopSlug($shopSlug: String!) {
    showsByShopSlug(shopSlug: $shopSlug) {
      edges {
        node {
          slug
          showImages {
            image {
              transformedSrc
            }
          }
          images {
            transformedSrc
          }
          shop {
            shopUsers {
              nodes {
                role
                user {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useShowsByShopSlugQuery__
 *
 * To run a query within a React component, call `useShowsByShopSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowsByShopSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowsByShopSlugQuery({
 *   variables: {
 *      shopSlug: // value for 'shopSlug'
 *   },
 * });
 */
export function useShowsByShopSlugQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.ShowsByShopSlugQuery, Operations.ShowsByShopSlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ShowsByShopSlugQuery, Operations.ShowsByShopSlugQueryVariables>(
    ShowsByShopSlugDocument,
    options
  );
}
export function useShowsByShopSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShowsByShopSlugQuery, Operations.ShowsByShopSlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ShowsByShopSlugQuery, Operations.ShowsByShopSlugQueryVariables>(
    ShowsByShopSlugDocument,
    options
  );
}
export type ShowsByShopSlugQueryHookResult = ReturnType<typeof useShowsByShopSlugQuery>;
export type ShowsByShopSlugLazyQueryHookResult = ReturnType<typeof useShowsByShopSlugLazyQuery>;
export type ShowsByShopSlugQueryResult = Apollo.QueryResult<
  Operations.ShowsByShopSlugQuery,
  Operations.ShowsByShopSlugQueryVariables
>;
export const StreamBySessionIdDocument = gql`
  query StreamBySessionId($sessionId: String!) {
    streamBySessionId(sessionId: $sessionId) {
      ...Stream
    }
  }
  ${StreamFragmentDoc}
`;

/**
 * __useStreamBySessionIdQuery__
 *
 * To run a query within a React component, call `useStreamBySessionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useStreamBySessionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreamBySessionIdQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useStreamBySessionIdQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.StreamBySessionIdQuery, Operations.StreamBySessionIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.StreamBySessionIdQuery, Operations.StreamBySessionIdQueryVariables>(
    StreamBySessionIdDocument,
    options
  );
}
export function useStreamBySessionIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.StreamBySessionIdQuery,
    Operations.StreamBySessionIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.StreamBySessionIdQuery, Operations.StreamBySessionIdQueryVariables>(
    StreamBySessionIdDocument,
    options
  );
}
export type StreamBySessionIdQueryHookResult = ReturnType<typeof useStreamBySessionIdQuery>;
export type StreamBySessionIdLazyQueryHookResult = ReturnType<typeof useStreamBySessionIdLazyQuery>;
export type StreamBySessionIdQueryResult = Apollo.QueryResult<
  Operations.StreamBySessionIdQuery,
  Operations.StreamBySessionIdQueryVariables
>;
export const TaxCategoriesDocument = gql`
  query TaxCategories($cursor: String) {
    taxCategories(first: 50, after: $cursor) {
      edges {
        node {
          ...TaxCategory
        }
        cursor
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${TaxCategoryFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useTaxCategoriesQuery__
 *
 * To run a query within a React component, call `useTaxCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxCategoriesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useTaxCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<Operations.TaxCategoriesQuery, Operations.TaxCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.TaxCategoriesQuery, Operations.TaxCategoriesQueryVariables>(
    TaxCategoriesDocument,
    options
  );
}
export function useTaxCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.TaxCategoriesQuery, Operations.TaxCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.TaxCategoriesQuery, Operations.TaxCategoriesQueryVariables>(
    TaxCategoriesDocument,
    options
  );
}
export type TaxCategoriesQueryHookResult = ReturnType<typeof useTaxCategoriesQuery>;
export type TaxCategoriesLazyQueryHookResult = ReturnType<typeof useTaxCategoriesLazyQuery>;
export type TaxCategoriesQueryResult = Apollo.QueryResult<
  Operations.TaxCategoriesQuery,
  Operations.TaxCategoriesQueryVariables
>;
export const TaxCategoryDocument = gql`
  query TaxCategory($id: ID!) {
    taxCategory(id: $id) {
      ...TaxCategory
    }
  }
  ${TaxCategoryFragmentDoc}
`;

/**
 * __useTaxCategoryQuery__
 *
 * To run a query within a React component, call `useTaxCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaxCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.TaxCategoryQuery, Operations.TaxCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.TaxCategoryQuery, Operations.TaxCategoryQueryVariables>(
    TaxCategoryDocument,
    options
  );
}
export function useTaxCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.TaxCategoryQuery, Operations.TaxCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.TaxCategoryQuery, Operations.TaxCategoryQueryVariables>(
    TaxCategoryDocument,
    options
  );
}
export type TaxCategoryQueryHookResult = ReturnType<typeof useTaxCategoryQuery>;
export type TaxCategoryLazyQueryHookResult = ReturnType<typeof useTaxCategoryLazyQuery>;
export type TaxCategoryQueryResult = Apollo.QueryResult<
  Operations.TaxCategoryQuery,
  Operations.TaxCategoryQueryVariables
>;
export const TotalShopSalesDocument = gql`
  query TotalShopSales($shopId: ID!) {
    shop(id: $shopId) {
      id
      totalShopSales {
        amount
        currency
      }
    }
  }
`;

/**
 * __useTotalShopSalesQuery__
 *
 * To run a query within a React component, call `useTotalShopSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalShopSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalShopSalesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useTotalShopSalesQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.TotalShopSalesQuery, Operations.TotalShopSalesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.TotalShopSalesQuery, Operations.TotalShopSalesQueryVariables>(
    TotalShopSalesDocument,
    options
  );
}
export function useTotalShopSalesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.TotalShopSalesQuery, Operations.TotalShopSalesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.TotalShopSalesQuery, Operations.TotalShopSalesQueryVariables>(
    TotalShopSalesDocument,
    options
  );
}
export type TotalShopSalesQueryHookResult = ReturnType<typeof useTotalShopSalesQuery>;
export type TotalShopSalesLazyQueryHookResult = ReturnType<typeof useTotalShopSalesLazyQuery>;
export type TotalShopSalesQueryResult = Apollo.QueryResult<
  Operations.TotalShopSalesQuery,
  Operations.TotalShopSalesQueryVariables
>;
export const UserByIdDocument = gql`
  query UserById($userId: ID!) {
    user(id: $userId) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.UserByIdQuery, Operations.UserByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.UserByIdQuery, Operations.UserByIdQueryVariables>(UserByIdDocument, options);
}
export function useUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.UserByIdQuery, Operations.UserByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.UserByIdQuery, Operations.UserByIdQueryVariables>(UserByIdDocument, options);
}
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<typeof useUserByIdLazyQuery>;
export type UserByIdQueryResult = Apollo.QueryResult<Operations.UserByIdQuery, Operations.UserByIdQueryVariables>;
export const UserByUsernameDocument = gql`
  query UserByUsername($userName: String!) {
    userByUsername(userName: $userName) {
      id
      email
      name
      username
      photoUrl
    }
  }
`;

/**
 * __useUserByUsernameQuery__
 *
 * To run a query within a React component, call `useUserByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByUsernameQuery({
 *   variables: {
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useUserByUsernameQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.UserByUsernameQuery, Operations.UserByUsernameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.UserByUsernameQuery, Operations.UserByUsernameQueryVariables>(
    UserByUsernameDocument,
    options
  );
}
export function useUserByUsernameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.UserByUsernameQuery, Operations.UserByUsernameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.UserByUsernameQuery, Operations.UserByUsernameQueryVariables>(
    UserByUsernameDocument,
    options
  );
}
export type UserByUsernameQueryHookResult = ReturnType<typeof useUserByUsernameQuery>;
export type UserByUsernameLazyQueryHookResult = ReturnType<typeof useUserByUsernameLazyQuery>;
export type UserByUsernameQueryResult = Apollo.QueryResult<
  Operations.UserByUsernameQuery,
  Operations.UserByUsernameQueryVariables
>;
export const UsersByEmailDocument = gql`
  query UsersByEmail($email: String!) {
    usersByEmail(email: $email) {
      nodes {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useUsersByEmailQuery__
 *
 * To run a query within a React component, call `useUsersByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUsersByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.UsersByEmailQuery, Operations.UsersByEmailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.UsersByEmailQuery, Operations.UsersByEmailQueryVariables>(
    UsersByEmailDocument,
    options
  );
}
export function useUsersByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.UsersByEmailQuery, Operations.UsersByEmailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.UsersByEmailQuery, Operations.UsersByEmailQueryVariables>(
    UsersByEmailDocument,
    options
  );
}
export type UsersByEmailQueryHookResult = ReturnType<typeof useUsersByEmailQuery>;
export type UsersByEmailLazyQueryHookResult = ReturnType<typeof useUsersByEmailLazyQuery>;
export type UsersByEmailQueryResult = Apollo.QueryResult<
  Operations.UsersByEmailQuery,
  Operations.UsersByEmailQueryVariables
>;
export const ValidateAddressDocument = gql`
  query ValidateAddress($address: AddressInput!) {
    validateAddress(address: $address) {
      status
      address {
        ...Address
      }
      matchedAddress {
        ...Address
      }
      errors {
        message
        code
        field
      }
      modified
    }
  }
  ${AddressFragmentDoc}
`;

/**
 * __useValidateAddressQuery__
 *
 * To run a query within a React component, call `useValidateAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateAddressQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useValidateAddressQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.ValidateAddressQuery, Operations.ValidateAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ValidateAddressQuery, Operations.ValidateAddressQueryVariables>(
    ValidateAddressDocument,
    options
  );
}
export function useValidateAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ValidateAddressQuery, Operations.ValidateAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ValidateAddressQuery, Operations.ValidateAddressQueryVariables>(
    ValidateAddressDocument,
    options
  );
}
export type ValidateAddressQueryHookResult = ReturnType<typeof useValidateAddressQuery>;
export type ValidateAddressLazyQueryHookResult = ReturnType<typeof useValidateAddressLazyQuery>;
export type ValidateAddressQueryResult = Apollo.QueryResult<
  Operations.ValidateAddressQuery,
  Operations.ValidateAddressQueryVariables
>;
export const VariantDocument = gql`
  query Variant($variantId: ID!) {
    variant(id: $variantId) {
      id
      inventoryItem {
        id
        quantity {
          available
          reserved
          total
        }
      }
      quantity {
        available
        reserved
        total
      }
      prices {
        basePrice
        price
        retailPrice
        salePrice
      }
    }
  }
`;

/**
 * __useVariantQuery__
 *
 * To run a query within a React component, call `useVariantQuery` and pass it any options that fit your needs.
 * When your component renders, `useVariantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVariantQuery({
 *   variables: {
 *      variantId: // value for 'variantId'
 *   },
 * });
 */
export function useVariantQuery(
  baseOptions: Apollo.QueryHookOptions<Operations.VariantQuery, Operations.VariantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.VariantQuery, Operations.VariantQueryVariables>(VariantDocument, options);
}
export function useVariantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.VariantQuery, Operations.VariantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.VariantQuery, Operations.VariantQueryVariables>(VariantDocument, options);
}
export type VariantQueryHookResult = ReturnType<typeof useVariantQuery>;
export type VariantLazyQueryHookResult = ReturnType<typeof useVariantLazyQuery>;
export type VariantQueryResult = Apollo.QueryResult<Operations.VariantQuery, Operations.VariantQueryVariables>;
export const ViewerDocument = gql`
  query Viewer {
    viewer {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(
  baseOptions?: Apollo.QueryHookOptions<Operations.ViewerQuery, Operations.ViewerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ViewerQuery, Operations.ViewerQueryVariables>(ViewerDocument, options);
}
export function useViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ViewerQuery, Operations.ViewerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ViewerQuery, Operations.ViewerQueryVariables>(ViewerDocument, options);
}
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerQueryResult = Apollo.QueryResult<Operations.ViewerQuery, Operations.ViewerQueryVariables>;
export const ViewerCartItemsDocument = gql`
  query ViewerCartItems($order: [CartItemSortInput!]) {
    viewerCartItems(order: $order) {
      nodes {
        cartId
        show {
          id
          slug
          createdAt
        }
        quantity
        show {
          shop {
            name
            slug
          }
        }
      }
    }
  }
`;

/**
 * __useViewerCartItemsQuery__
 *
 * To run a query within a React component, call `useViewerCartItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerCartItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerCartItemsQuery({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useViewerCartItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<Operations.ViewerCartItemsQuery, Operations.ViewerCartItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ViewerCartItemsQuery, Operations.ViewerCartItemsQueryVariables>(
    ViewerCartItemsDocument,
    options
  );
}
export function useViewerCartItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ViewerCartItemsQuery, Operations.ViewerCartItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ViewerCartItemsQuery, Operations.ViewerCartItemsQueryVariables>(
    ViewerCartItemsDocument,
    options
  );
}
export type ViewerCartItemsQueryHookResult = ReturnType<typeof useViewerCartItemsQuery>;
export type ViewerCartItemsLazyQueryHookResult = ReturnType<typeof useViewerCartItemsLazyQuery>;
export type ViewerCartItemsQueryResult = Apollo.QueryResult<
  Operations.ViewerCartItemsQuery,
  Operations.ViewerCartItemsQueryVariables
>;
export const ViewerCartItemsByShopSlugDocument = gql`
  query ViewerCartItemsByShopSlug($shopSlug: String!) {
    viewerCartItemsByShopSlug(shopSlug: $shopSlug, first: 100) {
      nodes {
        ...CartItem
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${CartItemFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useViewerCartItemsByShopSlugQuery__
 *
 * To run a query within a React component, call `useViewerCartItemsByShopSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerCartItemsByShopSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerCartItemsByShopSlugQuery({
 *   variables: {
 *      shopSlug: // value for 'shopSlug'
 *   },
 * });
 */
export function useViewerCartItemsByShopSlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.ViewerCartItemsByShopSlugQuery,
    Operations.ViewerCartItemsByShopSlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ViewerCartItemsByShopSlugQuery, Operations.ViewerCartItemsByShopSlugQueryVariables>(
    ViewerCartItemsByShopSlugDocument,
    options
  );
}
export function useViewerCartItemsByShopSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.ViewerCartItemsByShopSlugQuery,
    Operations.ViewerCartItemsByShopSlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.ViewerCartItemsByShopSlugQuery,
    Operations.ViewerCartItemsByShopSlugQueryVariables
  >(ViewerCartItemsByShopSlugDocument, options);
}
export type ViewerCartItemsByShopSlugQueryHookResult = ReturnType<typeof useViewerCartItemsByShopSlugQuery>;
export type ViewerCartItemsByShopSlugLazyQueryHookResult = ReturnType<typeof useViewerCartItemsByShopSlugLazyQuery>;
export type ViewerCartItemsByShopSlugQueryResult = Apollo.QueryResult<
  Operations.ViewerCartItemsByShopSlugQuery,
  Operations.ViewerCartItemsByShopSlugQueryVariables
>;
export const ViewerCheckoutsByShopSlugDocument = gql`
  query ViewerCheckoutsByShopSlug($shopSlug: String!, $first: Int = 10, $cursor: String) {
    viewerCheckoutsByShopSlug(shopSlug: $shopSlug, first: $first, after: $cursor) {
      edges {
        node {
          ...Checkout
        }
        cursor
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${CheckoutFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useViewerCheckoutsByShopSlugQuery__
 *
 * To run a query within a React component, call `useViewerCheckoutsByShopSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerCheckoutsByShopSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerCheckoutsByShopSlugQuery({
 *   variables: {
 *      shopSlug: // value for 'shopSlug'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useViewerCheckoutsByShopSlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.ViewerCheckoutsByShopSlugQuery,
    Operations.ViewerCheckoutsByShopSlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ViewerCheckoutsByShopSlugQuery, Operations.ViewerCheckoutsByShopSlugQueryVariables>(
    ViewerCheckoutsByShopSlugDocument,
    options
  );
}
export function useViewerCheckoutsByShopSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.ViewerCheckoutsByShopSlugQuery,
    Operations.ViewerCheckoutsByShopSlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Operations.ViewerCheckoutsByShopSlugQuery,
    Operations.ViewerCheckoutsByShopSlugQueryVariables
  >(ViewerCheckoutsByShopSlugDocument, options);
}
export type ViewerCheckoutsByShopSlugQueryHookResult = ReturnType<typeof useViewerCheckoutsByShopSlugQuery>;
export type ViewerCheckoutsByShopSlugLazyQueryHookResult = ReturnType<typeof useViewerCheckoutsByShopSlugLazyQuery>;
export type ViewerCheckoutsByShopSlugQueryResult = Apollo.QueryResult<
  Operations.ViewerCheckoutsByShopSlugQuery,
  Operations.ViewerCheckoutsByShopSlugQueryVariables
>;
export const ViewerCustomerDocument = gql`
  query ViewerCustomer {
    viewer {
      id
      customer {
        ...CustomerDetails
      }
    }
  }
  ${CustomerDetailsFragmentDoc}
`;

/**
 * __useViewerCustomerQuery__
 *
 * To run a query within a React component, call `useViewerCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<Operations.ViewerCustomerQuery, Operations.ViewerCustomerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ViewerCustomerQuery, Operations.ViewerCustomerQueryVariables>(
    ViewerCustomerDocument,
    options
  );
}
export function useViewerCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ViewerCustomerQuery, Operations.ViewerCustomerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ViewerCustomerQuery, Operations.ViewerCustomerQueryVariables>(
    ViewerCustomerDocument,
    options
  );
}
export type ViewerCustomerQueryHookResult = ReturnType<typeof useViewerCustomerQuery>;
export type ViewerCustomerLazyQueryHookResult = ReturnType<typeof useViewerCustomerLazyQuery>;
export type ViewerCustomerQueryResult = Apollo.QueryResult<
  Operations.ViewerCustomerQuery,
  Operations.ViewerCustomerQueryVariables
>;
export const ViewerIdentitiesDocument = gql`
  query ViewerIdentities {
    viewer {
      id
      name
      email
      photoUrl
      createdAt
      updatedAt
      identities {
        userId
        provider
        accessToken
        social
        refreshToken
      }
    }
  }
`;

/**
 * __useViewerIdentitiesQuery__
 *
 * To run a query within a React component, call `useViewerIdentitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerIdentitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerIdentitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerIdentitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<Operations.ViewerIdentitiesQuery, Operations.ViewerIdentitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ViewerIdentitiesQuery, Operations.ViewerIdentitiesQueryVariables>(
    ViewerIdentitiesDocument,
    options
  );
}
export function useViewerIdentitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ViewerIdentitiesQuery, Operations.ViewerIdentitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ViewerIdentitiesQuery, Operations.ViewerIdentitiesQueryVariables>(
    ViewerIdentitiesDocument,
    options
  );
}
export type ViewerIdentitiesQueryHookResult = ReturnType<typeof useViewerIdentitiesQuery>;
export type ViewerIdentitiesLazyQueryHookResult = ReturnType<typeof useViewerIdentitiesLazyQuery>;
export type ViewerIdentitiesQueryResult = Apollo.QueryResult<
  Operations.ViewerIdentitiesQuery,
  Operations.ViewerIdentitiesQueryVariables
>;
export const ViewerShopsDocument = gql`
  query ViewerShops {
    viewer {
      ...User
      shops {
        nodes {
          ...Shop
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${ShopFragmentDoc}
`;

/**
 * __useViewerShopsQuery__
 *
 * To run a query within a React component, call `useViewerShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerShopsQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerShopsQuery(
  baseOptions?: Apollo.QueryHookOptions<Operations.ViewerShopsQuery, Operations.ViewerShopsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.ViewerShopsQuery, Operations.ViewerShopsQueryVariables>(
    ViewerShopsDocument,
    options
  );
}
export function useViewerShopsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Operations.ViewerShopsQuery, Operations.ViewerShopsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.ViewerShopsQuery, Operations.ViewerShopsQueryVariables>(
    ViewerShopsDocument,
    options
  );
}
export type ViewerShopsQueryHookResult = ReturnType<typeof useViewerShopsQuery>;
export type ViewerShopsLazyQueryHookResult = ReturnType<typeof useViewerShopsLazyQuery>;
export type ViewerShopsQueryResult = Apollo.QueryResult<
  Operations.ViewerShopsQuery,
  Operations.ViewerShopsQueryVariables
>;
export const PaymentAccountsByShopDocument = gql`
  query PaymentAccountsByShop($id: ID!) {
    paymentAccountsByShop(shopId: $id) {
      nodes {
        id
      }
    }
  }
`;

/**
 * __usePaymentAccountsByShopQuery__
 *
 * To run a query within a React component, call `usePaymentAccountsByShopQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentAccountsByShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentAccountsByShopQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentAccountsByShopQuery(
  baseOptions: Apollo.QueryHookOptions<
    Operations.PaymentAccountsByShopQuery,
    Operations.PaymentAccountsByShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Operations.PaymentAccountsByShopQuery, Operations.PaymentAccountsByShopQueryVariables>(
    PaymentAccountsByShopDocument,
    options
  );
}
export function usePaymentAccountsByShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Operations.PaymentAccountsByShopQuery,
    Operations.PaymentAccountsByShopQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Operations.PaymentAccountsByShopQuery, Operations.PaymentAccountsByShopQueryVariables>(
    PaymentAccountsByShopDocument,
    options
  );
}
export type PaymentAccountsByShopQueryHookResult = ReturnType<typeof usePaymentAccountsByShopQuery>;
export type PaymentAccountsByShopLazyQueryHookResult = ReturnType<typeof usePaymentAccountsByShopLazyQuery>;
export type PaymentAccountsByShopQueryResult = Apollo.QueryResult<
  Operations.PaymentAccountsByShopQuery,
  Operations.PaymentAccountsByShopQueryVariables
>;
export const CartItemUpdatedByCartDocument = gql`
  subscription CartItemUpdatedByCart($cartId: ID!) {
    cartItemUpdatedByCart(cartId: $cartId) {
      ...CartItem
    }
  }
  ${CartItemFragmentDoc}
`;

/**
 * __useCartItemUpdatedByCartSubscription__
 *
 * To run a query within a React component, call `useCartItemUpdatedByCartSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCartItemUpdatedByCartSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartItemUpdatedByCartSubscription({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useCartItemUpdatedByCartSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    Operations.CartItemUpdatedByCartSubscription,
    Operations.CartItemUpdatedByCartSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    Operations.CartItemUpdatedByCartSubscription,
    Operations.CartItemUpdatedByCartSubscriptionVariables
  >(CartItemUpdatedByCartDocument, options);
}
export type CartItemUpdatedByCartSubscriptionHookResult = ReturnType<typeof useCartItemUpdatedByCartSubscription>;
export type CartItemUpdatedByCartSubscriptionResult =
  Apollo.SubscriptionResult<Operations.CartItemUpdatedByCartSubscription>;
export const FbCommentsStatusByShowDocument = gql`
  subscription FbCommentsStatusByShow($showId: ID!) {
    fbCommentsSessionStatusByShow(showId: $showId) {
      sessionId
      status
      error
      message
    }
  }
`;

/**
 * __useFbCommentsStatusByShowSubscription__
 *
 * To run a query within a React component, call `useFbCommentsStatusByShowSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFbCommentsStatusByShowSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFbCommentsStatusByShowSubscription({
 *   variables: {
 *      showId: // value for 'showId'
 *   },
 * });
 */
export function useFbCommentsStatusByShowSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    Operations.FbCommentsStatusByShowSubscription,
    Operations.FbCommentsStatusByShowSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    Operations.FbCommentsStatusByShowSubscription,
    Operations.FbCommentsStatusByShowSubscriptionVariables
  >(FbCommentsStatusByShowDocument, options);
}
export type FbCommentsStatusByShowSubscriptionHookResult = ReturnType<typeof useFbCommentsStatusByShowSubscription>;
export type FbCommentsStatusByShowSubscriptionResult =
  Apollo.SubscriptionResult<Operations.FbCommentsStatusByShowSubscription>;
export const InventoryItemQuantityChangedDocument = gql`
  subscription InventoryItemQuantityChanged($inventoryItemId: ID!) {
    inventoryItemQuantityChanged(inventoryItemId: $inventoryItemId) {
      inventoryItemId
      quantity {
        ...Quantity
      }
    }
  }
  ${QuantityFragmentDoc}
`;

/**
 * __useInventoryItemQuantityChangedSubscription__
 *
 * To run a query within a React component, call `useInventoryItemQuantityChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInventoryItemQuantityChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryItemQuantityChangedSubscription({
 *   variables: {
 *      inventoryItemId: // value for 'inventoryItemId'
 *   },
 * });
 */
export function useInventoryItemQuantityChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    Operations.InventoryItemQuantityChangedSubscription,
    Operations.InventoryItemQuantityChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    Operations.InventoryItemQuantityChangedSubscription,
    Operations.InventoryItemQuantityChangedSubscriptionVariables
  >(InventoryItemQuantityChangedDocument, options);
}
export type InventoryItemQuantityChangedSubscriptionHookResult = ReturnType<
  typeof useInventoryItemQuantityChangedSubscription
>;
export type InventoryItemQuantityChangedSubscriptionResult =
  Apollo.SubscriptionResult<Operations.InventoryItemQuantityChangedSubscription>;
export const InventoryItemsQuantityChangedDocument = gql`
  subscription InventoryItemsQuantityChanged($inventoryItemIds: [ID!]!) {
    inventoryItemsQuantityChanged(inventoryItemIds: $inventoryItemIds) {
      inventoryItemId
      quantity {
        ...Quantity
      }
    }
  }
  ${QuantityFragmentDoc}
`;

/**
 * __useInventoryItemsQuantityChangedSubscription__
 *
 * To run a query within a React component, call `useInventoryItemsQuantityChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInventoryItemsQuantityChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryItemsQuantityChangedSubscription({
 *   variables: {
 *      inventoryItemIds: // value for 'inventoryItemIds'
 *   },
 * });
 */
export function useInventoryItemsQuantityChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    Operations.InventoryItemsQuantityChangedSubscription,
    Operations.InventoryItemsQuantityChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    Operations.InventoryItemsQuantityChangedSubscription,
    Operations.InventoryItemsQuantityChangedSubscriptionVariables
  >(InventoryItemsQuantityChangedDocument, options);
}
export type InventoryItemsQuantityChangedSubscriptionHookResult = ReturnType<
  typeof useInventoryItemsQuantityChangedSubscription
>;
export type InventoryItemsQuantityChangedSubscriptionResult =
  Apollo.SubscriptionResult<Operations.InventoryItemsQuantityChangedSubscription>;
export const ItemAddedToCartByUserAndShopDocument = gql`
  subscription ItemAddedToCartByUserAndShop($userId: ID!, $shopId: ID!) {
    itemAddedToCartByUserAndShop(userId: $userId, shopId: $shopId) {
      ...CartItem
    }
  }
  ${CartItemFragmentDoc}
`;

/**
 * __useItemAddedToCartByUserAndShopSubscription__
 *
 * To run a query within a React component, call `useItemAddedToCartByUserAndShopSubscription` and pass it any options that fit your needs.
 * When your component renders, `useItemAddedToCartByUserAndShopSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemAddedToCartByUserAndShopSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useItemAddedToCartByUserAndShopSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    Operations.ItemAddedToCartByUserAndShopSubscription,
    Operations.ItemAddedToCartByUserAndShopSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    Operations.ItemAddedToCartByUserAndShopSubscription,
    Operations.ItemAddedToCartByUserAndShopSubscriptionVariables
  >(ItemAddedToCartByUserAndShopDocument, options);
}
export type ItemAddedToCartByUserAndShopSubscriptionHookResult = ReturnType<
  typeof useItemAddedToCartByUserAndShopSubscription
>;
export type ItemAddedToCartByUserAndShopSubscriptionResult =
  Apollo.SubscriptionResult<Operations.ItemAddedToCartByUserAndShopSubscription>;
export const ItemRemovedFromCartDocument = gql`
  subscription ItemRemovedFromCart($cartId: ID!) {
    itemRemovedFromCart(cartId: $cartId) {
      id
    }
  }
`;

/**
 * __useItemRemovedFromCartSubscription__
 *
 * To run a query within a React component, call `useItemRemovedFromCartSubscription` and pass it any options that fit your needs.
 * When your component renders, `useItemRemovedFromCartSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemRemovedFromCartSubscription({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useItemRemovedFromCartSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    Operations.ItemRemovedFromCartSubscription,
    Operations.ItemRemovedFromCartSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    Operations.ItemRemovedFromCartSubscription,
    Operations.ItemRemovedFromCartSubscriptionVariables
  >(ItemRemovedFromCartDocument, options);
}
export type ItemRemovedFromCartSubscriptionHookResult = ReturnType<typeof useItemRemovedFromCartSubscription>;
export type ItemRemovedFromCartSubscriptionResult =
  Apollo.SubscriptionResult<Operations.ItemRemovedFromCartSubscription>;
export const ProductsInventoryQuantityChangedDocument = gql`
  subscription ProductsInventoryQuantityChanged($productIds: [ID!]!) {
    productsInventoryQuantityChanged(productIds: $productIds) {
      productId
      quantity {
        ...Quantity
      }
    }
  }
  ${QuantityFragmentDoc}
`;

/**
 * __useProductsInventoryQuantityChangedSubscription__
 *
 * To run a query within a React component, call `useProductsInventoryQuantityChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProductsInventoryQuantityChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsInventoryQuantityChangedSubscription({
 *   variables: {
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useProductsInventoryQuantityChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    Operations.ProductsInventoryQuantityChangedSubscription,
    Operations.ProductsInventoryQuantityChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    Operations.ProductsInventoryQuantityChangedSubscription,
    Operations.ProductsInventoryQuantityChangedSubscriptionVariables
  >(ProductsInventoryQuantityChangedDocument, options);
}
export type ProductsInventoryQuantityChangedSubscriptionHookResult = ReturnType<
  typeof useProductsInventoryQuantityChangedSubscription
>;
export type ProductsInventoryQuantityChangedSubscriptionResult =
  Apollo.SubscriptionResult<Operations.ProductsInventoryQuantityChangedSubscription>;
export const VariantAddedToShowDocument = gql`
  subscription VariantAddedToShow($showId: ID!) {
    variantAddedToShow(showId: $showId) {
      ...FeaturedShowCard
    }
  }
  ${FeaturedShowCardFragmentDoc}
`;

/**
 * __useVariantAddedToShowSubscription__
 *
 * To run a query within a React component, call `useVariantAddedToShowSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVariantAddedToShowSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVariantAddedToShowSubscription({
 *   variables: {
 *      showId: // value for 'showId'
 *   },
 * });
 */
export function useVariantAddedToShowSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    Operations.VariantAddedToShowSubscription,
    Operations.VariantAddedToShowSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    Operations.VariantAddedToShowSubscription,
    Operations.VariantAddedToShowSubscriptionVariables
  >(VariantAddedToShowDocument, options);
}
export type VariantAddedToShowSubscriptionHookResult = ReturnType<typeof useVariantAddedToShowSubscription>;
export type VariantAddedToShowSubscriptionResult = Apollo.SubscriptionResult<Operations.VariantAddedToShowSubscription>;
